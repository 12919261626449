import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Auth, AuthContext } from "../../../context/AuthProvider";
import RetailMainButton from "../RetailMainButton";

import cm from "./style.module.scss";

interface RetailNotificationActionButtonProps {
  notificationType: string;
  id: unknown;
  /* expanded: boolean; */
}

const RetailNotificationActionButton = ({
  notificationType,
  id,
}: RetailNotificationActionButtonProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { advValue } = useContext(AuthContext) as Auth;

  const switchTranslationKey = () => {
    if (notificationType?.includes("ProductsStopped")) return "productAction";
    if (notificationType?.includes("BalanceLow")) return "balanceAction";
    return "bidAction";
  };

  const handleClick = () => {
    const baseUrl = notificationType?.includes("BalanceLow")
      ? "/invoices"
      : `/campaign/${id}`;
    const url = advValue ? `${baseUrl}?adv=${advValue}` : baseUrl;
    navigate(url);
  };

  return (
    <RetailMainButton
      className={cm.btn}
      hasBackground={true}
      onClick={handleClick}
    >
      {t(`components.notification.${switchTranslationKey()}`)}
    </RetailMainButton>
  );
};

export default RetailNotificationActionButton;
