import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as PlayButtonFilled } from "../../../assets/icons/playButtonFilled.svg";
import { ReactComponent as Warning } from "../../../assets/icons/dark/warning.svg";
import { EntityStates } from "../../../utils/types";
import { advertiserCurl, eventCurl, productCurl, saleCurl } from "./data";
import axios from "axios";
import useSettings from "../../../hooks/useSettings";
import "highlight.js/styles/tomorrow-night-bright.css";
import Highlight from "react-highlight";
import RetailDrawer from "../RetailDrawer";
import RetailMainButton from "../../Button/RetailMainButton";
import RetailText from "../../Typography/RetailText";

import cm from "./style.module.scss";

interface RetailCurlTestDrawerProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  id: null | number | string;
  dataInfo: {
    uuid: string;
    entity: EntityStates["selected"];
  };
  openLogsDrawer: (historyId: number) => void;
}

const RetailCurlTestDrawer = ({
  visible,
  setVisible,
  id,
  openLogsDrawer,
  dataInfo,
}: RetailCurlTestDrawerProps) => {
  const { t } = useTranslation();

  const { data } = useSettings("MARKETPLACE");

  const [apiStates, setApiStates] = useState({
    mode: "",
    loading: false,
    response: null,
  });

  const closeDrawer = () => {
    setVisible(false);
    setApiStates({
      mode: "",
      loading: false,
      response: null,
    });
  };

  const renderTestCurl = useCallback(() => {
    switch (dataInfo.entity) {
      case "product":
        return productCurl(data, dataInfo.uuid);
      case "advertiser":
        return advertiserCurl(data, dataInfo.uuid);
      case "impression":
      case "click":
        return eventCurl(data, dataInfo.uuid, dataInfo.entity);
      case "sale":
        return saleCurl(data, dataInfo.uuid);
      default:
        return "";
    }
  }, [dataInfo, data]);

  const fetchProducts = useCallback(async () => {
    const apiUrl = "/api/integration/products";
    setApiStates((prevState) => ({
      ...prevState,
      loading: true,
    }));
    try {
      const response = await axios.post(
        apiUrl,
        {
          marketplace_id: data?.data?.id,
          data_source_id: dataInfo.uuid,
          products: [
            {
              id: "12345",
              name: "Sample Product Alpha",
              advertiser_id: "56789",
              image_url: "https://example.com/images/sample-alpha.jpg",
              description:
                "This is a placeholder product for API testing purposes.",
              price: 50.0,
              stock: 100,
              rating: 4.5,
              buybox: false,
              brand: "TestBrand",
              retailer_taxonomy: "SampleCategory>Subcategory",
              status: "DELETED",
            },
            {
              id: "67890",
              name: "Sample Product Beta",
              advertiser_id: "98765",
              image_url: "https://example.com/images/sample-beta.jpg",
              description:
                "Another placeholder product to validate API functionality.",
              price: 75.0,
              stock: 50,
              rating: 4.0,
              buybox: true,
              brand: "TestBrand",
              retailer_taxonomy: "SampleCategory>AnotherSubcategory",
              status: "ACTIVE",
            },
          ],
        },
        {
          headers: {
            Authorization: data?.data?.api_key,
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      );

      setApiStates({
        loading: false,
        mode: "success",
        response: response?.data,
      });
    } catch (error) {
      console.error("Error:", error);
      setApiStates({
        loading: false,
        mode: "error",
        response: error?.response?.data,
      });
    }
  }, [data, dataInfo.uuid]);

  const fetchAdvertisers = useCallback(async () => {
    const apiUrl = "/api/integration/advertisers";
    setApiStates((prevState) => ({
      ...prevState,
      loading: true,
    }));

    try {
      const response = await axios.post(
        apiUrl,
        {
          marketplace_id: data?.data?.id,
          advertisers: [
            {
              status: "ACTIVE",
              name: "Sample Advertiser",
              email: "sample_advertiser@gowit.com",
              id: "56789",
            },
            {
              status: "ACTIVE",
              name: "Another Advertiser",
              email: "another_advertiser@gowit.com",
              id: "98765",
            },
          ],
        },
        {
          headers: {
            Authorization: data?.data?.api_key,
            "Data-Source-UUID": dataInfo.uuid,
            "Content-Type": "application/json",
          },
        }
      );

      setApiStates({
        loading: false,
        mode: "success",
        response: response?.data,
      });
    } catch (error) {
      console.error("Error:", error);
      setApiStates({
        loading: false,
        mode: "error",
        response: error?.response?.data,
      });
    }
  }, [data, dataInfo.uuid]);

  const fetchEvent = useCallback(async () => {
    const apiUrl = "/server/events";
    setApiStates((prevState) => ({
      ...prevState,
      loading: true,
    }));

    try {
      const response = await axios.post(
        apiUrl,
        {
          marketplace_id: data?.data?.id,
          event_type: dataInfo.entity,
          ad_id: "4b702dcc-2ea5-495b-adc3-66cf0b44f039",
          session_id: "ec07083a-8bf8",
        },
        {
          headers: {
            Authorization: data?.data?.api_key,
            "Data-Source-UUID": dataInfo.uuid,
            "Content-Type": "application/json",
          },
        }
      );

      setApiStates({
        loading: false,
        mode: "success",
        response: response?.data,
      });
    } catch (error) {
      console.error("Error:", error);
      setApiStates({
        loading: false,
        mode: "error",
        response: error?.response?.data,
      });
    }
  }, [data, dataInfo.entity, dataInfo.uuid]);

  const fetchSaleEvent = useCallback(async () => {
    const apiUrl = "/server/events";
    setApiStates((prevState) => ({
      ...prevState,
      loading: true,
    }));

    try {
      const response = await axios.post(
        apiUrl,
        {
          marketplace_id: data?.data?.id,
          event_type: "sale",
          sales: [
            {
              advertiser_id: "56789",
              quantity: 2,
              unit_price: 100,
              product_id: "12345",
            },
          ],
          session_id: "ec07083a-8bf8",
        },
        {
          headers: {
            Authorization: data?.data?.api_key,
            "Data-Source-UUID": dataInfo.uuid,
            "Content-Type": "application/json",
          },
        }
      );

      setApiStates({
        loading: false,
        mode: "success",
        response: response?.data,
      });
    } catch (error) {
      console.error("Error:", error);
      setApiStates({
        loading: false,
        mode: "error",
        response: error?.response?.data,
      });
    }
  }, [data, dataInfo.uuid]);

  const fetchCurls = useCallback(() => {
    switch (dataInfo.entity) {
      case "advertiser":
        return fetchAdvertisers();
      case "product":
        return fetchProducts();
      case "impression":
      case "click":
        return fetchEvent();
      case "sale":
        return fetchSaleEvent();
      default:
        return null;
    }
  }, [
    dataInfo.entity,
    fetchAdvertisers,
    fetchProducts,
    fetchEvent,
    fetchSaleEvent,
  ]);

  return (
    <RetailDrawer
      visible={visible}
      title={t("pages.admin.console.curlTest")}
      onOk={closeDrawer}
      onClose={closeDrawer}
      width={756}
      type="dark"
      className={cm.drawer}
      footer={null}
    >
      <section className={cm.curlWarning}>
        <Warning className={cm.warningIcon} />
        <RetailText size="xs" weight="bold">
          {t("pages.admin.console.curlWarning")}
        </RetailText>
      </section>
      <section className={cm.curlWrapper}>
        <article className={cm.curlHeader}>
          <RetailText size="xs" weight="bold">
            {t("pages.admin.console.request")}
          </RetailText>
        </article>
        <div className={cm.curlInner}>
          <Highlight className={`javascript ${cm.curl}`}>
            {renderTestCurl()}
          </Highlight>
        </div>
      </section>
      <RetailMainButton
        onClick={fetchCurls}
        loading={apiStates.loading}
        disabled={apiStates.loading}
        className={cm.button}
      >
        <PlayButtonFilled className={cm.playIcon} />
        {t("pages.admin.console.run")}
      </RetailMainButton>
      {apiStates.response && (
        <>
          <section className={cm.curlWrapper}>
            <article className={cm.curlHeader}>
              <RetailText size="xs" weight="bold">
                {t("pages.admin.console.response")}
              </RetailText>
            </article>
            <div className={cm.curlInner}>
              <Highlight className={`json ${cm.curl}`}>
                {JSON.stringify(apiStates.response, null, 2)}
              </Highlight>
            </div>
          </section>
          <RetailMainButton
            onClick={() => openLogsDrawer(id as number)}
            className={cm.button}
          >
            {t("pages.admin.console.logs")}
          </RetailMainButton>
        </>
      )}
    </RetailDrawer>
  );
};

export default RetailCurlTestDrawer;
