import Upload from "antd/lib/upload";
import { UploadProps } from "antd/lib/upload/interface";
import { ReactComponent as CloseOutlined } from "assets/icons/closeOutlined.svg";
import { ReactComponent as DisplayUploadFilled } from "assets/icons/onboardingCreativeUploadFilled.svg";
import { ReactComponent as RefreshOutlined } from "assets/icons/refreshOutlined.svg";
import { ReactComponent as UploadOutlined } from "assets/icons/uploadOutlined.svg";
import { ReactComponent as VideoUploadFilled } from "assets/icons/videoUploadFilled.svg";
import RetailMainButton from "components/Button/RetailMainButton";
import RetailPreVideoContainer from "components/Container/RetailPreVideoContainer";
import RetailText from "components/Typography/RetailText";
import {
	Trans,
	useTranslation,
} from "react-i18next";
import {
	AdType,
	Img,
	Video,
} from "utils/types";

import CreativeUploadInfo from "../CreativeUploadInfo";
import cm from "./style.module.scss";

export interface SecondDrawerUploadInnerProps extends UploadProps {
  showCreative: boolean;
  video: Video[];
  changeShowCreative(): void;
  img: Img[];
  adType: AdType;
  setOpenUpload: (value: boolean) => void;
  loading: boolean;
  error?: boolean;
}

const SecondDrawerUploadInner = ({
  showCreative,
  video,
  img,
  changeShowCreative,
  adType,
  setOpenUpload,
  loading,
  error,
  ...props
}: SecondDrawerUploadInnerProps) => {
  const { t } = useTranslation();

  const uploadType = adType === "VIDEO" ? "video" : "display";

  const switchPreview = () => {
    if (loading) return null;
    if (error)
      return (
        <section className={cm.errorWrapper}>
          <div className={cm.errorIconContainer}>
            <CloseOutlined />
          </div>
          <RetailText size="xs" weight="bold" className={cm.errorTitle}>
            {t("components.campaignForm.firstStep.displayErrorTitle")}
          </RetailText>
          <RetailText size="xxxs" weight="medium" className={cm.errorText}>
            {t("components.campaignForm.firstStep.displayErrorText")}
          </RetailText>
          <RetailMainButton
            onClick={changeShowCreative}
            className={cm.errorBtn}
          >
            <UploadOutlined />
            {t("components.campaignForm.firstStep.newDisplay")}
          </RetailMainButton>
        </section>
      );
    switch (uploadType) {
      case "video":
        return (
          <section className={cm.wrapper}>
            <RetailPreVideoContainer>
              <div className={cm.footer}>
                <RetailText weight="bold" className={cm.title}>
                  {video[video.length - 1]?.creative_name}
                </RetailText>
                <RetailMainButton
                  onClick={changeShowCreative}
                  className={cm.btn}
                >
                  <RefreshOutlined />
                  {t("components.campaignForm.firstStep.changeVideo")}
                </RetailMainButton>
              </div>
            </RetailPreVideoContainer>
          </section>
        );
      case "display":
        return (
          <section className={cm.wrapper}>
            <div className={cm.container}>
              <div className={cm.imgContainer}>
                <img src={img[img.length - 1]?.url} alt="creative" />
              </div>

              <div className={cm.footer}>
                <article>
                  <RetailText weight="bold" className={cm.title}>
                    {img[img.length - 1]?.creative_name}
                  </RetailText>
                  <RetailText weight="medium" className={cm.text}>
                    {img[img.length - 1]?.size}
                  </RetailText>
                </article>

                <RetailMainButton
                  onClick={changeShowCreative}
                  className={cm.btn}
                >
                  <RefreshOutlined />
                  {t("components.campaignForm.firstStep.changeDisplay")}
                </RetailMainButton>
              </div>
            </div>
          </section>
        );
      default:
        return null;
    }
  };

  const showPreview =
    showCreative && (video?.length > 0 || img?.length > 0) && !loading;

  return (
    <>
      {showPreview ? (
        switchPreview()
      ) : (
        <Upload.Dragger {...props}>
          <div className={cm.uploadContainer}>
            <div className={cm.uploadInner}>
              {adType === "VIDEO" ? (
                <VideoUploadFilled className={`${cm.icon} ${cm.videoIcon}`} />
              ) : (
                <DisplayUploadFilled
                  className={`${cm.icon} ${cm.displayIcon}`}
                />
              )}
              <RetailText size="xs" weight="medium" className={cm.draggerTitle}>
                <Trans>
                  {t(
                    `components.campaignForm.firstStep.${uploadType}DraggerTitle`
                  )}
                </Trans>
              </RetailText>
              <RetailText
                size="xxxs"
                weight="medium"
                className={cm.draggerText}
              >
                {t(
                  `components.campaignForm.firstStep.${uploadType}DraggerText`
                )}
              </RetailText>
            </div>
            <CreativeUploadInfo adType={adType} setOpenUpload={setOpenUpload} />
          </div>
        </Upload.Dragger>
      )}
    </>
  );
};

export default SecondDrawerUploadInner;
