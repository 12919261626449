import { TFunction } from "i18next";

export const campaignFilters = (t: TFunction) => [
  {
    name: t("common.table.name"),
    type: "contains",
    value: "campaignName",
  },
  {
    name: t("common.table.start_date"),
    type: "equality",
    value: "campaignStartDate",
  },
  {
    name: t("common.table.end_date"),
    type: "equality",
    value: "campaignEndDate",
  },
  {
    name: t("common.table.status"),
    type: "twoOptioned",
    value: "campaignStatus",
  },
  {
    name: t("components.campaignForm.firstStep.budget"),
    type: "equality",
    value: "campaignBudget",
  },
  {
    name: t("common.table.budget_type"),
    type: "twoOptioned",
    value: "campaignBudgetType",
  },
  {
    name: t("common.table.bid_type"),
    type: "is",
    value: "campaignBidType",
  },
  {
    name: t("common.table.max_acos"),
    type: "equality",
    value: "campaignMaxAcos",
  },
  {
    name: t("common.table.fixed_bid"),
    type: "equality",
    value: "campaignFixedBid",
  },
  {
    name: t("common.table.sold"),
    type: "equality",
    value: "sumSaleAmount",
  },
  {
    name: t("common.table.expense"),
    type: "equality",
    value: "sumSpend",
  },
  {
    name: t("common.table.acos"),
    type: "equality",
    value: "ACoS",
  },
];

export const productFilters = (t: TFunction) => [
  {
    name: t("common.table.product"),
    type: "contains",
    value: "product",
  },
  {
    name: t("common.table.status"),
    type: "twoOptioned",
    value: "productCampaignStatus",
  },
  {
    name: t("common.table.sold"),
    type: "equality",
    value: "sumSale",
  },
  {
    name: t("common.table.expense"),
    type: "equality",
    value: "sumSpend",
  },
  {
    name: t("common.table.acos"),
    type: "equality",
    value: "ACoS",
  },
  {
    name: t("common.table.click"),
    type: "equality",
    value: "sumClick",
  },
  {
    name: t("marketplaceDependentTable.CPC"),
    type: "equality",
    value: "CPC",
  },
];

export const keywordFilters = (t: TFunction) => [
  {
    name: t("common.table.status"),
    type: "twoOptioned",
    value: "keywordCampaignStatus",
  },
  {
    name: t("common.table.sold"),
    type: "equality",
    value: "sumSale",
  },
  {
    name: t("common.table.expense"),
    type: "equality",
    value: "sumSpend",
  },
  {
    name: t("common.table.acos"),
    type: "equality",
    value: "ACoS",
  },
  {
    name: t("common.table.click"),
    type: "equality",
    value: "sumClick",
  },
  {
    name: t("common.keyword"),
    type: "contains",
    value: "keywordText",
  },
  {
    name: t("components.table.keywords.matchType"),
    type: "is",
    value: "keywordMatchType",
  },
];

export const negativeKeywordFilters = (t: TFunction) => [
  {
    name: t("common.table.status"),
    type: "twoOptioned",
    value: "negativeKeywordCampaignStatus",
  },
  {
    name: t("common.negative"),
    type: "contains",
    value: "negativeKeywordText",
  },
  {
    name: t("components.table.keywords.matchType"),
    type: "is",
    value: "negativeKeywordMatchType",
  },
];

export const citiesFilters = (t: TFunction) => [
  {
    name: t("pages.acc.campaignDetails.city"),
    type: "contains",
    value: "cityName",
  },
  {
    name: t("common.table.status"),
    type: "twoOptioned",
    value: "cityCampaignStatus",
  },
  {
    name: t("common.table.sold"),
    type: "equality",
    value: "sumSale",
  },
  {
    name: t("common.table.expense"),
    type: "equality",
    value: "sumSpend",
  },
  {
    name: t("common.table.acos"),
    type: "equality",
    value: "ACoS",
  },
  {
    name: t("common.table.click"),
    type: "equality",
    value: "sumClick",
  },
  {
    name: t("marketplaceDependentTable.CPC"),
    type: "equality",
    value: "CPC",
  },
];

export const ageFilters = (t: TFunction) => [
  {
    name: t("pages.acc.campaignDetails.age"),
    type: "contains",
    value: "ageRangeText",
  },
  {
    name: t("common.table.status"),
    type: "twoOptioned",
    value: "ageRangeCampaignStatus",
  },
  {
    name: t("common.table.sold"),
    type: "equality",
    value: "sumSale",
  },
  {
    name: t("common.table.expense"),
    type: "equality",
    value: "sumSpend",
  },
  {
    name: t("common.table.acos"),
    type: "equality",
    value: "ACoS",
  },
  {
    name: t("common.table.click"),
    type: "equality",
    value: "sumClick",
  },
  {
    name: t("marketplaceDependentTable.CPC"),
    type: "equality",
    value: "CPC",
  },
];

export const genderFilters = (t: TFunction) => [
  {
    name: t("pages.acc.campaignDetails.gender"),
    type: "contains",
    value: "genderText",
  },
  {
    name: t("common.table.status"),
    type: "twoOptioned",
    value: "genderCampaignStatus",
  },
  {
    name: t("common.table.sold"),
    type: "equality",
    value: "sumSale",
  },
  {
    name: t("common.table.expense"),
    type: "equality",
    value: "sumSpend",
  },
  {
    name: t("common.table.acos"),
    type: "equality",
    value: "ACoS",
  },
  {
    name: t("common.table.click"),
    type: "equality",
    value: "sumClick",
  },
  {
    name: t("marketplaceDependentTable.CPC"),
    type: "equality",
    value: "CPC",
  },
];

export const platformFilters = (t: TFunction) => [
  {
    name: t("pages.acc.campaignDetails.platform"),
    type: "contains",
    value: "platformText",
  },
  {
    name: t("common.table.status"),
    type: "twoOptioned",
    value: "platformCampaignStatus",
  },
  {
    name: t("common.table.sold"),
    type: "equality",
    value: "sumSale",
  },
  {
    name: t("common.table.expense"),
    type: "equality",
    value: "sumSpend",
  },
  {
    name: t("common.table.acos"),
    type: "equality",
    value: "ACoS",
  },
  {
    name: t("common.table.click"),
    type: "equality",
    value: "sumClick",
  },
  {
    name: t("marketplaceDependentTable.CPC"),
    type: "equality",
    value: "CPC",
  },
];

export const customerFilters = (t: TFunction) => [
  {
    name: t("pages.acc.campaignDetails.customer"),
    type: "contains",
    value: "customerTargetingText",
  },
  {
    name: t("common.table.status"),
    type: "twoOptioned",
    value: "customerTargetingCampaignStatus",
  },
  {
    name: t("common.table.sold"),
    type: "equality",
    value: "sumSale",
  },
  {
    name: t("common.table.expense"),
    type: "equality",
    value: "sumSpend",
  },
  {
    name: t("common.table.acos"),
    type: "equality",
    value: "ACoS",
  },
  {
    name: t("common.table.click"),
    type: "equality",
    value: "sumClick",
  },
  {
    name: t("marketplaceDependentTable.CPC"),
    type: "equality",
    value: "CPC",
  },
];

export const placementFilters = (t: TFunction) => [
  {
    name: t("pages.acc.campaignDetails.placement"),
    type: "contains",
    value: "placementText",
  },
  {
    name: t("common.table.status"),
    type: "twoOptioned",
    value: "placementCampaignStatus",
  },
  {
    name: t("common.table.sold"),
    type: "equality",
    value: "sumSale",
  },
  {
    name: t("common.table.expense"),
    type: "equality",
    value: "sumSpend",
  },
  {
    name: t("common.table.acos"),
    type: "equality",
    value: "ACoS",
  },
  {
    name: t("common.table.click"),
    type: "equality",
    value: "sumClick",
  },
  {
    name: t("marketplaceDependentTable.CPC"),
    type: "equality",
    value: "CPC",
  },
];

export const reportFilters = (t: TFunction) => [
  {
    name: t("pages.acc.reports.name"),
    type: "contains",
    value: "reportName",
  },
  {
    name: t("common.table.start_date"),
    type: "equality",
    value: "reportStartDate",
  },
  {
    name: t("common.table.end_date"),
    type: "equality",
    value: "reportEndDate",
  },
  {
    name: t("pages.acc.reports.table.type"),
    type: "twoOptioned",
    value: "reportType",
  },
  {
    name: t("pages.acc.reportDetails.frequency"),
    type: "is",
    value: "reportDeliveryFrequency",
  },
  {
    name: t("common.table.initialDate"),
    type: "equality",
    value: "reportCreatedAt",
  },
];

export const createReportFilters = (t: TFunction) => [
  {
    name: t("components.reportForm.dimensions.campaignName"),
    type: "contains",
    value: "campaignName",
  },
  {
    name: t("common.table.start_date"),
    type: "equality",
    value: "campaignStartDate",
  },
  {
    name: t("common.table.end_date"),
    type: "equality",
    value: "campaignEndDate",
  },
  {
    name: t("common.table.status"),
    type: "twoOptioned",
    value: "campaignStatus",
  },
  {
    name: t("components.campaignForm.firstStep.budget"),
    type: "equality",
    value: "campaignBudget",
  },
  {
    name: t("common.filters.campaignBudgetType"),
    type: "twoOptioned",
    value: "campaignBudgetType",
  },
  {
    name: t("common.table.product"),
    type: "reportEq",
    value: "product",
  },
  {
    name: t("components.reportForm.dimensions.productBrand"),
    type: "reportEq",
    value: "productBrand",
  },
  {
    name: t("common.table.category"),
    type: "reportEq",
    value: "category",
  },
  {
    name: t("components.reportForm.dimensions.keywordText"),
    type: "reportEq",
    value: "keyword",
  },
  {
    name: t("components.reportForm.metric.sumImpression"),
    type: "equality",
    value: "sumImpression",
  },
  {
    name: t("components.reportForm.metric.sumClick"),
    type: "equality",
    value: "sumClick",
  },
  {
    name: t("components.reportForm.metric.sumSpend"),
    type: "equality",
    value: "sumSpend",
  },
  {
    name: t("components.reportForm.metric.sumSale"),
    type: "equality",
    value: "sumSale",
  },
  {
    name: t("components.reportForm.metric.sumSaleAmount"),
    type: "equality",
    value: "sumSaleAmount",
  },
  {
    name: t("components.reportForm.metric.sumDirectSaleAmount"),
    type: "equality",
    value: "sumDirectSaleAmount",
  },
  {
    name: t("components.reportForm.metric.sumIndirectSaleAmount"),
    type: "equality",
    value: "sumIndirectSaleAmount",
  },
  {
    name: t("components.reportForm.metric.sumDirectSale"),
    type: "equality",
    value: "sumDirectSale",
  },
  {
    name: t("components.reportForm.metric.sumIndirectSale"),
    type: "equality",
    value: "sumIndirectSale",
  },
  {
    name: t("components.reportForm.metric.CTR"),
    type: "equality",
    value: "CTR",
  },
  {
    name: t("marketplaceDependentTable.CPC"),
    type: "equality",
    value: "CPC",
  },
  {
    name: t("components.reportForm.metric.CVR"),
    type: "equality",
    value: "CVR",
  },
  {
    name: t("common.table.acos"),
    type: "equality",
    value: "ACoS",
  },
  {
    name: t("marketplaceDependentTable.RoAS"),
    type: "equality",
    value: "RoAS",
  },
  {
    name: t("marketplaceDependentTable.RoAS"),
    type: "equality",
    value: "ROASS",
  },
];

export const createReportFiltersAdmin = (t: TFunction) => [
  ...createReportFilters(t).filter((item) => item.value !== "ROASS"),
  {
    name: t("components.reportForm.dimensions.advertiser"),
    type: "reportEq",
    value: "advertiserId",
  },
  {
    name: t("components.reportForm.dimensions.agency"),
    type: "reportEq",
    value: "agencyId",
  },
  {
    name: t("common.table.agency"),
    type: "reportEq",
    value: "agencyy",
  },
];

export const advertiserFilters = (t: TFunction) => [
  {
    name: t("common.table.name"),
    type: "contains",
    value: "advertiserName",
  },
  {
    name: t("common.table.status"),
    type: "twoOptioned",
    value: "advertiserStatus",
  },
  {
    name: t("common.table.impression"),
    type: "equality",
    value: "sumImpression",
  },
  {
    name: t("common.table.click"),
    type: "equality",
    value: "sumClick",
  },
  {
    name: t("common.table.sold"),
    type: "equality",
    value: "sumSale",
  },
  {
    name: t("common.table.sumSaleAmount"),
    type: "equality",
    value: "sumSaleAmount",
  },
  {
    name: t("common.table.expense"),
    type: "equality",
    value: "sumSpend",
  },
  {
    name: t("common.table.acos"),
    type: "equality",
    value: "ACoS",
  },
  {
    name: t("marketplaceDependentTable.CPC"),
    type: "equality",
    value: "CPC",
  },
  {
    name: t("common.table.ctr"),
    type: "equality",
    value: "CTR",
  },
  {
    name: t("common.table.cvr"),
    type: "equality",
    value: "CVR",
  },
];

export const agencyFilters = (t: TFunction) => [
  {
    name: t("common.table.name"),
    type: "contains",
    value: "agencyName",
  },
];

export const addAdvertiserFilters = (t: TFunction) => [
  {
    name: t("pages.admin.advertisers.product"),
    type: "advertiserValues",
    value: "name",
  },
  {
    name: t("pages.admin.advertisers.brand"),
    type: "advertiserValues",
    value: "brand",
  },
  {
    name: t("pages.admin.advertisers.retailer_taxonomy"),
    type: "advertiserValues",
    value: "retailer_taxonomy",
  },
  {
    name: t("pages.admin.advertisers.advertiser_id"),
    type: "advertiserValues",
    value: "advertiser_id",
  },
];

export const transactionFilters = (t: TFunction) => [
  {
    name: t("common.table.transaction_type"),
    type: "twoOptioned",
    value: "transaction_type",
  },
  {
    name: t("common.table.advertiser_name"),
    type: "contains",
    value: "advertiser_name",
  },
  {
    name: t("common.table.created_by"),
    type: "contains",
    value: "created_by",
  },
];
