import { Input } from "antd";
import cc from "classcat";
import RetailText from "components/Typography/RetailText";
import { useState } from "react";
import { TFunction } from "react-i18next";

import cm from "./style.module.scss";

export interface SecondDrawerVastInnerProps {
  t: TFunction;
  onChange(e: any): void;
  vastTag: string;
}

const SecondDrawerVastInner = ({
  t,
  onChange,
  vastTag,
}: SecondDrawerVastInnerProps) => {
  const [error, setError] = useState(false);

  const isValidVAST =
    /https:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9&%_./~-]*)?/;

  const inputClassName = cc([
    "floating",
    cm.vastTextArea,
    error ? cm.vastErrorInput : "",
  ]);

  const handleChange = (e: any) => {
    if (isValidVAST.test(e.target.value)) {
      setError(false);
      onChange(e);
    } else {
      setError(true);
    }
  };

  return (
    <>
      {error && (
        <article className={`flex ${cm.vastErrorContainer}`}>
          <RetailText size="xxxs" weight="medium">
            {t("components.campaignForm.firstStep.errorStates.vast")}
          </RetailText>
        </article>
      )}
      <Input.TextArea
        onChange={handleChange}
        className={inputClassName}
        value={vastTag}
      />
    </>
  );
};

export default SecondDrawerVastInner;
