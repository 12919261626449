import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Spin } from "antd";
import { Log } from "utils/types";
import { getLocalizedRelativeTime } from "utils/helpers";
import { ReactComponent as ClockOutlined } from "assets/icons/clockOutlined.svg";
import { ReactComponent as LeftOutlined } from "assets/icons/leftOutlined.svg";
import { ReactComponent as RightOutlined } from "assets/icons/rightWhiteOutlined.svg";
import { ReactComponent as SearchOutlined } from "assets/icons/searchOutlined.svg";
import { ReactComponent as Error } from "assets/icons/dark/error.svg";
import { ReactComponent as Warning } from "assets/icons/dark/warning.svg";
import { ReactComponent as Info } from "assets/icons/dark/info.svg";
import { ReactComponent as Minus } from "assets/icons/minusFilled.svg";
import { ReactComponent as Plus } from "assets/icons/plusFilled.svg";
import moment from "moment";
import "highlight.js/styles/vs2015.css";
import Highlight from "react-highlight";
import useApi from "api";
import Empty from "components/Empty";
import RetailDrawer from "../RetailDrawer";
import RetailText from "components/Typography/RetailText";

import cm from "./style.module.scss";

const LogTab = ({
  type,
  active,
  onClick,
}: {
  type: { value: "ERROR" | "WARNING" | "INFO"; icon: JSX.Element };
  active: boolean;
  onClick: () => void;
}) => {
  return (
    <section
      className={`${active ? cm.active : ""} ${cm.logTypeContainer}`}
      onClick={onClick}
    >
      {type.icon}
      <RetailText
        size="xs"
        weight="medium"
        family="poppins"
        className={cm.tabText}
      >
        {type.value}
      </RetailText>
    </section>
  );
};

const logTypes: {
  value: "ERROR" | "WARNING" | "INFO";
  icon: JSX.Element;
}[] = [
  {
    value: "ERROR",
    icon: <Error />,
  },
  {
    value: "WARNING",
    icon: <Warning />,
  },
  {
    value: "INFO",
    icon: <Info />,
  },
];

interface RetailLogsDrawerProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  id: null | number | string;
  historyId?: number | null;
}

const RetailLogsDrawer = ({
  visible,
  setVisible,
  id,
  historyId,
}: RetailLogsDrawerProps) => {
  const { t, i18n } = useTranslation();

  const [dataStates, setDataStates] = useState<{
    type: "ERROR" | "WARNING" | "INFO";
    logs: Log[];
    loading: boolean;
  }>({
    type: "ERROR",
    logs: [],
    loading: false,
  });

  const [expandedLogs, setExpandedLogs] = useState<{ [key: number]: boolean }>(
    {}
  );

  const [timestampsHistory, setTimestampsHistory] = useState<string[]>([]);

  const [currentTimestamp, setCurrentTimestamp] = useState<string | null>(null);

  const [search, setSearch] = useState<string>("");

  const [debouncedSearch, setDebouncedSearch] = useState<string>("");

  const { api } = useApi();

  const fetchLogs = useCallback(
    async (
      type: "ERROR" | "WARNING" | "INFO",
      id: number | string,
      end?: string | null,
      ignoreSearch = false
    ) => {
      setExpandedLogs({});

      setDataStates((prevState) => ({
        ...prevState,
        type,
        loading: true,
      }));

      try {
        const config = {
          log_type: type,
          limit: 50,
          ...(end && { end }),
          ...(historyId && { data_source_history_id: historyId }),
          ...(!ignoreSearch && debouncedSearch && { search: debouncedSearch }),
        };

        const response = await api.post(`data_sources/${id}/logs`, config);

        setDataStates((prevState) => ({
          ...prevState,
          logs: response?.data?.records || [],
          loading: false,
        }));

        setCurrentTimestamp(response?.data?.last_timestamp);

        return response?.data;
      } catch (error) {
        console.error("Error fetching logs:", error);
        setDataStates((prevState) => ({
          ...prevState,
          loading: false,
        }));
        return null;
      }
    },
    [api, debouncedSearch, historyId]
  );

  const handleSearchButtonClick = () =>
    fetchLogs(dataStates.type, id as number);

  const handleNextPage = async () => {
    const response = await fetchLogs(
      dataStates.type,
      id as number,
      currentTimestamp
    );

    if (timestampsHistory.length === 0 && currentTimestamp) {
      setTimestampsHistory([currentTimestamp]);
    }

    if (response && response.last_timestamp) {
      setTimestampsHistory((prev) => [...prev, response.last_timestamp]);
      setCurrentTimestamp(response.last_timestamp);
    }
  };

  const handlePreviousPage = async () => {
    if (timestampsHistory.length > 2) {
      const newHistory = timestampsHistory.slice(0, -1);
      const previousTimestamp = newHistory[newHistory.length - 2];

      setTimestampsHistory(newHistory);
      await fetchLogs(dataStates.type, id as number, previousTimestamp);
    } else {
      setTimestampsHistory([]);
      setCurrentTimestamp(null);
      await fetchLogs(dataStates.type, id as number);
    }
  };

  const closeDrawer = () => {
    setVisible(false);
    setDataStates({
      type: "ERROR",
      logs: [],
      loading: false,
    });
    setCurrentTimestamp(null);
    setTimestampsHistory([]);
    setSearch("");
  };

  const handleLogTabClick = (typeValue: "ERROR" | "WARNING" | "INFO") => {
    if (typeValue === dataStates.type) return;
    fetchLogs(typeValue, id as number, null);
  };

  const toggleExpanded = (index: number) => {
    setExpandedLogs((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const renderLogs = (logs: Log[]) => {
    return logs.map((log, index) => {
      const parsedMessage =
        typeof log.message === "string"
          ? JSON.parse(log?.message)
          : log?.message;

      const combinedLog = {
        ...parsedMessage,
        timestamp: log.timestamp
          ? moment(+log?.timestamp / 1e6).format("DD-MM-YYYY HH:mm:ss")
          : "-",
      };

      const isExpanded = expandedLogs[index];

      return (
        <section className={cm.log} key={index}>
          <div className={cm.logHeader}>
            <div className={cm.logHeaderText}>
              <ClockOutlined />
              <RetailText size="xxxs" weight="medium" className={cm.logTime}>
                {getLocalizedRelativeTime(log?.timestamp, i18n.language)}
              </RetailText>
              <RetailText size="xxxs" weight="medium" className={cm.logMessage}>
                {parsedMessage?.message}
              </RetailText>
            </div>
            <button
              className={cm.expandButton}
              onClick={() => toggleExpanded(index)}
            >
              {isExpanded ? <Minus /> : <Plus />}
              {t(`pages.admin.console.${isExpanded ? "hide" : "show"}`)}
            </button>
          </div>
          <div
            className={cm.innerLogWrapper}
            ref={(el) => {
              if (el) {
                el.style.height = isExpanded ? `${el.scrollHeight}px` : "0px";
              }
            }}
          >
            <article className={cm.innerLog}>
              <RetailText
                size="xxxs"
                weight="medium"
                family="poppins"
                className={cm.logText}
              >
                <Highlight className="json">
                  {JSON.stringify(combinedLog, null, 2)}
                </Highlight>
              </RetailText>
            </article>
          </div>
        </section>
      );
    });
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => clearTimeout(debounce);
  }, [search]);

  useEffect(() => {
    if (visible && id) {
      fetchLogs(dataStates.type, id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, id, debouncedSearch]);

  return (
    <RetailDrawer
      visible={visible}
      title={t("pages.admin.console.drawer")}
      onOk={closeDrawer}
      onClose={closeDrawer}
      width={880}
      type="dark"
      className={cm.drawer}
      footer={null}
    >
      <section className={cm.logTypeWrapper}>
        {logTypes.map((type) => (
          <LogTab
            key={type.value}
            type={type}
            active={type.value === dataStates.type}
            onClick={() => handleLogTabClick(type.value)}
          />
        ))}

        <section
          className={`${cm.prev} ${
            timestampsHistory.length === 0 ? cm.disabled : ""
          }`}
          onClick={handlePreviousPage}
        >
          <LeftOutlined />
          <RetailText
            size="xs"
            weight="medium"
            family="poppins"
            className={cm.tabText}
          >
            {t("pages.admin.console.prev")}
          </RetailText>
        </section>
        <section className={cm.next} onClick={handleNextPage}>
          <RetailText
            size="xs"
            weight="medium"
            family="poppins"
            className={cm.tabText}
          >
            {t("pages.admin.console.next")}
          </RetailText>
          <RightOutlined />
        </section>
      </section>
      <section
        className={
          dataStates.logs.length > 0 && !dataStates.loading
            ? cm.wrapper
            : cm.emptyWrapper
        }
      >
        {!dataStates.loading && (
          <section className={cm.searchContainer}>
            <Input
              prefix={<SearchOutlined />}
              className={cm.searchInput}
              placeholder={t("common.search")}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
            <button
              className={cm.searchButton}
              onClick={handleSearchButtonClick}
            >
              {t("common.search")}
            </button>
          </section>
        )}

        {dataStates.loading ? (
          <Spin spinning={dataStates.loading} />
        ) : dataStates.logs.length > 0 ? (
          renderLogs(dataStates.logs)
        ) : (
          <Empty type="logs" className={cm.empty} />
        )}
      </section>
    </RetailDrawer>
  );
};

export default RetailLogsDrawer;
