import {
	RadioChangeEvent,
	Upload,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import {
	UploadChangeParam,
	UploadFile,
	UploadProps,
} from "antd/lib/upload/interface";
import useApi from "api";
import { ReactComponent as VideoUploadBackground } from "assets/icons/videoUploadBackground.svg";
import { ReactComponent as CreativeIcon } from "assets/images/creatives.svg";
import cc from "classcat";
import RetailMainButton from "components/Button/RetailMainButton";
import RetailDrawer from "components/Drawer/RetailDrawer";
import RetailFormInfo from "components/Form/RetailFormInfo";
import RetailFormRow from "components/Form/RetailFormRow";
import CreativePreviewModal from "components/Modal/CreativePreviewModal";
import RetailNotification from "components/Notification";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import {
	Creative,
	CreativeContext,
} from "context/CreativeProvider";
import {
	useContext,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
	ACCEPTED_FILE_TYPES,
	UPLOAD_FORMATS,
} from "utils/constants";
import { selectCampaignType } from "utils/helpers";
import {
	AdType,
	Img,
	Video,
} from "utils/types";

import CreativeNameForm from "./CreativeNameForm";
import CreativeStepOptions from "./CreativeStepOptions";
import DisplayItem from "./DisplayItem";
import SecondDrawerUploadInner from "./SecondDrawerUploadInner";
import SecondDrawerVastInner from "./SecondDrawerVastInner";
import cm from "./style.module.scss";
import VideoItem from "./VideoItem";

export interface CreativeStepProps {
  type: AdType;
  targeting: string;
  error: boolean;
  languages: string[];
}

const CreativeStep = ({
  type,
  error,
  targeting,
  languages,
}: CreativeStepProps) => {
  const { t } = useTranslation();

  const { api } = useApi();

  const mainImgFile = useRef<File>();

  const { video, setVideo, deleteVideo, img, setImg, deleteCreative } =
    useContext(CreativeContext) as Creative;

  const [openUpload, setOpenUpload] = useState(true);

  const [isMultipleFilesSupported, setIsMultipleFilesSupported] =
    useState(false);

  const [firstDrawerStates, setFirstDrawerStates] = useState({
    visible: false,
    selected: "",
  });

  const [secondDrawerStates, setSecondDrawerStates] = useState<{
    visible: boolean;
    name: string;
    showCreative: boolean;
    isLoading: boolean;
    vastTag?: string;
  }>({
    visible: false,
    name: "",
    showCreative: false,
    isLoading: false,
    vastTag: "",
  });

  const [updateDrawerStates, setUpdateDrawerStates] = useState({
    visible: false,
    uuid: "",
    name: "",
  });

  const [previewStates, setPreviewStates] = useState({
    visible: false,
    url: "",
    name: "",
  });

  const [form] = useForm();

  const open = (url: string, name: string) =>
    setPreviewStates({ visible: true, url, name });

  const close = () => setPreviewStates({ visible: false, url: "", name: "" });

  const showWrapper =
    video?.filter((v) => v.submitted)?.length > 0 ||
    img?.filter((i) => i.submitted)?.length > 0;

  const props: UploadProps = {
    name: "files",
    disabled: secondDrawerStates.isLoading,
    className: cc(["dragger", cm.creativeUploadDragger]),
    beforeUpload: () => false,
    itemRender: () => <></>,
    onChange: (f) =>
      handleFileChange(
        f,
        type === "VIDEO" ? "video" : "image",
        f.fileList.length > 1
      ),
    openFileDialogOnClick: openUpload,
    accept: ACCEPTED_FILE_TYPES[type === "VIDEO" ? "VIDEO" : "IMAGE"],
  };

  const uploadCreative = async (
    info: UploadChangeParam<UploadFile<any>> | null,
    options: {
      type: "image" | "video" | "vast";
      vastTag?: string;
      name?: string;
      onSuccess?: (response: any) => void;
      onError?: (error: any) => void;
    }
  ) => {
    const { type, vastTag, name, onSuccess, onError } = options;

    const file = mainImgFile;
    if (type !== "vast") {
      if (!info || !info.file || info.fileList.length === 0) {
        file.current = undefined;
        return;
      }
      file.current = info.file as unknown as File;
    }

    const formData = new FormData();

    formData.append("status", "ACTIVE");
    formData.append(
      "name",
      type === "vast"
        ? name!
        : file?.current?.name.replace(/\.[^/.]+$/, "") || ""
    );

    if (type === "image" || type === "video") {
      formData.append(type, file.current!);
      formData.append(
        "format",
        type === "image" && info?.file?.type === "image/gif"
          ? UPLOAD_FORMATS.GIF
          : UPLOAD_FORMATS[type.toUpperCase() as keyof typeof UPLOAD_FORMATS]
      );
    } else if (type === "vast") {
      formData.append("format", UPLOAD_FORMATS.VIDEO);
      formData.append("vast_tag", vastTag!);
    }

    try {
      const response = await api.post(
        "/creatives",
        createConfig(formData, type),
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      await handleSuccess(
        response,
        type.toLowerCase() as "image" | "video",
        isMultipleFilesSupported
      );
      if (onSuccess) onSuccess(response);
    } catch (error) {
      if (onError) onError(error);
    }
  };
  const handleFileChange = async (
    info: UploadChangeParam<UploadFile<any>> | null,
    type: "video" | "image",
    isMultiple?: boolean
  ) => {
    if (!info) {
      console.warn("No file information provided.");
      return;
    }

    await uploadCreative(info, {
      type,
      onSuccess: () => {
        setSecondDrawerStates((prev) => ({
          ...prev,
          showCreative: isMultiple ? false : true,
          isLoading: false,
        }));
      },
      onError: (error) => handleError(error, type),
    });
  };

  const handleError = (error: any, type: "image" | "video" | "vast") => {
    console.error(error);
    setSecondDrawerStates((prev) => ({ ...prev, isLoading: false }));

    let errKey = "generalErr";

    if (type === "image" && error?.request?.responseText) {
      const responseText = error.request.responseText;
      errKey = responseText.includes("maximum number of creatives for SMO")
        ? "creativeLimitErr"
        : responseText.includes("file size is too large")
        ? "creativeSizeErr"
        : "generalErr";
    }

    RetailNotification.showNotification(
      "error",
      "",
      t(`components.campaignForm.firstStep.errorStates.${errKey}`)
    );
  };

  const createConfig = (
    formData: FormData,
    type: "image" | "video" | "vast"
  ) => {
    const key = type === "image" ? "image" : type === "video" ? "video" : null;

    return {
      ...(key && { [key]: formData.get(key) }),
      body: JSON.stringify({
        name: formData.get("name"),
        status: formData.get("status"),
        format: formData.get("format"),
        ...(type === "vast" && { vast_tag: formData.get("vast_tag") }),
      }),
    };
  };

  const resetUploadStates = (success: boolean) => {
    const hasCreativeToDelete = video.length > 0 || img.length > 0;

    setFirstDrawerStates({ visible: false, selected: "" });
    setSecondDrawerStates({
      visible: false,
      name: "",
      showCreative: false,
      isLoading: false,
      vastTag: "",
    });

    if (!success && hasCreativeToDelete) {
      switch (type) {
        case "VIDEO":
          deleteVideo(video[video.length - 1].uuid);
          break;
        case "DISPLAY":
          deleteCreative(img[img.length - 1].uuid);
          break;
        default:
          break;
      }
    }
  };

  const postVideoWithVast = async () => {
    await uploadCreative(null, {
      type: "vast",
      name: secondDrawerStates.name,
      vastTag: secondDrawerStates.vastTag,
      onSuccess: () => {
        resetUploadStates(true);
        RetailNotification.showNotification(
          "success",
          "",
          t("components.notification.creativeUpload"),
          "bottomLeft"
        );
      },
      onError: (error) => handleError(error, "vast"),
    });
  };

  const handleSuccess = async (
    response: any,
    type: "image" | "video" | "vast",
    isMultiple?: boolean
  ) => {
    if (type === "image") {
      return new Promise<void>((resolve) => {
        const img = new Image();
        img.src = response.data.image_url;
        if (isMultiple && !response.data.size_valid) {
          RetailNotification.showNotification(
            "error",
            "",
            t(
              "components.campaignForm.firstStep.errorStates.creativeDimensionErr"
            )
          );
        } else {
          img.onload = () => {
            setImg((prev: Img[]) => [
              ...prev,
              {
                url: img.src,
                size: `${img.width}x${img.height}`,
                creative_name: response.data.creative_name,
                uuid: response.data.id,
                size_valid: response.data.size_valid,
                languages: response.data.languages,
                ...(isMultiple && { submitted: true }),
              },
            ]);
            resolve();
          };
        }
      });
    } else if (type === "video" || type === "vast") {
      setVideo((prev: Video[]) => [
        ...prev,
        {
          url: response?.data?.image_url,
          size: response?.data?.size,
          creative_name: response?.data?.creative_name,
          uuid: response?.data?.id,
          submitted: type !== "vast" && isMultiple,
          ...(type === "vast" && {
            vast_tag: response.data.vast_tag,
            submitted: true,
          }),
        },
      ]);
    }
  };

  const openFirstDrawer = () => {
    setFirstDrawerStates({ visible: true, selected: "" });
    setIsMultipleFilesSupported(false);
  };

  const cancelAllDrawers = () => resetUploadStates(false);

  const openSecondDrawer = () =>
    setSecondDrawerStates({
      ...secondDrawerStates,
      visible: true,
    });

  const changeUploadType = ({ target }: RadioChangeEvent) => {
    setFirstDrawerStates({ ...firstDrawerStates, selected: target.value });
    openSecondDrawer();
  };

  const closeSecondDrawer = () => {
    setSecondDrawerStates({
      ...secondDrawerStates,
      visible: false,
    });
    setFirstDrawerStates({ ...firstDrawerStates, selected: "" });
  };

  const resetUpdateStates = () => {
    setUpdateDrawerStates({
      visible: false,
      uuid: "",
      name: "",
    });
  };

  const openUpdateDrawer = (uuid: string) => {
    setUpdateDrawerStates({
      visible: true,
      uuid,
      name:
        type === "VIDEO"
          ? video.find((v) => v.uuid === uuid)?.creative_name || ""
          : img.find((i) => i.uuid === uuid)?.creative_name || "",
    });
  };

  const changeShowCreative = () =>
    setSecondDrawerStates({ ...secondDrawerStates, showCreative: false });

  const updateCreativeName = async () => {
    let success = false;
    const isSelectedCreative = updateDrawerStates.uuid !== "";

    const uploadedVideoID = video[video.length - 1]?.uuid;

    const uploadedDisplayID = img[img.length - 1]?.uuid;

    const currentVideoID = isSelectedCreative
      ? updateDrawerStates.uuid
      : uploadedVideoID;

    const currentDisplayID = isSelectedCreative
      ? updateDrawerStates.uuid
      : uploadedDisplayID;

    try {
      const response = await api
        .patch(`/creatives/${currentVideoID || currentDisplayID}`, {
          name: form.getFieldValue("creative_name"),
        })
        .then((response) => {
          if (type === "VIDEO") {
            setVideo((allVideos: Video[]) =>
              allVideos.map((obj: Video) => {
                if (obj.uuid === currentVideoID) {
                  return {
                    ...obj,
                    creative_name: response.data.creative_name,
                    submitted: true,
                  };
                }
                return obj;
              })
            );
          } else {
            setImg((allImgs: Img[]) =>
              allImgs.map((obj: Img) => {
                if (obj.uuid === currentDisplayID) {
                  return {
                    ...obj,
                    creative_name: response.data.creative_name,
                    submitted: true,
                  };
                }
                return obj;
              })
            );
          }
        });
      success = true;
      RetailNotification.showNotification(
        "success",
        "",
        t("components.notification.creativeUpload"),
        "bottomLeft"
      );
      return response;
    } catch (error) {
      console.error(error);
      success = false;
    } finally {
      isSelectedCreative ? resetUpdateStates() : resetUploadStates(success);
    }
  };

  return (
    <div className={cm.creativeFormRow}>
      <RetailFormRow className="form-row">
        <RetailFormInfo
          column={{
            number: targeting === "AUTO_TARGETING" ? "6" : "7",
            title: t("components.campaignForm.firstStep.creativeTitle"),
            text: t("components.campaignForm.firstStep.videoText"),
          }}
        />
        <div
          className={cc([
            cm.creativeSideContainer,
            error && video.length === 0 ? cm.errorBorder : "",
          ])}
        >
          <VideoUploadBackground className={cm.creativeBackgroundIcon} />
          <CreativeIcon className={cm.creativeIcon} />
          <RetailTitle level={5} className={cm.creativeSideTitle}>
            {t("components.campaignForm.firstStep.videoSideTitle")}
          </RetailTitle>
          <RetailText
            size="xxs"
            weight="medium"
            className={cm.creativeSideText}
          >
            {t("components.campaignForm.firstStep.videoSideText")}
          </RetailText>
          <div className={cm.btnContainer}>
            <Upload {...props} multiple={true} className={cm.bulkUpload}>
              <RetailMainButton
                isPrimaryBorder={true}
                className={cm.creativeSideButton}
                onClick={() => setIsMultipleFilesSupported(true)}
              >
                {t("components.campaignForm.firstStep.bulk")}
              </RetailMainButton>
            </Upload>
            <RetailMainButton
              isPrimaryBorder={true}
              className={cm.creativeSideButton}
              onClick={openFirstDrawer}
            >
              {t("common.add")}
            </RetailMainButton>
          </div>
        </div>
      </RetailFormRow>
      {showWrapper && (
        <div className={cm.cardItemWrapper}>
          <section className={cm.cardItemContainer}>
            {type === "VIDEO"
              ? video.map((v) => (
                  <VideoItem
                    singleVideo={v}
                    deleteVideo={deleteVideo}
                    openUpdateDrawer={openUpdateDrawer}
                    key={v.uuid}
                  />
                ))
              : img.map((i) => (
                  <DisplayItem
                    singleImg={i}
                    deleteCreative={deleteCreative}
                    openUpdateDrawer={openUpdateDrawer}
                    openPreview={open}
                    languages={languages}
                    setImg={setImg}
                    key={i.uuid}
                  />
                ))}
          </section>
        </div>
      )}
      <RetailDrawer
        visible={firstDrawerStates.visible}
        onOk={openSecondDrawer}
        onClose={cancelAllDrawers}
        width={820}
        title={t("components.campaignForm.firstStep.firstDrawerTitle")}
        type="steps"
        disableOk={firstDrawerStates.selected === ""}
        footer={null}
      >
        <RetailTitle level={5} noMargin>
          {t("components.campaignForm.firstStep.creativeTypeTitle")}
        </RetailTitle>
        <RetailText size="xxxs" weight="medium" className={cm.drawerText}>
          {t("components.campaignForm.firstStep.creativeTypeText")}
        </RetailText>
        <CreativeStepOptions
          t={t}
          changeUploadType={changeUploadType}
          firstDrawerStates={firstDrawerStates}
          adType={selectCampaignType(type)}
        />
      </RetailDrawer>
      {/*
       * Upload Drawers
       */}
      <RetailDrawer
        title={t(
          `components.campaignForm.firstStep.${type.toLowerCase()}SecondDrawerTitle`
        )}
        visible={secondDrawerStates.visible}
        onOk={form.submit}
        onClose={closeSecondDrawer}
        onCloseAll={cancelAllDrawers}
        width={772}
        type="add"
        cancelText={t("common.goBack")}
        disableOk={
          secondDrawerStates.name === "" ||
          !secondDrawerStates.showCreative ||
          img[img.length - 1]?.size_valid === false
        }
      >
        <RetailTitle level={5} noMargin>
          {t("components.campaignForm.firstStep.creativeNameTitle")}
        </RetailTitle>
        <RetailText size="xxxs" weight="medium" className={cm.drawerText}>
          {t("components.campaignForm.firstStep.creativeNameText")}
        </RetailText>
        <CreativeNameForm
          onFinish={
            firstDrawerStates.selected === "VAST"
              ? postVideoWithVast
              : updateCreativeName
          }
          form={form}
          value={secondDrawerStates.name}
          onChange={(e) =>
            setSecondDrawerStates({
              ...secondDrawerStates,
              name: e.target.value,
            })
          }
          t={t}
        />
        <RetailTitle level={5} noMargin>
          {t(
            `components.campaignForm.firstStep.${firstDrawerStates.selected.toLowerCase()}`
          )}
        </RetailTitle>
        <RetailText size="xxxs" weight="medium" className={cm.drawerText}>
          {t(
            `components.campaignForm.firstStep.${firstDrawerStates.selected.toLowerCase()}_drawer`
          )}
        </RetailText>
        {firstDrawerStates.selected?.includes("UPLOAD") ? (
          <SecondDrawerUploadInner
            showCreative={secondDrawerStates.showCreative}
            video={video}
            changeShowCreative={changeShowCreative}
            img={img}
            adType={selectCampaignType(type)}
            setOpenUpload={setOpenUpload}
            loading={secondDrawerStates.isLoading}
            error={img[img.length - 1]?.size_valid === false}
            {...props}
          />
        ) : (
          <SecondDrawerVastInner
            t={t}
            onChange={(e) =>
              setSecondDrawerStates({
                ...secondDrawerStates,
                vastTag: e.target.value,
                showCreative: e.target.value !== "",
              })
            }
            vastTag={secondDrawerStates.vastTag || ""}
          />
        )}
      </RetailDrawer>

      <RetailDrawer
        title={t("components.campaignForm.firstStep.updateDrawerTitle")}
        visible={updateDrawerStates.visible}
        onOk={form.submit}
        onClose={resetUpdateStates}
        mode="update"
        width={772}
      >
        <RetailTitle level={5} noMargin>
          {t("components.campaignForm.firstStep.creativeNameTitle")}
        </RetailTitle>
        <RetailText size="xxxs" weight="medium" className={cm.drawerText}>
          {t("components.campaignForm.firstStep.creativeNameText")}
        </RetailText>
        <CreativeNameForm
          onFinish={updateCreativeName}
          form={form}
          value={updateDrawerStates.name}
          onChange={(e) =>
            setUpdateDrawerStates({
              ...updateDrawerStates,
              name: e.target.value,
            })
          }
          t={t}
        />
      </RetailDrawer>

      <CreativePreviewModal
        type={type === "VIDEO" ? "video" : "creative"}
        url={previewStates.url}
        visible={previewStates.visible}
        name={previewStates.name}
        onCancel={close}
      />
    </div>
  );
};

export default CreativeStep;
