import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import useApi from "api";
import { ReactComponent as CreativeLanguage } from "assets/icons/creativeLanguage.svg";
import { ReactComponent as DisplayFilled } from "assets/icons/displayFilled.svg";
import { ReactComponent as EditOutlined } from "assets/icons/editWhiteOutlined.svg";
import { ReactComponent as SearchOutlined } from "assets/icons/searchOutlined.svg";
import RetailSearchBar from "components/Bar/RetailSearchBar";
import RetailDrawer from "components/Drawer/RetailDrawer";
import RetailNotification from "components/Notification";
import RetailTooltip from "components/Tooltip/RetailTooltip";
import RetailText from "components/Typography/RetailText";
import { useState } from "react";
import {
	Trans,
	useTranslation,
} from "react-i18next";
import {
	renderFlagOrItem,
	switchFlag,
} from "utils/helpers";
import { Img } from "utils/types";

import {
	DeleteOutlined,
	InfoCircleFilled,
} from "@ant-design/icons";

import cm from "./style.module.scss";

export interface DisplayItemProps {
  singleImg: Img;
  languages: string[];
  deleteCreative(uuid: string): void;
  openUpdateDrawer(uuid: string): void;
  openPreview(url: string, name: string): void;
  setImg: React.Dispatch<React.SetStateAction<Img[]>>;
}

const DisplayItem = ({
  singleImg,
  languages,
  deleteCreative,
  openUpdateDrawer,
  openPreview,
  setImg,
}: DisplayItemProps) => {
  const { t } = useTranslation();

  const { api } = useApi();

  const {
    uuid,
    url,
    languages: creativeLanguages,
    creative_name,
    submitted,
  } = singleImg;

  const [languageDrawerVisible, setLanguageDrawerVisible] = useState(false);

  const [selectedLanguages, setSelectedLanguages] = useState<
    CheckboxValueType[]
  >(singleImg?.languages || []);

  const [indeterminate, setIndeterminate] = useState(false);

  const [checkAll, setCheckAll] = useState(
    selectedLanguages?.length === languages?.length
  );

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    setSearchValue(target.value);

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setSelectedLanguages(e.target.checked ? languages : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const handleChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedLanguages(checkedValues);
    setIndeterminate(
      !!checkedValues.length && checkedValues.length < languages?.length
    );
    setCheckAll(checkedValues.length === languages?.length);
  };

  const updateCreative = async (
    key: string,
    value: string | CheckboxValueType[]
  ) => {
    const response = await api
      .patch(`/creatives/${uuid}`, {
        [key]: value,
      })
      .then((response: any) => {
        setImg((current: Img[]) =>
          current.map((obj: Img) => {
            if (obj.uuid === uuid) {
              return {
                ...obj,
                name: response.data?.name,
                languages: response.data?.languages,
              };
            }
            return obj;
          })
        );
        setSelectedLanguages(response.data?.languages);
      });

    return response;
  };

  const closeDrawer = () => {
    setLanguageDrawerVisible(false);
    setCheckAll(selectedLanguages?.length === languages?.length);
    setIndeterminate(
      languages?.length >
        (singleImg?.languages?.length || selectedLanguages?.length)
    );
    setSelectedLanguages(singleImg?.languages || []);
  };

  const handleOk = async () => {
    try {
      await updateCreative("languages", selectedLanguages);
    } catch (error) {
      console.error(error);
      RetailNotification.showNotification(
        "error",
        "",
        t("components.campaignForm.firstStep.errorStates.generalErr")
      );
    } finally {
      setLanguageDrawerVisible(false);
    }
  };

  const editDisplayItem = () => openUpdateDrawer(uuid);

  const deleteDisplayItem = () => deleteCreative(uuid);

  const openDrawer = () => setLanguageDrawerVisible(true);

  const openPreviewModal = () => openPreview(url, creative_name);

  const isLanguageSelectable = languages?.length > 0;

  const filteredLanguages = languages?.filter((item) => {
    const localizedLanguage = t(`common.languages.${item?.toLowerCase()}`);
    return localizedLanguage.toLowerCase().includes(searchValue);
  });

  const languageClassName = `flex ${cm.displayCardIcon} ${
    creativeLanguages && creativeLanguages?.length > 0 ? cm.languageActive : ""
  }`;

  return (
    <section className={`${cm.displayCard} ${submitted ? cm.submitted : ""}`}>
      <div className={cm.imgContainer}>
        <img src={url} alt={creative_name} />
        <div className={cm.displayButton} onClick={openPreviewModal}>
          <SearchOutlined />
          <RetailText size="xxxs" weight="bold">
            {t("common.view")}
          </RetailText>
        </div>
      </div>
      <div className={`flex ${cm.displayCardMeta}`}>
        <DisplayFilled className={cm.displayIcon} />
        <RetailText weight="bold" className={`flex ${cm.displayCardMetaText}`}>
          {t("pages.acc.campaigns.table.display")}
        </RetailText>
      </div>
      <div className={cm.displayNameContainer}>
        <Trans>
          <RetailText size="xxxs" weight="medium" className={cm.creativeName}>
            {t("components.campaignForm.firstStep.creativeName", {
              value: creative_name,
            })}
          </RetailText>
        </Trans>
        <RetailTooltip title={creative_name}>
          <InfoCircleFilled />
        </RetailTooltip>
      </div>
      <div className={cm.displayCardFooter}>
        <div className={cm.displayCardIcon} onClick={editDisplayItem}>
          <EditOutlined className={cm.editIcon} />
        </div>
        <div className={cm.displayCardIcon} onClick={deleteDisplayItem}>
          <DeleteOutlined className={cm.deleteIcon} />
        </div>
        {isLanguageSelectable && (
          <div className={languageClassName} onClick={openDrawer}>
            <div className={cm.languageContainer}>
              <RetailText size="xxxs" weight="bold">
                {renderFlagOrItem(selectedLanguages || languages)}
              </RetailText>
            </div>
            <CreativeLanguage className={cm.language} />
          </div>
        )}
      </div>
      {languages?.length > 0 && (
        <RetailDrawer
          visible={languageDrawerVisible}
          title={t("components.campaignForm.firstStep.languageTitle")}
          onOk={handleOk}
          onClose={closeDrawer}
          disableOk={selectedLanguages?.length === 0}
          okText={t("common.apply")}
        >
          <RetailSearchBar
            placeholder={t("components.campaignForm.firstStep.languageSearch")}
            onChange={handleSearch}
            value={searchValue}
          />
          <div className={cm.languageCheckboxWrapper}>
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              value={checkAll}
              className={cm.creativeLanguageItem}
              defaultChecked={true}
            >
              <RetailText size="xs">{t("common.selectAll")}</RetailText>
            </Checkbox>
            <Checkbox.Group
              className={cm.creativeLanguages}
              defaultValue={selectedLanguages}
              value={selectedLanguages}
              onChange={handleChange}
            >
              {filteredLanguages?.map((item) => (
                <Checkbox
                  value={item}
                  key={item}
                  className={cm.creativeLanguageItem}
                >
                  <div className="flex">
                    <RetailText size="xs">
                      {t(`common.languages.${item?.toLowerCase()}`)}
                    </RetailText>
                    <RetailText size="xxs" weight="medium">
                      {switchFlag(item)}
                    </RetailText>
                  </div>
                </Checkbox>
              ))}
            </Checkbox.Group>
          </div>
        </RetailDrawer>
      )}
    </section>
  );
};

export default DisplayItem;
