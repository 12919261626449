import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { Form } from "antd";
import { ConfigStates, EntityStates, MethodStates } from "../../../utils/types";
import { ReactComponent as LeftOutlined } from "../../../assets/icons/leftOutlined.svg";
import { switchMethod } from "../../../utils/helpers";
import useApi from "../../../api";
import RetailDatasourceForm from "../../../components/Form/RetailDatasourceForm";
import RetailMainButton from "../../../components/Button/RetailMainButton";
import RetailNotification from "../../../components/Notification";
import RetailTitle from "../../../components/Typography/RetailTitle";
import RetailSuccessModal from "../../../components/Modal/RetailSuccessModal";
import RetailErrorModal from "../../../components/Modal/RetailErrorModal";

import cc from "classcat";
import cm from "./style.module.scss";

const CreateDatasourcePage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { api } = useApi();

  const [form] = Form.useForm();

  const [navbarScrolled, setNavbarScrolled] = useState(false);

  const [entityStates, setEntityStates] = useState<EntityStates>({
    mode: "create",
    selected: null,
    filled: false,
    visible: false,
    secondaryVisible: false,
    showFields: false,
  });

  const [methodStates, setMethodStates] = useState<MethodStates>({
    mode: "create",
    selected: null,
    filled: false,
    visible: false,
  });

  const [configStates, setConfigStates] = useState<ConfigStates>({
    filled: false,
    name: "",
    url: "",
    cycle: "",
    data: null,
    loading: false,
    file: null,
    xml: "",
  });

  const [loading, setLoading] = useState(false);

  const [modalStates, setModalStates] = useState<{
    visible: boolean;
    mode: string;
    type: "file" | "xml";
    id?: string;
  }>({
    visible: false,
    mode: "",
    type: "file",
    id: "",
  });

  const [isValid, setIsValid] = useState(false);

  const onCancel = () => navigate("/admin/console");

  const switchErrKey = (err: AxiosError) => {
    if (err?.request?.responseText?.includes("already a field"))
      return "existsErr";
    else if (err?.request?.responseText?.includes("feed_url field"))
      return "feedErr";
    else if (err?.request?.responseText?.includes("schedule_interval"))
      return "cycleErr";
    else if (err?.request?.responseText?.includes("could not be accessed"))
      return "permissionErr";
    else return "generalErr";
  };

  const isFeedUrlSelected =
    methodStates.selected === "googleFeedUrl" ||
    methodStates.selected === "metaFeedUrl";

  const validateFields = async () => {
    const formData = new FormData();

    formData.append(
      "body",
      JSON.stringify({
        import_method: switchMethod(methodStates.selected),
        ...(isFeedUrlSelected && {
          feed_url: configStates.url,
        }),
        ...(methodStates.selected === "googleFeedDirectXml" && {
          file_type: "xml",
        }),
        data_maps: [
          ...configStates?.data?.imported_fields
            .filter((field: any) => field?.default_system_fields)
            .map((field: any) => ({
              source_field_name: field?.default_system_fields,
              target_field_name: field?.field,
            })),
          {
            source_field_name: "status",
            default_value: "ACTIVE",
          },
        ],
      })
    );

    if (methodStates.selected === "googleFeedFile")
      formData.append("file", configStates.file);

    if (methodStates.selected === "googleFeedDirectXml")
      formData.append("file_body", configStates.xml);

    try {
      const response = await api.post("data_sources/validate", formData);
      if (response?.data?.errors?.length) {
        setIsValid(false);
        response?.data?.errors?.forEach((error: any) => {
          RetailNotification.showNotification("error", "", error.error);
        });
      } else {
        setIsValid(true);
        RetailNotification.showNotification(
          "success",
          t("pages.admin.createDatasource.validateSuccess"),
          t("pages.admin.createDatasource.validateSuccessText")
        );
      }
    } catch (error) {
      setIsValid(false);
      RetailNotification.showNotification(
        "error",
        "",
        t(`pages.admin.createDatasource.${switchErrKey(error)}`)
      );
    }
  };

  const createDatasource = async () => {
    setLoading(true);

    const { url, name, cycle } = form.getFieldsValue();

    const formData = new FormData();

    formData.append(
      "body",
      JSON.stringify({
        entity: entityStates.selected?.toUpperCase(),
        import_method: switchMethod(methodStates.selected),
        name: name || "",
        ...(isFeedUrlSelected && {
          feed_url: url,
          schedule_interval: cycle,
        }),
        ...(methodStates.selected === "googleFeedDirectXml" && {
          file_type: "xml",
        }),
        ...(methodStates.selected !== "api" && {
          data_maps: [
            ...configStates?.data?.imported_fields
              .filter((field: any) => field?.default_system_fields)
              .map((field: any) => ({
                source_field_name: field?.default_system_fields,
                target_field_name: field?.field,
              })),
            {
              source_field_name: "status",
              default_value: "ACTIVE",
            },
          ],
        }),
        status: "ACTIVE",
      })
    );

    if (methodStates.selected === "googleFeedFile")
      formData.append("file", configStates.file);

    if (methodStates.selected === "googleFeedDirectXml")
      formData.append("file_body", configStates.xml);

    const showPopup =
      methodStates.selected === "googleFeedFile" ||
      methodStates.selected === "googleFeedDirectXml";
    try {
      const response = await api.post("data_sources", formData);
      if (showPopup) {
        setModalStates({
          visible: true,
          mode: "success",
          type: methodStates.selected === "googleFeedFile" ? "file" : "xml",
          id: response?.data?.id,
        });
      } else
        navigate(
          methodStates.selected === "api"
            ? "/admin/console"
            : `/admin/console/${response?.data?.id}`
        );
    } catch (error) {
      console.error(error);

      if (showPopup) {
        setModalStates({
          visible: true,
          mode: "error",
          type: methodStates.selected === "googleFeedFile" ? "file" : "xml",
        });
      } else {
        return RetailNotification.showNotification(
          "error",
          "",
          t(`pages.admin.createDatasource.${switchErrKey(error)}`)
        );
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => setNavbarScrolled(window.scrollY > 50);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={cm.pageWrapper}>
      <section className={cm.pageContainer}>
        <nav
          className={cc(["flex", cm.nav, navbarScrolled ? cm.scrolledNav : ""])}
        >
          <div className="flex">
            <button className={cc(["flex", cm.close])} onClick={onCancel}>
              <LeftOutlined />
            </button>
            <RetailTitle level={4} className={cm.title} noMargin>
              {t("pages.admin.createDatasource.title")}
            </RetailTitle>
          </div>
        </nav>

        {/* Content */}

        <RetailDatasourceForm
          form={form}
          entityStates={entityStates}
          setEntityStates={setEntityStates}
          methodStates={methodStates}
          setMethodStates={setMethodStates}
          configStates={configStates}
          setConfigStates={setConfigStates}
          validateFields={validateFields}
        />

        <div className={cc(["flex", cm.footer])}>
          <RetailMainButton onClick={onCancel}>
            {t("common.cancel")}
          </RetailMainButton>

          <RetailMainButton
            htmlType="button"
            hasBackground
            className={cm.saveBtn}
            onClick={createDatasource}
            loading={loading}
            disabled={methodStates.selected === "api" ? false : !isValid}
          >
            {t("pages.admin.createDatasource.save")}
          </RetailMainButton>
        </div>
      </section>
      {modalStates.visible && modalStates.mode === "success" && (
        <RetailSuccessModal
          type={modalStates.type}
          visible={modalStates.visible}
          setVisible={(visible) => setModalStates({ ...modalStates, visible })}
          onClick={() => navigate(`/admin/console/${modalStates.id}`)}
        />
      )}

      {modalStates.visible && modalStates.mode === "error" && (
        <RetailErrorModal
          type={modalStates.type!}
          visible={modalStates.visible}
          setVisible={(visible) => setModalStates({ ...modalStates, visible })}
        />
      )}
    </div>
  );
};

export default CreateDatasourcePage;
