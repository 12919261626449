import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { DataSchemaItem, EntityStates } from "../../../../utils/types";
import { ReactComponent as DownOutlined } from "../../../../assets/icons/downOutlined.svg";
import { ReactComponent as PlayButtonOutlined } from "../../../../assets/icons/playButtonOutlined.svg";
import RetailDataSchemaItem from "../RetailEntityDrawers/RetailDataSchemaItem";
import RetailEntityDrawers from "../RetailEntityDrawers";
import RetailMainButton from "../../../Button/RetailMainButton";
import RetailText from "../../../Typography/RetailText";

import cm from "./style.module.scss";

interface RetailEntityRowProps {
  entityStates: EntityStates;
  setEntityStates: React.Dispatch<React.SetStateAction<EntityStates>>;
  setActive: (id: string) => void;
}

const RetailEntityRow = ({
  entityStates,
  setEntityStates,
  setActive,
}: RetailEntityRowProps) => {
  const { t } = useTranslation();

  const { data, isError, isLoading } = useQuery<any>("entities", {
    retry: false,
    enabled: entityStates.filled,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const [videoVisible, setVideoVisible] = useState(false);

  const entityTitle = entityStates.filled
    ? t(`components.datasource.${entityStates.selected}`)
    : t("components.datasource.emptyEntity");

  //Delete productEntityText and advertiserEntityText from lang files
  const entityText = entityStates.filled
    ? t(`components.datasource.${entityStates.selected}Text`)
    : t("components.datasource.emptyEntityText");

  const isEntityNotIterable =
    !entityStates.filled || !entityStates.selected || isError || isLoading;

  const openEntityDrawer = () =>
    setEntityStates((prev) => ({ ...prev, visible: true }));

  const onOk = () => {
    setEntityStates((prev) => ({ ...prev, visible: false, filled: true }));
    setActive("method");
  };

  const cancelEntityDrawer = () => {
    setEntityStates((prev) => ({ ...prev, selected: null, visible: false }));
  };

  const openShowFields = () =>
    setEntityStates((prev) => ({
      ...prev,
      showFields: !prev.showFields,
    }));

  const openVideo = () => setVideoVisible(true);

  const closeVideo = () => setVideoVisible(false);

  return (
    <>
      <section
        className={`${cm.contentContainer} ${
          entityStates.filled ? cm.active : ""
        }`}
        id="entity"
      >
        <div className={cm.content}>
          <Trans>
            <h2
              className={`${entityStates.filled ? cm.titleFilled : ""}  ${
                cm.title
              }`}
            >
              {entityTitle}
            </h2>
          </Trans>
          <p className={cm.text}>{entityText}</p>
          {!entityStates.filled ? (
            <RetailMainButton
              hasBackground
              onClick={openEntityDrawer}
              className={cm.btn}
            >
              {t("components.datasource.selectEntity")}
            </RetailMainButton>
          ) : entityStates.selected === "product" ? (
            <RetailText
              size="xxxs"
              weight="medium"
              className={cm.how}
              onClick={openVideo}
            >
              <PlayButtonOutlined />
              {t("components.datasource.how")}
            </RetailText>
          ) : null}
        </div>
        {isEntityNotIterable ? null : (
          <section>
            <div
              className={`${cm.fieldContainer} ${
                entityStates.showFields && cm.openFields
              }`}
            >
              {data?.data[entityStates.selected || "product"]?.map(
                (item: DataSchemaItem) => (
                  <RetailDataSchemaItem
                    key={item.field}
                    item={item}
                    mode="form"
                  />
                )
              )}
            </div>
            <div
              className={`${cm.bottom} ${entityStates.showFields && cm.close}`}
              onClick={openShowFields}
            >
              <RetailText size="xxxs" weight="bold" className={cm.showText}>
                {t(
                  `components.datasource.${
                    entityStates.showFields ? "hide" : "show"
                  }Fields`
                )}
              </RetailText>
              <DownOutlined
                className={entityStates.showFields ? cm.iconUp : cm.iconDown}
              />
            </div>
          </section>
        )}
      </section>
      <RetailEntityDrawers
        entityStates={entityStates}
        setEntityStates={setEntityStates}
        onOk={onOk}
        onClose={cancelEntityDrawer}
        videoVisible={videoVisible}
        openVideo={openVideo}
        closeVideo={closeVideo}
      />
    </>
  );
};

export default RetailEntityRow;
