import { Radio } from "antd";
import { ReactComponent as CodeUploadFilled } from "assets/icons/codeUploadFilled.svg";
import { ReactComponent as CreativeUploadFilled } from "assets/icons/onboardingCreativeUploadFilled.svg";
import { ReactComponent as VideoUploadFilled } from "assets/icons/videoUploadFilled.svg";
import cc from "classcat";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import { TFunction } from "react-i18next";
import { AdType } from "utils/types";

import cm from "./style.module.scss";

interface CreativeStepOptionsProps {
  t: TFunction;
  changeUploadType(e: any): void;
  firstDrawerStates: any;
  adType: AdType;
}

const CreativeStepOptions = ({
  t,
  changeUploadType,
  firstDrawerStates,
  adType,
}: CreativeStepOptionsProps) => {
  return (
    <Radio.Group
      value={firstDrawerStates.selected}
      onChange={changeUploadType}
      className={cm.radioContainer}
    >
      {adType === "VIDEO" ? (
        <>
          <Radio
            value="VIDEO_UPLOAD"
            className={cc(["form-radio", cm.radioItem])}
          >
            <div className={cm.radioInner}>
              <VideoUploadFilled
                className={
                  firstDrawerStates.selected?.includes("UPLOAD")
                    ? cm.selectedIcon
                    : cm.regularIcon
                }
              />
              <article>
                <RetailTitle level={5} noMargin className="creativeInnerTitle">
                  {t("components.campaignForm.firstStep.mp4Title")}
                </RetailTitle>
                <RetailText className={cm.drawerRadioText} size="xxxs">
                  {t("components.campaignForm.firstStep.mp4Text")}
                </RetailText>
              </article>
            </div>
          </Radio>
          <Radio value="VAST" className={cc(["form-radio", cm.radioItem])}>
            <div className={cm.radioInner}>
              <CodeUploadFilled
                className={
                  firstDrawerStates.selected === "VAST"
                    ? cm.selectedIcon
                    : cm.regularIcon
                }
              />
              <article>
                <RetailTitle level={5} noMargin className="creativeInnerTitle">
                  {t("components.campaignForm.firstStep.vastTitle")}
                </RetailTitle>
                <RetailText size="xxxs" className={cm.drawerRadioText}>
                  {t("components.campaignForm.firstStep.vastText")}
                </RetailText>
              </article>
            </div>
          </Radio>
        </>
      ) : (
        <Radio
          value="DISPLAY_UPLOAD"
          className={cc(["form-radio", cm.radioItem])}
        >
          <div className={cm.radioInner}>
            <CreativeUploadFilled
              className={
                firstDrawerStates.selected?.includes("UPLOAD")
                  ? cm.selectedIcon
                  : cm.regularIcon
              }
            />
            <article>
              <RetailTitle level={5} noMargin className="creativeInnerTitle">
                {t("components.campaignForm.firstStep.displayUploadTitle")}
              </RetailTitle>
              <RetailText className={cm.drawerRadioText} size="xxxs">
                {t("components.campaignForm.firstStep.displayUploadText")}
              </RetailText>
            </article>
          </div>
        </Radio>
      )}
    </Radio.Group>
  );
};

export default CreativeStepOptions;
