import Col from "antd/lib/col";
import { Header } from "antd/lib/layout/layout";
import cc from "classcat";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { Inbox } from "@novu/react";
import useApi from "api";
import { ReactComponent as Bell } from "assets/icons/bellOutlined.svg";
import { ReactComponent as BellActive } from "assets/icons/bellActiveOutlined.svg";
import { ReactComponent as LeftOutlined } from "assets/icons/leftOutlined.svg";
import { ReactComponent as SyncOutlined } from "assets/icons/menu/syncOutlined.svg";
import logo from "assets/images/logo.svg";
import { Auth, AuthContext } from "context/AuthProvider";
import { SMOContext, SMOContextType } from "context/SMOProvider";
import useSettings from "hooks/useSettings";
import {
  bg_mode,
  marketplaceLogo,
  notificationApplicationId,
  notificationBackendUrl,
  notificationSocketUrl,
} from "utils/defaults";

import RetailAdminBar from "components/Bar/RetailAdminBar";
import RetailDocsBar from "components/Bar/RetailDocsBar";
import RetailBalanceContainer from "components/Container/RetailBalanceContainer";
import RetailAdvertiserViewLink from "components/Link/RetailAdvertiserViewLink";
import RetailNavbarPopover from "components/Popover/RetailNavbarPopover";
import RetailTitle from "components/Typography/RetailTitle";
import RetailBreadcrumb from "./RetailBreadcrumb";
import RetailBrowserBreadcrumb from "./RetailBrowserBreadcrumb";
import RetailNotificationContainer from "components/Container/RetailNotificationContainer";

import cm from "./style.module.scss";

const RetailPageNavbar = () => {
  const { t, i18n } = useTranslation();

  const [inboxOpen, setInboxOpen] = useState(false);

  const [subscriberId, setSubscriberId] = useState("");

  const { showAdminBar, authInfo, accountIndex } = useContext(
    AuthContext
  ) as Auth;

  const { showDocs } = useContext(SMOContext) as SMOContextType;

  const { roleForDashboard } = useApi();

  const { data } = useSettings(
    roleForDashboard,
    roleForDashboard === "ADVERTISER"
  );

  const navigate = useNavigate();

  const location = useLocation();

  const adminNavbar = location.pathname.includes("admin");

  const userType = adminNavbar ? "admin" : "user";

  const breadcrumb = location.state as {
    breadcrumb: boolean;
    breadcrumbType: string;
  };

  const isBreadcrumbFromBrowser =
    /\d/.test(decodeURI(location.pathname)) ||
    location.pathname.includes("academy/");

  const languageSelect = adminNavbar
    ? location.pathname.substring(7)
    : location.pathname.substring(1);

  const isCampaign =
    location.pathname === "/" ||
    location.pathname === "/admin" ||
    location.pathname === "/admin/";

  const adminBarTopValue = showDocs ? 38 : 0;

  const navbarTopValue = () => {
    if (showAdminBar && showDocs) return 104;

    if (showAdminBar) return 66;

    if (showDocs) return 38;

    return 0;
  };

  const title = () => {
    if (isCampaign) return t("sider.user.campaign");
    if (i18n.exists(`sider.${userType}.${languageSelect}`))
      return t(`sider.${userType}.${languageSelect}`);
    return "";
  };

  const handleNavigate = () => navigate("/admin/synchronization");

  const toggleInbox = () => setInboxOpen(!inboxOpen);

  const appearance = {
    elements: {
      popoverTrigger: cm.bell,
      popoverContent: cm.popoverContent,
      inboxHeader: cm.inboxHeader,
      preferencesHeader: cm.inboxHeader,
      inboxStatus__title: cm.inboxTitle,
      preferencesHeader__title: cm.inboxTitle,
      preferences__button: cm.preferencesButton,
      moreActions__dropdownTrigger: cm.moreActionsButton,
      preferencesHeader__back__button: cm.preferencesBackButton,
    },
  };

  useEffect(() => {
    return () => {
      setInboxOpen(false);
    };
  }, [location]);

  useEffect(() => {
    if (authInfo && authInfo[accountIndex]?.account_user_id) {
      const id = authInfo[accountIndex].account_user_id.toString();
      setSubscriberId(id);
    }
  }, [authInfo, accountIndex]);

  return (
    <>
      {showDocs && <RetailDocsBar />}
      {showAdminBar && <RetailAdminBar topValue={adminBarTopValue} />}
      <Header
        className={cc([cm.navbar, cm[bg_mode]])}
        style={{ top: navbarTopValue() }}
      >
        {/** Logo */}
        <div className={cm.logo}>
          <RetailAdvertiserViewLink to={adminNavbar ? "/admin/campaigns" : "/"}>
            <img alt="" className={cm.logoImg} src={marketplaceLogo || logo} />
          </RetailAdvertiserViewLink>
        </div>

        {breadcrumb !== null && breadcrumb.breadcrumb ? (
          <RetailBreadcrumb />
        ) : isBreadcrumbFromBrowser ? (
          <RetailBrowserBreadcrumb />
        ) : (
          <RetailTitle className={cm.title} level={5}>
            {title()}
          </RetailTitle>
        )}

        {userType === "user" ? (
          <>
            {data?.data?.seller_site && (
              <Col className={cc(["flex", cm.link, cm[bg_mode]])}>
                <a
                  href={`https://${data?.data?.seller_site}`}
                  className="flex"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LeftOutlined />
                  {t("components.navbar.site")}
                </a>
              </Col>
            )}
            {/*
             * Show balance on navbar
             */}
            <RetailBalanceContainer
              className={data?.data?.seller_site ? "" : cm.noSellerSite}
            />
            {/*
             * Show notification bell on navbar
             */}
            {subscriberId && (
              <Inbox
                backendUrl={notificationBackendUrl}
                socketUrl={notificationSocketUrl}
                applicationIdentifier={notificationApplicationId}
                subscriberId={subscriberId}
                appearance={appearance}
                routerPush={undefined}
                open={inboxOpen}
                localization={{
                  "inbox.filters.labels.default": t("components.inbox.title"),
                  "inbox.filters.dropdownOptions.default": t(
                    "components.inbox.default"
                  ),
                  "notifications.emptyNotice": t("components.inbox.empty"),
                  "inbox.filters.dropdownOptions.unread": t(
                    "components.inbox.unread"
                  ),
                  "inbox.filters.labels.unread": t("components.inbox.unread"),
                  "inbox.filters.dropdownOptions.archived": t(
                    "components.inbox.archived"
                  ),
                  "inbox.filters.labels.archived": t(
                    "components.inbox.archived"
                  ),
                  "notifications.actions.readAll": t(
                    "components.inbox.readAll"
                  ),
                  "notifications.actions.archiveAll": t(
                    "components.inbox.archiveAll"
                  ),
                  "notifications.actions.archiveRead": t(
                    "components.inbox.archiveRead"
                  ),
                  "preferences.title": t("components.inbox.preferences"),
                  "preferences.global": t("components.inbox.global"),
                  dynamic: {
                    AdvertiserBalanceLow: t("components.inbox.balance"),
                    CampaignCategoriesBelowMinBid: t(
                      "components.inbox.categories"
                    ),
                    CampaignKeywordsBelowMinBid: t("components.inbox.keywords"),
                    CampaignProductsStopped: t("components.inbox.products"),
                  },
                }}
                renderBell={(unreadCount) => (
                  <div
                    className={`flex ${cm.bell} ${cm[bg_mode]}`}
                    onClick={toggleInbox}
                  >
                    {unreadCount > 0 ? <BellActive /> : <Bell />}
                  </div>
                )}
                renderNotification={(notification) => (
                  <RetailNotificationContainer
                    notification={notification}
                    campaignID={notification.data?.value}
                  />
                )}
              />
            )}
          </>
        ) : (
          <Col
            className={cc([cm.logout, cm.sync, "flex"])}
            onClick={handleNavigate}
          >
            <SyncOutlined />
          </Col>
        )}

        {/*
         * Account Info, change account and language
         */}
        <RetailNavbarPopover />
      </Header>
    </>
  );
};

export default RetailPageNavbar;
