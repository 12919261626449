import { ButtonProps } from "antd/lib/button";
import ConfigProvider from "antd/lib/config-provider";
import Popover from "antd/lib/popover";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Table, { TableProps } from "antd/lib/table";
import cc from "classcat";
import {
	useLayoutEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";

import useApi from "../../../api";
import { ReactComponent as MoreOutlined } from "../../../assets/icons/moreOutlined.svg";
import useMetricFetch from "../../../hooks/useMetricFetch";
import useTableFetch from "../../../hooks/useTableFetch";
import { localeLanguages } from "../../../utils/helpers";
import RetailBulkUpdateBar from "../../Bar/RetailBulkUpdateBar";
import RetailExportBottomBar from "../../Bar/RetailExportBottomBar";
import RetailFilterBar from "../../Bar/RetailFilterBar";
import RetailInviteBar from "../../Bar/RetailInviteBar";
import RetailSearchBar from "../../Bar/RetailSearchBar";
import RetailMainButton from "../../Button/RetailMainButton";
import RetailPaginationButtons from "../../Button/RetailPaginationButtons";
import Empty from "../../Empty";
import RetailActionPopover from "../../Popover/RetailActionPopover";
import RetailColumnPopover from "../../Popover/RetailColumnPopover";
import RetailFilterPopover from "../../Popover/RetailFilterPopover";
import cm from "./style.module.scss";

export interface RetailTableButtonProps extends Pick<ButtonProps, "onClick"> {
  title: string;
  dataTest?: string;
}
export interface RetailTableProps extends TableProps<any> {
  /**
   * Add new button of table. It accepts a title and an onClick function.
   */
  button?: RetailTableButtonProps;
  /**
   * Columns that aren't a part of API call or specially rendered.
   */
  columnsForAdditionalRendering?: any;
  /**
   * Some of the tables doesn't have an add new button. This props determines if there is a button. Default is true.
   */
  addBtnVisible?: boolean;
  /**
   * Placeholder for search input.
   */
  placeholder: string;
  /**
   * Change search value
   */
  tableConfig: any;
}

const RetailTable = ({
  columnsForAdditionalRendering,
  button,
  placeholder,
  tableConfig,
  addBtnVisible = true,
  pagination,
  className,
  ...tableProps
}: RetailTableProps) => {
  const { t, i18n } = useTranslation();

  const {
    url,
    isRelation,
    relationType,
    to,
    state,
    defaultFilter,
    filters,
    activeKey,
    onArchive,
    onThirdOption,
    fetchAll,
    renderColumns,
  } = tableConfig;

  const { columns, selectedColumns, extraColumns, setCheckedValues } =
    useMetricFetch(url, isRelation, relationType, activeKey, renderColumns);

  const { adminInGeneral } = useApi();

  const isFilterVisible = url === "transactions" && adminInGeneral && filters;

  const oldLocalStorageKey = `${adminInGeneral ? "admin" : "user"}_${url}_${
    isRelation ? "relation_" : "page"
  }${relationType || ""}`;

  const localStorageKey = `display_${
    adminInGeneral ? "admin" : "user"
  }_${url}_${isRelation ? "relation_" : "page"}${relationType || ""}`;

  useLayoutEffect(() => {
    if (localStorage.getItem(oldLocalStorageKey) !== null) {
      localStorage.removeItem(oldLocalStorageKey);
    }
  }, []);

  const sortKey = () => {
    if (isRelation) {
      switch (relationType) {
        case "PRODUCTS":
          return "product_created_at";
        case "KEYWORDS":
          return "keyword_created_at";
        case "NEGATIVE_KEYWORDS":
          return "negative_keyword_created_at";
        case "CATEGORIES":
          return "category_created_at";
        case "NEGATIVE_CATEGORIES":
          return "negative_category_created_at";
        case "CITIES":
          return "cityName";
        case "GENDERS":
        case "AGE_RANGES":
        case "PLACEMENTS":
        case "PLATFORMS":
          return `${relationType.toLowerCase()}Text`;
        case "CUSTOMERS":
          return "customerTargetingText";
        case "CREATIVES":
          return "creative_created_at";
      }
    }
    switch (url) {
      case "agencies":
      case "data_sources":
        return "createdAt";
      case "coupons":
        return "created_date";
      case "sync_logs":
      case "creatives":
      case "ad_placements":
      case "invites":
        return "created_at";
      case "account-users":
        return "account_user_created_at";
      case "reports":
        return "report_created_at";
      case "keyword-blacklist":
        return "text";
      case "products":
        return "product";
      case "transactions":
        return "transaction_date";
      default:
        return `${url.slice(0, -1)}CreatedAt`;
    }
  };

  const {
    data,
    isLoading,
    changePageSize,
    search,
    setSearch,
    setSort,
    setIsExported,
    setFormat,
    config,
  } = useTableFetch(
    url,
    isRelation,
    { [sortKey()]: "desc" },
    relationType && relationType,
    defaultFilter && defaultFilter,
    selectedColumns(t),
    fetchAll
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [selectedInfo, setSelectedInfo] = useState<any>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[], info: any) => {
    if (url === "advertisers" || url === "invites") {
      setSelectedRowKeys(newSelectedRowKeys);
      setSelectedInfo(info);
    } else {
      setSelectedRowKeys(newSelectedRowKeys);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const action =
    to !== undefined || onArchive !== undefined
      ? {
          title: "",
          dataIndex: "action",
          width: 5,
          render: (value: any, records: any) => (
            <Popover
              content={
                <RetailActionPopover
                  to={
                    typeof to === "string"
                      ? `/${to}/${records.id ? records.id : records.report}`
                      : to
                  }
                  state={state}
                  onArchive={onArchive}
                  onThirdOption={onThirdOption}
                  records={records}
                  activeKey={activeKey}
                />
              }
              placement="leftTop"
              trigger="hover"
              arrowPointAtCenter
              zIndex={1}
            >
              <MoreOutlined className={cm.action} />
            </Popover>
          ),
        }
      : {};

  const disabledColumnsFromSelection = [
    "name",
    "status",
    "campaign_name",
    "custom_key",
    "image_url",
    "transaction_date",
    "transaction_type",
    "spend_details",
    "account_user_status",
    "user_email",
    "email",
    "created_at",
    "report_name",
    "text",
    "restriction_type",
  ];

  // Function to handle the common logic of mapping options
  const mapOptions = (options: any[], defaultValue: boolean) =>
    options.map((opt: any) => ({
      label: opt.title,
      value: opt.dataIndex,
      disabled: disabledColumnsFromSelection.includes(opt.dataIndex),
      defaultChecked:
        localStorage.getItem(localStorageKey) !== null
          ? JSON.parse(localStorage.getItem(localStorageKey)!).some(
              (item: any) => item === opt.dataIndex
            )
          : defaultValue,
    }));

  const extraColumnOptions = mapOptions(extraColumns(t), false);
  const columnOptions = mapOptions(columns(t), true)
    .filter((opt) => opt.label !== "")
    .concat(extraColumnOptions);

  const handleChange = (checkedValues: any) => {
    const filteredColumns = (t: any) =>
      columns(t).filter((column: any) =>
        checkedValues.includes(column.dataIndex)
      );
    const filteredExtraColumns = (t: any) =>
      extraColumns(t).filter((column: any) =>
        checkedValues.includes(column.dataIndex)
      );
    if (extraColumns(t).length === 0) {
      const dataIndex = [...filteredColumns(t), action].map(
        (item: any) => item.dataIndex
      );
      localStorage.setItem(localStorageKey, JSON.stringify(dataIndex));
    } else {
      const dataIndex = [
        ...filteredColumns(t),
        ...filteredExtraColumns!(t),
        action,
      ].map((item: any) => item.dataIndex);
      localStorage.setItem(localStorageKey, JSON.stringify(dataIndex));
    }
    setCheckedValues(checkedValues);
  };

  const handleRowKeys = () => {
    setSelectedRowKeys([]);
    setSelectedInfo([]);
  };

  const tableExport = (value: "excel" | "pdf" | "csv") => {
    setFormat(value);
    setIsExported(true);
  };

  const switchTableType = () => {
    const linkForTableType = url.replace("-", "_");
    if (search !== "") return "searched_table";

    if (search === "") {
      switch (activeKey) {
        case "ARCHIVED":
        case "INVITED":
        case "ACCEPTED":
        case "REJECTED":
        case "PENDING":
          return `${activeKey.toLowerCase()}_table_${linkForTableType}`;
        default:
          if (relationType === "CREATIVES") {
            return "creatives_relations_table";
          }
          if (relationType !== undefined) {
            return `${relationType.toLowerCase()}_table`;
          }
      }
    }

    if (url === "keyword-blacklist") return "blacklist_table";

    return `${linkForTableType}_table`; // Default return value
  };

  const customizeRenderEmpty = () => <Empty type={switchTableType()} />;

  const switchIDForRowKey = (record: any) => {
    if (isRelation) {
      switch (relationType) {
        case "PRODUCTS":
          return record.product_id;
        case "KEYWORDS":
        case "NEGATIVE_KEYWORDS":
          return record.keyword_id;
        case "CATEGORIES":
        case "NEGATIVE_CATEGORIES":
          return record.category_id;
        case "CREATIVES":
          return record.creative_id;
        case "PLACEMENTS":
          return record.placement_id;
        case "CITIES":
          return record.city_id;
        case "AGE_RANGES":
          return record.age_range_id;
        case "GENDERS":
          return record.gender_id;
        case "PLATFORMS":
          return record.platform_id;
        case "CUSTOMERS":
          return record.customer_targeting_id;
        default:
          break;
      }
    } else return record.id || record.account_user_id;
  };

  const switchDataSource = () => {
    if (url === "users") {
      const uniqueAccounts = Array.from(
        new Set(
          data?.data?.records.flatMap((record: any) =>
            record.accounts.map((account: any) => account.id)
          )
        )
      ).map((accountId) => {
        const record = data?.data?.records.find((record: any) =>
          record.accounts.some((account: any) => account.id === accountId)
        );

        const account = record.accounts.find(
          (account: any) => account.id === accountId
        );

        return {
          ...account,
          email: record.email,
          created_at: record.created_at,
          last_login: record.last_login,
          user_id: record.id,
        };
      });
      return uniqueAccounts;
    } else return data?.data?.records;
  };

  return (
    <Row className={cm.container}>
      {selectedRowKeys.length === 0 && url !== "products" && (
        <Space
          className={cc([
            cm.space,
            addBtnVisible ? cm.spaceBig : cm.spaceSmall,
            url === "transactions" || url === "coupons" ? cm.notSearch : "",
          ])}
        >
          {addBtnVisible && (
            <RetailMainButton
              hasBackground
              className={cc([cm.btn, cm.add, "flex"])}
              onClick={button?.onClick}
              data-test={button?.dataTest}
              id={`${url}-add-button`}
            >
              {button?.title}
            </RetailMainButton>
          )}
          {url !== "transactions" && url !== "coupons" && (
            <RetailSearchBar
              placeholder={placeholder}
              onChange={({ target }) => setSearch(target.value)}
              defaultValue={search}
            />
          )}

          <RetailColumnPopover
            options={columnOptions}
            onChange={handleChange}
          />
          {isFilterVisible && (
            <RetailFilterPopover mode="table" filters={filters} />
          )}
        </Space>
      )}
      <RetailFilterBar />
      {url === "advertisers" || url === "invites" ? (
        <RetailInviteBar
          selectedRowKeys={selectedInfo}
          handleRowkeys={handleRowKeys}
          activeKey={activeKey}
        />
      ) : (
        url !== "products" && (
          <RetailBulkUpdateBar
            tableConfig={tableConfig}
            selectedRowKeys={selectedRowKeys}
            handleRowkeys={handleRowKeys}
            archived={activeKey === "ARCHIVED"}
          />
        )
      )}

      <ConfigProvider
        renderEmpty={customizeRenderEmpty}
        locale={localeLanguages(i18n.language)}
      >
        <Table
          columns={selectedColumns(t).concat(
            columnsForAdditionalRendering
              ? columnsForAdditionalRendering(t)
              : [],
            action
          )}
          showSorterTooltip={false}
          className={cc([cm.table, className || ""])}
          loading={isLoading}
          dataSource={switchDataSource()}
          rowKey={(record) => switchIDForRowKey(record)}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          pagination={{
            current: config?.page,
            pageSize: config?.page_size,
            total: data?.data.total_records || data?.data.totalRecords,
            itemRender(page, type, element) {
              return <RetailPaginationButtons type={type} element={element} />;
            },
            showSizeChanger: true,
            showTotal: (total, range) => t("common.pageTotal"),
            ...pagination,
          }}
          sortDirections={["descend", "ascend", null]}
          onChange={(pagination, filters, sorter: any) => {
            sorter.order !== undefined && sorter.order !== null
              ? setSort({
                  sortValue: sorter.field,
                  order: sorter.order === "ascend" ? "asc" : "desc",
                })
              : setSort(null);
            changePageSize(pagination.current!, pagination.pageSize!);
          }}
          scroll={{ x: true }}
          {...tableProps}
        />
      </ConfigProvider>

      {selectedRowKeys.length === 0 && url !== "products" && (
        <RetailExportBottomBar tableExport={tableExport} />
      )}
    </Row>
  );
};

export default RetailTable;
