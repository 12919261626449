import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Radio, RadioChangeEvent } from "antd";
import { DataSchemaItem, EntityStates } from "../../../../utils/types";
import { ReactComponent as PlayButtonOutlined } from "../../../../assets/icons/playButtonOutlined.svg";
import useApi from "../../../../api";
import CreativePreviewModal from "../../../Modal/CreativePreviewModal";
import RetailDataSchemaItem from "./RetailDataSchemaItem";
import RetailDrawer from "../../../Drawer/RetailDrawer";
import RetailMainButton from "../../../Button/RetailMainButton";
import RetailText from "../../../Typography/RetailText";
import RetailTitle from "../../../Typography/RetailTitle";

import cm from "./style.module.scss";

interface RetailEntityDrawersProps {
  entityStates: EntityStates;
  videoVisible: boolean;
  setEntityStates: React.Dispatch<React.SetStateAction<EntityStates>>;
  onOk: () => void;
  onClose: () => void;
  openVideo: () => void;
  closeVideo: () => void;
}

const RetailEntityDrawers = ({
  entityStates,
  setEntityStates,
  onOk,
  onClose,
  videoVisible,
  openVideo,
  closeVideo,
}: RetailEntityDrawersProps) => {
  const { t } = useTranslation();

  const { api } = useApi();

  const { data } = useQuery(
    "entities",
    () => {
      const data = api.get("data_sources/default_system_fields");
      return data;
    },
    {
      retry: false,
      enabled: entityStates.secondaryVisible,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  const allEntities = ["product", "advertiser", "impression", "click", "sale"];

  const openSecondaryDrawer = (type: EntityStates["selected"]) => {
    setEntityStates((prev) => ({
      ...prev,
      secondaryVisible: true,
      selected: type,
    }));
  };

  const closeSecondaryDrawer = () =>
    setEntityStates((prev) => ({ ...prev, secondaryVisible: false }));

  const handleFieldChange = (e: RadioChangeEvent) => {
    setEntityStates((prev) => ({ ...prev, selected: e.target.value }));
  };

  return (
    <>
      {/* Main Drawer */}
      <RetailDrawer
        title={t("components.datasource.selectEntity")}
        visible={entityStates.visible}
        onOk={onOk}
        onClose={onClose}
        disableOk={entityStates.selected === null}
        width={615}
      >
        <p className={cm.info}>
          {t("components.datasource.selectEntityDescription")}
        </p>

        <Radio.Group onChange={handleFieldChange} value={entityStates.selected}>
          {allEntities.map((entity) => (
            <Radio
              value={entity}
              className={`form-radio ${cm.radioLabel}`}
              key={entity}
            >
              <section className={cm.radio}>
                <div
                  className={`${cm.video} ${
                    entity !== "product" ? cm.disabledVideo : ""
                  }`}
                  onClick={openVideo}
                >
                  <div className={cm.videoBtn}>
                    <PlayButtonOutlined />
                    <RetailText size="xxxs" weight="medium">
                      {t("components.datasource.how")}
                    </RetailText>
                  </div>
                </div>
                <div className={cm.content}>
                  <RetailTitle level={5} noMargin>
                    {t(`components.datasource.${entity}`)}
                  </RetailTitle>
                  <RetailText
                    size="xxxs"
                    weight="medium"
                    className={cm.contentText}
                  >
                    {t(`components.datasource.${entity}Text`)}
                  </RetailText>
                  <RetailMainButton
                    onClick={() =>
                      openSecondaryDrawer(entity as EntityStates["selected"])
                    }
                    className={cm.contentBtn}
                  >
                    {t("components.datasource.seeFields", {
                      value: t(`components.datasource.${entity}`),
                    })}
                  </RetailMainButton>
                </div>
              </section>
            </Radio>
          ))}
        </Radio.Group>
      </RetailDrawer>

      {/* Secondary Field Drawer */}
      <RetailDrawer
        title={t("components.datasource.schema", {
          value: t(`components.datasource.${entityStates.selected}`),
        })}
        visible={entityStates.secondaryVisible}
        onOk={closeSecondaryDrawer}
        onClose={closeSecondaryDrawer}
        footer={null}
        width={520}
      >
        {data?.data[entityStates.selected || "product"]?.map(
          (item: DataSchemaItem) => (
            <RetailDataSchemaItem key={item.field} item={item} />
          )
        )}
      </RetailDrawer>
      <CreativePreviewModal
        type="video"
        url="https://gowit-feature-guide-videos.s3.eu-central-1.amazonaws.com/product_data.mp4"
        visible={videoVisible}
        onCancel={closeVideo}
      />
    </>
  );
};

export default RetailEntityDrawers;
