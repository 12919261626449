import { Modal } from "antd";
import { ReactComponent as CloseOutlined } from "assets/icons/closeOutlined.svg";
import { ReactComponent as EllipseOutlined } from "assets/icons/ellipseOutlined.svg";
import cc from "classcat";
import RetailMainButton from "components/Button/RetailMainButton";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import useTableFetch from "hooks/useTableFetch";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AdType } from "utils/types";

import cm from "./style.module.scss";

export interface CreativeUploadInfoProps {
  adType: AdType;
  setOpenUpload?: (value: boolean) => void;
}

const CreativeUploadInfo = ({
  adType,
  setOpenUpload,
}: CreativeUploadInfoProps) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const { data } = useTableFetch("ad_placements", false, {}, undefined, [
    { key: "status", op: "eq", value: "ACTIVE" },
    {
      key: "ad_format",
      op: "eq",
      value: "DISPLAY",
    },
  ]);

  const modalActions = (value: boolean) => {
    setVisible(value);
    setOpenUpload && setOpenUpload(!value);
  };

  const open = (e: any) => {
    e.stopPropagation();
    setOpenUpload && setOpenUpload(false);
    setVisible(true);
  };

  const close = () => modalActions(false);

  const videoInfo = [
    {
      text: t("components.campaignForm.firstStep.maxVideoSize"),
      className: cm.listItem,
    },
    {
      text: t("components.campaignForm.firstStep.videoFormat"),
      className: cm.listItem,
      onClick: undefined,
    },
  ];

  const displayInfo = [
    {
      text: t("components.campaignForm.firstStep.listWarningSize"),
      className: cm.listItem,
    },
    {
      text: t("components.campaignForm.firstStep.listWarningType"),
      className: cm.listItem,
    },
    {
      text: t("components.campaignForm.firstStep.listWarningRecommended"),
      className: cc([cm.listItem, cm.warningRecommended]),
      onClick: open,
    },
  ];

  const info = adType === "VIDEO" ? videoInfo : displayInfo;

  return (
    <>
      <ul className={cm.infoList}>
        {info.map((info, index) => (
          <li onClick={info.onClick} key={index}>
            <EllipseOutlined />
            <RetailText size="xxxs" weight="medium" className={info.className}>
              {info.text}
            </RetailText>
          </li>
        ))}
      </ul>
      {adType === "DISPLAY" && (
        <Modal
          closable
          closeIcon={<CloseOutlined />}
          centered
          visible={visible}
          onCancel={close}
          className={cc(["campaign-modal", cm.modal])}
          destroyOnClose
          title={
            <>
              <RetailTitle level={4} className={cm.modalTitle}>
                {t("components.campaignForm.firstStep.modalTitle")}
              </RetailTitle>
              <RetailText size="xs" family="poppins" className={cm.modalText}>
                {t("components.campaignForm.firstStep.modalText")}
              </RetailText>
            </>
          }
          footer={[
            <RetailMainButton
              hasBackground
              className={cm.modalBtn}
              onClick={close}
            >
              {t("common.okay")}
            </RetailMainButton>,
          ]}
        >
          <table className={cm.table}>
            <thead>
              <th>
                <RetailText
                  weight="medium"
                  family="poppins"
                  className={cm.title}
                >
                  {t("components.campaignForm.firstStep.recommended")}
                </RetailText>
              </th>
              <th>
                <RetailText
                  weight="medium"
                  family="poppins"
                  className={cm.title}
                >
                  {t("components.campaignForm.firstStep.accepted")}
                </RetailText>
              </th>
            </thead>

            <tbody className={cm.tableBody}>
              <td className={cm.recommendedSizes}>
                {data?.data.records.map((x: any) => (
                  <tr>
                    <RetailText className={cm.data} size="xs" family="poppins">
                      {x.recommended_size}
                    </RetailText>
                  </tr>
                ))}
              </td>
              <td className={cm.sizes}>
                {data?.data.records.map((x: any) => (
                  <tr className={cm.sizes}>
                    <RetailText className={cm.data} size="xs" family="poppins">
                      {x?.sizes?.map((size: string) => (
                        <span>{size}</span>
                      ))}
                    </RetailText>
                  </tr>
                ))}
              </td>
            </tbody>
          </table>
        </Modal>
      )}
    </>
  );
};

export default CreativeUploadInfo;
