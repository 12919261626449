import Form from "antd/lib/form";
import AntdInput from "antd/lib/input/Input";
import cc from "classcat";
import RetailFormInput from "components/Form/RetailFormInput";
import { TFunction } from "react-i18next";

import cm from "./style.module.scss";

export interface CreativeNameFormProps {
  onFinish(): void;
  form: any;
  value: string;
  onChange(e: any): void;
  t: TFunction;
}

const CreativeNameForm = ({
  onFinish,
  form,
  value,
  onChange,
  t,
}: CreativeNameFormProps) => {
  return (
    <Form
      onFinish={onFinish}
      form={form}
      autoComplete="off"
      requiredMark={false}
      initialValues={{ creative_name: value }}
      className={cm.creativeDrawerForm}
    >
      <RetailFormInput
        isFocused={value !== ""}
        label={t("components.campaignForm.firstStep.creativeVideoLabel")}
        className="floating"
        name="creative_name"
        rules={[
          {
            required: true,
            message: t("components.campaignForm.firstStep.creativeError"),
          },
        ]}
      >
        <AntdInput
          className={cc(["floating", cm.creativeDrawerInput])}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          onChange={onChange}
          value={value}
        />
      </RetailFormInput>
    </Form>
  );
};

export default CreativeNameForm;
