export const productCurl = (
  data: any,
  uuid: string
) => `// Do NOT change the hostname below
curl --location 'https://${window.location.hostname}/api/integration/products' \

// Do NOT change the API key below
--header 'Authorization: ${data?.data?.api_key}' \

// Do NOT change the data source UUID
--header 'Data-Source-UUID: ${uuid}' \

--header 'Content-Type: application/json' \

--data '{
    "marketplace_id": ${data?.data?.id}, // Do NOT change the marketplace ID
    "products": [
        {
            "id": "12345",
            "name": "Sample Product Alpha",
            "advertiser_id": "56789", // The unique ID of the advertiser associated with this product. Use the ID created when setting up advertisers on the platform. If the advertiser does not exist, you can use an ID, but products cannot be created until the advertiser exists.
            "image_url": "https://example.com/images/sample-alpha.jpg",
            "description": "This is a placeholder product for API testing purposes.",
            "price": 50.00,
            "stock": 100,
            "rating": 4.5,
            "buybox": false,
            "brand": "TestBrand",
            "retailer_taxonomy": "SampleCategory>Subcategory",
            "status": "DELETED"
        },
        {
            "id": "67890",
            "name": "Sample Product Beta",
            "advertiser_id": "98765", // The unique ID of the advertiser associated with this product. Use the ID created when setting up advertisers on the platform. If the advertiser does not exist, you can use an ID, but products cannot be created until the advertiser exists.
            "image_url": "https://example.com/images/sample-beta.jpg",
            "description": "Another placeholder product to validate API functionality.",
            "price": 75.00,
            "stock": 50,
            "rating": 4.0,
            "buybox": true,
            "brand": "TestBrand",
            "retailer_taxonomy": "SampleCategory>AnotherSubcategory",
            "status": "ACTIVE"
        }
    ]
}'
`;

export const advertiserCurl = (
  data: any,
  uuid: string
) => `// Do NOT change the hostname below
curl --location 'https://${window.location.hostname}/api/integration/advertisers' \

// Do NOT change the API key below
--header 'Authorization: ${data?.data?.api_key}' \

// Do NOT change the data source UUID
--header 'Data-Source-UUID: ${uuid}' \

--header 'Content-Type: application/json' \

--data '{
    "marketplace_id": ${data?.data?.id}, // Do NOT change the marketplace ID
    "advertisers": [
        {
            "status": "ACTIVE", 
            "name": "Sample Advertiser", 
            "email": "sample_advertiser@gowit.com", 
            "id": "56789" 
        },
        {
            "status": "ACTIVE", 
            "name": "Another Advertiser", 
            "email": "another_advertiser@gowit.com", 
            "id": "98765" 
        }
    ]
}'`;

export const eventCurl = (
  data: any,
  uuid: string,
  event: string
) => `// Do NOT change the hostname below
curl --location 'https://${window.location.hostname}/server/events' \

// Do NOT change the API key below
--header 'Authorization: ${data?.data?.api_key}' \

// Do NOT change the data source UUID
--header 'Data-Source-UUID: ${uuid}' \

--header 'Content-Type: application/json' \

--data '{
    "marketplace_id": ${data?.data?.id}, // Do NOT change the marketplace ID
    "event_type": ${event}, // Do NOT change the event type
    "ad_id": "4b702dcc-2ea5-495b-adc3-66cf0b44f039", // The unique ID of the advertisement related to this event.
    "session_id": "ec07083a-8bf8"
}'`;

export const saleCurl = (
  data: any,
  uuid: string
) => `// Do NOT change the hostname below
curl --location 'https://${window.location.hostname}/server/events' \

// Do NOT change the API key below
--header 'Authorization: ${data?.data?.api_key}' \

// Do NOT change the data source UUID
--header 'Data-Source-UUID: ${uuid}' \

--header 'Content-Type: application/json' \

--data '{
    "marketplace_id": ${data?.data?.id}, // Do NOT change the marketplace ID
    "event_type": "sale", // Do NOT change the event type
    "sales": [
        {
            "advertiser_id": "56789", // The unique ID of the advertiser associated with this product. Use the ID you used when setting up advertisers on the platform. If the advertiser does not exist, you can use an ID, but products cannot be created until the advertiser exists.
            "quantity": 2,
            "unit_price": 100,
            "product_id": "12345" // The unique ID of the purchased product. Use the ID you used when setting up products on the platform. If the product does not exist, you can use an ID, but sales cannot be created until the product exists.
        }
    ],
    "session_id": "ec07083a-8bf8"
}'`;
