import {
	Col,
	Form,
	Radio,
} from "antd";
import cc from "classcat";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import {
	Product,
	ProductContext,
} from "../../../../../context/ProductProvider";
import { AdType } from "../../../../../utils/types";
import SelectProductsTable from "../../../../Table/SelectProductsTable";
import RetailSuggestedTag from "../../../../Tag/RetailSuggestedTag";
import RetailText from "../../../../Typography/RetailText";
import RetailTitle from "../../../../Typography/RetailTitle";
import RetailFormInfo from "../../../RetailFormInfo";
import RetailFormRow from "../../../RetailFormRow";
import cm from "../../style.module.scss";

interface ConnectedWithProductsProps {
  targeting: string;
  connected_with_products: boolean;
  handleFieldChange(e: any): void;
  type: AdType;
  error: boolean;
}

const ConnectedWithProducts = ({
  targeting,
  connected_with_products,
  handleFieldChange,
  type,
  error,
}: ConnectedWithProductsProps) => {
  const { t } = useTranslation();

  const { selectedProducts } = useContext(ProductContext) as Product;

  const isFormItemVisible = type === "DISPLAY" || type === "VIDEO";

  const isProductSectionVisible =
    type === "DISPLAY" || type === "VIDEO" ? connected_with_products : true;

  return (
    <RetailFormRow
      className={cc([
        "form-row",
        error && selectedProducts?.length === 0 ? cm.productError : "",
      ])}
    >
      <RetailFormInfo
        column={{
          number: targeting === "AUTO_TARGETING" ? "7" : "8",
          title: t("components.campaignForm.firstStep.connectedProductsTitle"),
          text: t("components.campaignForm.firstStep.connectedProductsText"),
        }}
      />
      {isFormItemVisible && (
        <Form.Item name="connected_with_products">
          <Radio.Group
            name="connected_with_products"
            className={cm.placement}
            onChange={handleFieldChange}
          >
            <Radio
              value={true}
              className={cc(["form-radio", cm.placementCheckbox])}
            >
              <RetailTitle level={5} className={cc(["flex", cm.marginBottom])}>
                {t("components.campaignForm.firstStep.showProducts")}
                <RetailSuggestedTag className={cm.suggestedTag} />
              </RetailTitle>
              <RetailText className={cm.radioText} family="poppins">
                {t("components.campaignForm.firstStep.showProductsText")}
              </RetailText>
            </Radio>

            <Radio
              value={false}
              className={cc(["form-radio", cm.placementCheckbox])}
            >
              <RetailTitle level={5} className={cm.marginBottom}>
                {t("components.campaignForm.firstStep.closeProducts")}
              </RetailTitle>
              <RetailText className={cm.radioText} family="poppins">
                {t("components.campaignForm.firstStep.closeProductsText")}
              </RetailText>
            </Radio>
          </Radio.Group>
        </Form.Item>
      )}
      {isProductSectionVisible && (
        <Col span={24}>
          <SelectProductsTable
            ad_type="PRODUCT"
            fetchAll={false}
            error={error}
          />
        </Col>
      )}
    </RetailFormRow>
  );
};

export default ConnectedWithProducts;
