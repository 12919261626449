import Divider from "antd/lib/divider";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Switch from "antd/lib/switch";
import Tabs from "antd/lib/tabs";
import Tag from "antd/lib/tag";
import cc from "classcat";
import {
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
	useMutation,
	useQueryClient,
} from "react-query";

import { InfoCircleFilled } from "@ant-design/icons";

import useApi from "../../../api";
import { ReactComponent as CloseTagOutlined } from "../../../assets/icons/closeTagOutlined.svg";
import { ReactComponent as PlusCircleOutlined } from "../../../assets/icons/statsAddOutlined.svg";
import { ReactComponent as WarningTwoToned } from "../../../assets/icons/warningTwoToned.svg";
import RetailSearchBar from "../../../components/Bar/RetailSearchBar";
import RetailMainButton from "../../../components/Button/RetailMainButton";
import RetailFormInput from "../../../components/Form/RetailFormInput";
import RetailSettingsHeader from "../../../components/Layout/RetailSettingsHeader";
import RetailSettingsLayout from "../../../components/Layout/RetailSettingsLayout";
import CampaignDetailsModal from "../../../components/Modal/CampaignDetailsModal";
import RetailNotification from "../../../components/Notification";
import RetailSelect from "../../../components/Select/RetailSelect";
import RetailInfoTag from "../../../components/Tag/RetailInfoTag";
import RetailText from "../../../components/Typography/RetailText";
import RetailTitle from "../../../components/Typography/RetailTitle";
import { useInfiniteScroll } from "../../../hooks/useInfiniteScroll";
import useSettings from "../../../hooks/useSettings";
import {
	renderSettingsLinks,
	settingsSuccessNotification,
	validateNumber,
} from "../../../utils/helpers";
import { BidTypes } from "../../../utils/types";
import { CampaignSettingsBidContainer } from "./CampaignSettingsBidContainer";
import CampaignSettingsBidTab from "./CampaignSettingsBidTab";
import cm from "./style.module.scss";

const CampaignSettingsPage = () => {
  const { t } = useTranslation();

  const { data } = useSettings("MARKETPLACE");

  const { api } = useApi();

  const [targeting, setTargeting] = useState(false);

  const [advertiserVisible, setAdvertiserVisible] = useState(false);

  type ActiveKey = "campaign" | "bid" | "conversion";

  const [activeKey, setActiveKey] = useState<ActiveKey>("campaign");

  const [visible, setVisible] = useState(false);

  const {
    options: advertisers,
    handleScroll,
    handleKey,
  } = useInfiniteScroll("advertisers", advertiserVisible);

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const [product, setProduct] = useState(false);

  const [activeStock, setActiveStock] = useState(false);

  const [buyBox, setBuyBox] = useState(false);

  const [selectedAdvertisers, setSelectedAdvertisers] = useState<any>([]);

  const [formFields, setFormFields] = useState({
    rating: "",
    ratingBehaviour: "",
    buybox_behaviour: "",
    stock: "",
    stockBehaviour: "",
    conversion_range: "",
    demographic_targeting: "",
    available_bidding_types: [] as string[],
    min_sale_threshold: "",
    product_attribution_type: "",
    display_attribution_type: "",
    video_attribution_type: "",
    attribution_type: "",
    show_marketplace_metrics_by_attribute: false,
    show_advertiser_metrics_by_attribute: false,
  });

  const handleChange = (value: any, name: string) =>
    setFormFields((prev) => ({ ...prev, [name]: value }));

  const handleProduct = (value: boolean) => setProduct(value);
  const handleStock = (value: boolean) => setActiveStock(value);
  const handleBuyBox = (value: boolean) => setBuyBox(value);

  const handleThreshold = (e: any) =>
    setFormFields((prev) => ({ ...prev, min_sale_threshold: e.target.value }));

  const close = () => setVisible(false);

  const openAdvertiser = () => setAdvertiserVisible(true);

  const closeAdvertiser = () => {
    setSelectedAdvertisers([]);
    setAdvertiserVisible(false);
    advertisers?.map((item: any) => (item.selected = false));
  };

  const handleOkAdvertiser = () => setAdvertiserVisible(false);

  const options = (t: any) => [
    {
      label: t("pages.admin.campaignSettings.alert"),
      value: "WARN",
    },
    {
      label: t("pages.admin.campaignSettings.block"),
      value: "BLOCK",
    },
  ];

  const buyboxOptions = (t: any) => [
    ...options(t),
    {
      label: t("pages.admin.campaignSettings.serverBlock"),
      value: "SERVER_BLOCK",
    },
  ];

  const stockOptions = (t: any) => [
    ...options(t),
    {
      label: t("pages.admin.campaignSettings.serverBlock"),
      value: "SERVER_BLOCK",
    },
  ];

  const advertiserOptions = (t: any) => [
    {
      label: t("pages.admin.campaignSettings.all"),
      value: "ALL",
    },
    {
      label: t("pages.admin.campaignSettings.none"),
      value: "NONE",
    },
    {
      label: t("pages.admin.campaignSettings.selected"),
      value: "SELECTED",
    },
  ];

  useEffect(() => {
    setFormFields((prev) => ({
      ...prev,
      rating: data?.data.rating_settings?.min || "",
      stock: data?.data.stock_settings?.min || "",
      conversion_range: data?.data.conversion_range,
      ratingBehaviour: data?.data.rating_settings?.behaviour,
      buybox_behaviour: data?.data.buybox_behaviour,
      stockBehaviour: data?.data.stock_settings?.behaviour,
      demographic_targeting: data?.data.demographic_targeting_type,
      available_bidding_types: data?.data.available_bidding_types,
      min_sale_threshold: data?.data?.min_sale_threshold,
      product_attribution_type: data?.data?.product_attribution_type || "VIEW",
      display_attribution_type: data?.data?.display_attribution_type || "VIEW",
      video_attribution_type: data?.data?.video_attribution_type || "VIEW",
      attribution_type: data?.data?.attribution_type || "VIEW",
      show_marketplace_metrics_by_attribute:
        data?.data?.show_marketplace_metrics_by_attribute || false,
      show_advertiser_metrics_by_attribute:
        data?.data?.show_advertiser_metrics_by_attribute || false,
    }));
    setProduct(data?.data.rating_settings.behaviour !== "NONE");
    setActiveStock(data?.data.stock_settings.behaviour !== "NONE");
    setBuyBox(data?.data.buybox_behaviour !== "NONE");
    /* setSelectedAdvertisers(data?.data.allowed_advertisers); */
    setTargeting(data?.data.demographic_targeting_type === "SELECTED");
    form.setFieldsValue({
      buyBox:
        data?.data.buybox_behaviour !== "NONE"
          ? data?.data.buybox_behaviour
          : null,
      rating_behaviour:
        data?.data.rating_settings.behaviour !== "NONE"
          ? data?.data.rating_settings.behaviour
          : null,
      stock_behaviour:
        data?.data.stock_settings.behaviour !== "NONE"
          ? data?.data.stock_settings.behaviour
          : null,
      rating: data?.data.rating_settings.min
        ? data?.data.rating_settings.min
        : "",
      stock: data?.data.stock_settings.min ? data?.data.stock_settings.min : "",
      conversion_range: data?.data.conversion_range,
      demographic_targeting: data?.data.demographic_targeting_type || "NONE",
      min_sale_threshold: data?.data?.min_sale_threshold,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data]);

  const updateValue = async (settings: any) => {
    const config = { ...settings };
    const response = await api.patch(`/settings`, config);
    return response;
  };

  const { mutateAsync } = useMutation(updateValue);

  const changeValue = async (settings: any) => {
    try {
      await mutateAsync({ ...settings }).then(() => {
        queryClient.invalidateQueries("settings");
      });
      settingsSuccessNotification(t);
    } catch (error) {
      console.error(error);
    }
  };

  const isStockWarning = activeStock
    ? data?.data.stock_settings.min === parseInt(formFields.stock)
    : true;

  const isRatingWarning = product
    ? data?.data.rating_settings.min === parseInt(formFields.rating)
    : true;

  const openModal = !isStockWarning || !isRatingWarning;

  const onFinish = () => {
    if (formFields.available_bidding_types?.length === 0) {
      RetailNotification.showNotification(
        "error",
        "",
        t("pages.admin.campaignSettings.bidWarning")
      );
    } else {
      if (openModal) setVisible(true);
      else
        try {
          changeValue({
            rating_settings: {
              behaviour: product ? formFields.ratingBehaviour : "NONE",
              min: product ? parseInt(formFields.rating) : null,
            },
            stock_settings: {
              behaviour: activeStock ? formFields.stockBehaviour : "NONE",
              min: activeStock ? parseInt(formFields.stock) : null,
            },
            buybox_behaviour: buyBox ? formFields.buybox_behaviour : "NONE",
            demographic_targeting: {
              type: formFields.demographic_targeting,
              advertiser_ids: selectedAdvertisers.map((x: any) => x.id),
            },
            available_bidding_types: formFields.available_bidding_types,
            min_sale_threshold: parseInt(formFields.min_sale_threshold),
          });
          settingsSuccessNotification(t);
        } catch (error) {
          console.log(error);
        }
    }
  };

  const onFinishConversion = () => {
    const {
      conversion_range,
      product_attribution_type,
      display_attribution_type,
      video_attribution_type,
      attribution_type,
      show_marketplace_metrics_by_attribute,
      show_advertiser_metrics_by_attribute,
    } = formFields;
    changeValue({
      conversion_range: parseInt(conversion_range),
      product_attribution_type,
      display_attribution_type,
      video_attribution_type,
      attribution_type,
      show_marketplace_metrics_by_attribute,
      show_advertiser_metrics_by_attribute,
    });
  };

  const okay = () => {
    try {
      changeValue({
        rating_settings: {
          behaviour: product ? formFields.ratingBehaviour : "NONE",
          min: product ? parseInt(formFields.rating) : null,
        },
        stock_settings: {
          behaviour: activeStock ? formFields.stockBehaviour : "NONE",
          min: activeStock ? parseInt(formFields.stock) : null,
        },
      });
      close();
      settingsSuccessNotification(t);
    } catch (error) {
      console.log(error);
    }
  };

  const isError = (arr: any, equal: string) =>
    arr.find((item: string) => item === equal);

  const checkBuyBox = (err: any) => {
    if (isError(err, "buyBox")) {
      RetailNotification.showNotification(
        "error",
        "",
        t("pages.admin.campaignSettings.chooseBehaviour")
      );
    }
  };

  const checkStock = (err: any) => {
    // checking for both stock error and stock behaviour error
    if (isError(err, "stock") && isError(err, "stock_behaviour")) {
      RetailNotification.showNotification(
        "error",
        "",
        t("pages.admin.campaignSettings.allStockError")
      );
    }
    // only stock error
    else if (isError(err, "stock")) {
      RetailNotification.showNotification(
        "error",
        "",
        t("pages.admin.campaignSettings.stockError")
      );
    }
    // only stock behaviour error
    else if (isError(err, "stock_behaviour")) {
      RetailNotification.showNotification(
        "error",
        "",
        t("pages.admin.campaignSettings.chooseBehaviour")
      );
    }
  };
  const checkRating = (err: any) => {
    if (isError(err, "rating") && isError(err, "rating_behaviour")) {
      RetailNotification.showNotification(
        "error",
        "",
        t("pages.admin.campaignSettings.allRatingError")
      );
    } else if (isError(err, "rating")) {
      RetailNotification.showNotification(
        "error",
        "",
        t("pages.admin.campaignSettings.ratingError")
      );
    } else if (isError(err, "rating_behaviour")) {
      RetailNotification.showNotification(
        "error",
        "",
        t("pages.admin.campaignSettings.chooseBehaviour")
      );
    }
  };

  const onFinishFailed = ({ errorFields }: any) => {
    const err = errorFields.map((error: any) => error.name[0]);
    checkBuyBox(err);
    checkStock(err);
    checkRating(err);
  };

  const onFinishFailedConversion = () =>
    RetailNotification.showNotification(
      "error",
      "",
      t("pages.admin.campaignSettings.rangeError")
    );

  const deleteAdvertiser = (id: number) =>
    setSelectedAdvertisers(
      selectedAdvertisers.filter((advertiser: any) => id !== advertiser.id)
    );

  const bidTypes: BidTypes[] = ["AUTO", "ACOS", "FIXED"];

  const handleActiveKey = (key: ActiveKey | string) =>
    setActiveKey(key as ActiveKey);

  const changeAttribution = (type: string, value: string) =>
    setFormFields((prev) => ({ ...prev, [type]: value }));

  const updateRoleAttribution = (type: string, value: boolean) =>
    setFormFields((prev) => ({ ...prev, [type]: value }));

  return (
    <>
      <div className={cm.tabHeader}></div>
      <RetailSettingsLayout
        className={cc([cm.page, activeKey === "bid" ? cm.bidContainer : ""])}
      >
        {/* <RetailSettingsHeader type={activeKey} onClick={onFinish} /> */}
        <Tabs
          className={cc([cm.tabs, activeKey === "bid" ? cm.bidTab : ""])}
          centered
          activeKey={activeKey}
          onChange={handleActiveKey}
        >
          <Tabs.TabPane
            tab={t("pages.admin.campaignSettings.general")}
            key="campaign"
          >
            <RetailSettingsHeader type={activeKey} onClick={form.submit} />
            <Form
              className={cm.section}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              requiredMark={false}
              autoComplete="off"
            >
              <div className={cm.container}>
                <div className={cm.flex}>
                  <div>
                    <RetailTitle level={4} className={cm.secondaryTitle}>
                      {t("pages.admin.campaignSettings.buyBoxTitle")}
                    </RetailTitle>
                    <RetailText
                      size="xxxs"
                      family="poppins"
                      className={cm.text}
                    >
                      {t("pages.admin.campaignSettings.buyBoxText")}
                    </RetailText>
                  </div>
                  <div className="flex">
                    <Switch checked={buyBox} onChange={handleBuyBox} />
                    <RetailText size="xs" family="poppins">
                      {buyBox
                        ? t("common.table.open")
                        : t("common.table.closed")}
                    </RetailText>
                  </div>
                </div>
                {buyBox === true && (
                  <>
                    <Divider className={cm.divider} />
                    <div
                      className={cc([
                        "flex",
                        cm.justifyContent,
                        cm.couponContainer,
                      ])}
                    >
                      <div>
                        <RetailTitle level={5} className={cm.title}>
                          {t("pages.admin.campaignSettings.behaviour")}
                        </RetailTitle>
                        <RetailText
                          size="xxxs"
                          family="poppins"
                          className={cm.text}
                        >
                          {t("pages.admin.campaignSettings.buyBoxSubtext")}
                        </RetailText>
                      </div>
                      <Form.Item
                        name="buyBox"
                        rules={[
                          {
                            required: true,
                            message: t(
                              "pages.admin.campaignSettings.chooseBehaviour"
                            ),
                          },
                        ]}
                      >
                        <RetailSelect
                          className={cm.select}
                          dropdownClassName={cm.dropdown}
                          placeholder={t(
                            "pages.admin.campaignSettings.placeholder"
                          )}
                          options={buyboxOptions(t)}
                          optionFilterProp="label"
                          onChange={(value) =>
                            handleChange(value, "buybox_behaviour")
                          }
                        />
                      </Form.Item>
                    </div>
                  </>
                )}
              </div>

              <div className={cm.container}>
                <div className={cm.flex}>
                  <div>
                    <RetailTitle level={4} className={cm.secondaryTitle}>
                      {t("pages.admin.campaignSettings.product")}
                    </RetailTitle>
                    <RetailText
                      size="xxxs"
                      family="poppins"
                      className={cm.text}
                    >
                      {t("pages.admin.campaignSettings.productText")}
                    </RetailText>
                    {renderSettingsLinks(
                      t("marketplaceDependentSettings.product_link"),
                      {
                        className: cm.link,
                        text: t("pages.admin.campaignSettings.productLink"),
                      }
                    )}
                  </div>
                  <div className="flex">
                    <Switch checked={product} onChange={handleProduct} />
                    <RetailText size="xs" family="poppins">
                      {product
                        ? t("common.table.open")
                        : t("common.table.closed")}
                    </RetailText>
                  </div>
                </div>
                {product === true && (
                  <>
                    <Divider className={cm.divider} />
                    <div
                      className={cc([
                        "flex",
                        cm.justifyContent,
                        cm.couponContainer,
                      ])}
                    >
                      <div>
                        <RetailTitle level={5} className={cm.title}>
                          {t("pages.admin.campaignSettings.minimum")}
                        </RetailTitle>
                        <RetailText
                          size="xxxs"
                          family="poppins"
                          className={cm.text}
                        >
                          {t("pages.admin.campaignSettings.minimumText")}
                        </RetailText>
                      </div>
                      <RetailFormInput
                        isFocused={formFields.rating !== ""}
                        label={t("pages.admin.campaignSettings.minimum")}
                        className="floating"
                        name="rating"
                        rules={[
                          {
                            required: true,
                            message: t(
                              "pages.admin.campaignSettings.ratingError"
                            ),
                          },
                        ]}
                      >
                        <Input
                          type="tel"
                          className="number-input floating"
                          onChange={({ target }) =>
                            handleChange(target.value, "rating")
                          }
                          value={
                            formFields.rating !== "" ? formFields.rating : ""
                          }
                          onKeyDownCapture={(e) => validateNumber(e)}
                        />
                      </RetailFormInput>
                    </div>
                    <div
                      className={cc([
                        "flex",
                        cm.justifyContent,
                        cm.couponContainer,
                      ])}
                    >
                      <div>
                        <RetailTitle level={5} className={cm.title}>
                          {t("pages.admin.campaignSettings.behaviour")}
                        </RetailTitle>
                        <RetailText
                          size="xxxs"
                          family="poppins"
                          className={cm.text}
                        >
                          {t("pages.admin.campaignSettings.behaviourText")}
                        </RetailText>
                      </div>
                      <Form.Item
                        name="rating_behaviour"
                        rules={[
                          {
                            required: true,
                            message: t(
                              "pages.admin.campaignSettings.chooseBehaviour"
                            ),
                          },
                        ]}
                      >
                        <RetailSelect
                          className={cm.select}
                          dropdownClassName={cm.dropdown}
                          placeholder={t(
                            "pages.admin.campaignSettings.placeholder"
                          )}
                          options={options(t)}
                          optionFilterProp="label"
                          onChange={(value) =>
                            handleChange(value, "ratingBehaviour")
                          }
                        />
                      </Form.Item>
                    </div>
                  </>
                )}
              </div>

              <div className={cm.container}>
                <div className={cm.flex}>
                  <div>
                    <RetailTitle level={4} className={cm.secondaryTitle}>
                      {t("pages.admin.campaignSettings.stock")}
                    </RetailTitle>
                    <RetailText
                      size="xxxs"
                      family="poppins"
                      className={cm.text}
                    >
                      {t("pages.admin.campaignSettings.stockText")}
                    </RetailText>
                    {renderSettingsLinks(
                      t("marketplaceDependentSettings.stock_link"),
                      {
                        className: cm.link,
                        text: t("pages.admin.campaignSettings.stockLink"),
                      }
                    )}
                  </div>
                  <div className="flex">
                    <Switch checked={activeStock} onChange={handleStock} />
                    <RetailText size="xs" family="poppins">
                      {activeStock
                        ? t("common.table.open")
                        : t("common.table.closed")}
                    </RetailText>
                  </div>
                </div>
                {activeStock === true && (
                  <>
                    <Divider className={cm.divider} />
                    <div
                      className={cc([
                        "flex",
                        cm.justifyContent,
                        cm.couponContainer,
                      ])}
                    >
                      <div>
                        <RetailTitle level={5} className={cm.title}>
                          {t("pages.admin.campaignSettings.minimumStock")}
                        </RetailTitle>
                        <RetailText
                          size="xxxs"
                          family="poppins"
                          className={cm.text}
                        >
                          {t("pages.admin.campaignSettings.minimumStockText")}
                        </RetailText>
                      </div>
                      <RetailFormInput
                        isFocused={formFields.stock !== ""}
                        label={t("pages.admin.campaignSettings.stock")}
                        className="floating"
                        name="stock"
                        rules={[
                          {
                            required: true,
                            message: t(
                              "pages.admin.campaignSettings.stockError"
                            ),
                          },
                        ]}
                      >
                        <Input
                          type="tel"
                          className="number-input floating"
                          onChange={({ target }) =>
                            handleChange(target.value, "stock")
                          }
                          value={
                            formFields.stock !== "" ? formFields.stock : ""
                          }
                          onKeyDownCapture={(e) => validateNumber(e)}
                        />
                      </RetailFormInput>
                    </div>
                    <div
                      className={cc([
                        "flex",
                        cm.justifyContent,
                        cm.couponContainer,
                      ])}
                    >
                      <div>
                        <RetailTitle level={5} className={cm.title}>
                          {t("pages.admin.campaignSettings.behaviour")}
                        </RetailTitle>
                        <RetailText
                          size="xxxs"
                          family="poppins"
                          className={cm.text}
                        >
                          {t("pages.admin.campaignSettings.stockBehaviourText")}
                        </RetailText>
                      </div>
                      <Form.Item
                        name="stock_behaviour"
                        rules={[
                          {
                            required: true,
                            message: t(
                              "pages.admin.campaignSettings.chooseBehaviour"
                            ),
                          },
                        ]}
                      >
                        <RetailSelect
                          className={cm.select}
                          dropdownClassName={cm.dropdown}
                          placeholder={t(
                            "pages.admin.campaignSettings.placeholder"
                          )}
                          options={stockOptions(t)}
                          optionFilterProp="label"
                          onChange={(value) =>
                            handleChange(value, "stockBehaviour")
                          }
                        />
                      </Form.Item>
                    </div>
                  </>
                )}
              </div>

              <div className={cm.container}>
                <div
                  className={cc([
                    "flex",
                    cm.couponContainer,
                    cm.justifyContent,
                  ])}
                >
                  <div>
                    <RetailTitle level={4} className={cm.secondaryTitle}>
                      {t("pages.admin.campaignSettings.targeting")}
                    </RetailTitle>
                    <RetailText
                      size="xxxs"
                      family="poppins"
                      className={cm.text}
                    >
                      {t("pages.admin.campaignSettings.targetingText")}
                    </RetailText>
                    {renderSettingsLinks(
                      t("marketplaceDependentSettings.targeting_link"),
                      {
                        className: cm.link,
                        text: t("pages.admin.campaignSettings.targetingLink"),
                      }
                    )}
                  </div>
                  <Form.Item
                    name="demographic_targeting"
                    /* rules={[
                    {
                      required: true,
                      message: t(
                        "pages.admin.campaignSettings.chooseBehaviour"
                      ),
                    },
                  ]} */
                  >
                    <RetailSelect
                      className={cm.select}
                      dropdownClassName={cm.dropdown}
                      placeholder="Reklamveren Opsiyonları"
                      options={advertiserOptions(t)}
                      optionFilterProp="label"
                      defaultValue={formFields.demographic_targeting}
                      onChange={(value) => {
                        setTargeting(value === "SELECTED");
                        handleChange(value, "demographic_targeting");
                      }}
                    />
                  </Form.Item>
                </div>
                {targeting === true && (
                  <>
                    <Divider className={cm.divider} />
                    <div
                      className={cc([
                        "flex",
                        cm.justifyContent,
                        cm.couponContainer,
                      ])}
                    >
                      <div>
                        <RetailTitle level={5} className={cm.title}>
                          {t("pages.admin.campaignSettings.advertisers")}
                        </RetailTitle>
                        <RetailText
                          size="xxxs"
                          family="poppins"
                          className={cm.text}
                        >
                          {t("pages.admin.campaignSettings.advertisersText")}
                        </RetailText>
                      </div>
                      <div>
                        <RetailMainButton
                          hasBackground={false}
                          className={cc(["flex", cm.advertiserBtn])}
                          onClick={openAdvertiser}
                        >
                          <PlusCircleOutlined />
                          <RetailText size="xs" weight="medium">
                            {t("pages.admin.campaignSettings.advertisersBtn")}
                          </RetailText>
                        </RetailMainButton>
                        {selectedAdvertisers?.length > 0 && (
                          <RetailText
                            family="poppins"
                            size="xs"
                            weight="medium"
                            className={cm.lengthText}
                          >
                            {t(
                              "pages.admin.campaignSettings.advertisersLength",
                              {
                                value: selectedAdvertisers?.length,
                              }
                            )}
                          </RetailText>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <CampaignDetailsModal
                type="TARGETING_SETTINGS"
                visible={advertiserVisible}
                subtitle={t("pages.admin.campaignSettings.advertisersSub")}
                onCancel={closeAdvertiser}
                onOk={handleOkAdvertiser}
              >
                {selectedAdvertisers.length > 0 && (
                  <div>
                    <RetailTitle>
                      {t("pages.admin.campaignSettings.advertisersTitle")}
                    </RetailTitle>
                    <div className={cc(["flex", cm.tagContainer])}>
                      {selectedAdvertisers.map((item: any) => (
                        <Tag
                          closable
                          className={cc(["flex", cm.tag])}
                          closeIcon={<CloseTagOutlined />}
                          onClose={() => deleteAdvertiser(item.id)}
                        >
                          {item.name}
                        </Tag>
                      ))}
                    </div>
                  </div>
                )}
                <section className="bordered-container">
                  <RetailSearchBar
                    placeholder={t(
                      "pages.admin.campaignSettings.advertisersPlaceholder"
                    )}
                    onChange={handleKey}
                  />
                  <div className={cm.advertisersList} onScroll={handleScroll}>
                    {advertisers?.map((item: any) => (
                      <RetailText
                        family="poppins"
                        size="xs"
                        key={item.id}
                        className={cc([
                          cm.advertiserItem,
                          selectedAdvertisers.find((x: any) => item.id === x.id)
                            ? cm.itemSelected
                            : "",
                        ])}
                        onClick={() => {
                          setSelectedAdvertisers([
                            ...selectedAdvertisers,
                            item,
                          ]);
                        }}
                      >
                        {item.name}
                      </RetailText>
                    ))}
                  </div>
                </section>
              </CampaignDetailsModal>

              <div className={cm.container}>
                <div className={cm.flex}>
                  <div>
                    <RetailTitle level={4} className={cm.secondaryTitle}>
                      {t("pages.admin.campaignSettings.biddingTypes")}
                    </RetailTitle>
                    <RetailText
                      size="xxxs"
                      family="poppins"
                      className={cm.text}
                    >
                      {t("pages.admin.campaignSettings.biddingTypesText")}
                    </RetailText>
                    {renderSettingsLinks(
                      t("marketplaceDependentSettings.bidding_link"),
                      {
                        className: cm.link,
                        text: t("pages.admin.campaignSettings.bidLink"),
                      }
                    )}
                    <RetailInfoTag
                      type="SETTINGS"
                      closable={false}
                      className={cm.infoTag}
                    >
                      <InfoCircleFilled />
                      {t("pages.admin.campaignSettings.biddingTypesWarning")}
                    </RetailInfoTag>
                  </div>
                </div>

                <Divider className={cm.divider} />

                {bidTypes.map((type: BidTypes) => (
                  <CampaignSettingsBidContainer
                    type={type}
                    formFields={formFields}
                    setFormFields={setFormFields}
                  />
                ))}
              </div>
              <CampaignDetailsModal
                visible={visible}
                type="SETTINGS"
                onCancel={close}
                onOk={okay}
                className={cm.modal}
              >
                <RetailText
                  className={cc(["flex", cm.modalText])}
                  family="poppins"
                  weight="medium"
                  size="xs"
                >
                  <WarningTwoToned />
                  {t("pages.admin.campaignSettings.warn")}
                </RetailText>
              </CampaignDetailsModal>
              <div
                className={cc([cm.inputFlex, cm.justifyContent, cm.container])}
              >
                <div className={cm.noMarginFormItem}>
                  <RetailTitle level={5} className={cm.title}>
                    {t("pages.admin.campaignSettings.minThreshold")}
                  </RetailTitle>
                  <RetailText size="xxxs" family="poppins" className={cm.text}>
                    {t("pages.admin.campaignSettings.minThresholdText")}
                  </RetailText>
                </div>
                <RetailFormInput
                  isFocused={formFields.min_sale_threshold !== ""}
                  label={t("pages.admin.campaignSettings.minThresholdLabel")}
                  name="min_sale_threshold"
                  className="floating"
                  rules={[
                    {
                      pattern: /^(0|[1-9]\d*)$/,
                      message: t(
                        "pages.admin.campaignSettings.minThresholdError"
                      ),
                    },
                  ]}
                >
                  <Input
                    onChange={handleThreshold}
                    className={cc(["floating", cm.input])}
                    value={formFields.min_sale_threshold}
                  />
                </RetailFormInput>
              </div>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("pages.admin.campaignSettings.bid")} key="bid">
            <CampaignSettingsBidTab />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t("pages.admin.campaignSettings.conversion")}
            key="conversion"
          >
            <RetailSettingsHeader type={activeKey} onClick={form.submit} />
            <Form
              className={cm.section}
              form={form}
              onFinish={onFinishConversion}
              onFinishFailed={onFinishFailedConversion}
              requiredMark={false}
            >
              <section className={cm.container}>
                <div
                  className={cc([
                    "flex",
                    cm.couponContainer,
                    cm.justifyContent,
                  ])}
                >
                  <div>
                    <RetailTitle level={5} className={cm.title}>
                      {t("pages.admin.campaignSettings.conversionRange")}
                    </RetailTitle>
                    <RetailText
                      size="xxxs"
                      family="poppins"
                      className={cm.text}
                    >
                      {t("pages.admin.campaignSettings.conversionRangeText")}
                    </RetailText>
                  </div>
                  <div style={{ position: "relative" }}>
                    <RetailFormInput
                      isFocused={formFields.conversion_range !== ""}
                      label={t("pages.admin.campaignSettings.conversionRange")}
                      name="conversion_range"
                      rules={[
                        {
                          required: true,
                          pattern: /^(1[0-4]|[1-9])$/,
                        },
                      ]}
                      help={t("pages.admin.campaignSettings.rangeErrorInput")}
                      className="floating"
                    >
                      <Input
                        onChange={({ target }) =>
                          handleChange(target.value, "conversion_range")
                        }
                        className={`floating ${cm.input}`}
                        onKeyDownCapture={(e) => validateNumber(e)}
                        value={formFields.conversion_range}
                      />
                    </RetailFormInput>
                    {formFields.conversion_range !== "" && (
                      <RetailText
                        size="xs"
                        family="poppins"
                        className={cm.suffix}
                      >
                        {t("common.day")}
                      </RetailText>
                    )}
                  </div>
                </div>
              </section>
              <section className={cm.container}>
                <article className={cm.formatTitleContainer}>
                  <RetailTitle level={5} className={cm.secondaryTitle}>
                    {t("pages.admin.campaignSettings.formatTitle")}
                  </RetailTitle>
                  <RetailText
                    size="xxs"
                    family="poppins"
                    className={cc([cm.text, cm.formatText])}
                  >
                    {t("pages.admin.campaignSettings.formatText")}
                  </RetailText>
                </article>

                <div className={cm.flex}>
                  <article>
                    <RetailTitle level={5} className={cm.title}>
                      {t("pages.admin.campaignSettings.sponsoredProductTitle")}
                    </RetailTitle>
                    <RetailText
                      size="xxxs"
                      family="poppins"
                      className={cm.secondaryText}
                    >
                      {t("pages.admin.campaignSettings.sponsoredProductText")}
                    </RetailText>
                  </article>
                  <div className={cm.btnContainer}>
                    <button
                      type="button"
                      className={
                        formFields.product_attribution_type === "VIEW"
                          ? cm.activeButton
                          : ""
                      }
                      onClick={() =>
                        changeAttribution("product_attribution_type", "VIEW")
                      }
                    >
                      {t("pages.admin.campaignSettings.postView")}
                    </button>
                    <button
                      type="button"
                      className={
                        formFields.product_attribution_type === "CLICK"
                          ? cm.activeButton
                          : ""
                      }
                      onClick={() =>
                        changeAttribution("product_attribution_type", "CLICK")
                      }
                    >
                      {t("pages.admin.campaignSettings.postClick")}
                    </button>
                  </div>
                </div>

                <div className={cm.flex}>
                  <article>
                    <RetailTitle level={5} className={cm.title}>
                      {t("pages.admin.campaignSettings.sponsoredDisplayTitle")}
                    </RetailTitle>
                    <RetailText
                      size="xxxs"
                      family="poppins"
                      className={cm.secondaryText}
                    >
                      {t("pages.admin.campaignSettings.sponsoredDisplayText")}
                    </RetailText>
                  </article>
                  <div className={cm.btnContainer}>
                    <button
                      type="button"
                      className={
                        formFields.display_attribution_type === "VIEW"
                          ? cm.activeButton
                          : ""
                      }
                      onClick={() =>
                        changeAttribution("display_attribution_type", "VIEW")
                      }
                    >
                      {t("pages.admin.campaignSettings.postView")}
                    </button>
                    <button
                      type="button"
                      className={
                        formFields.display_attribution_type === "CLICK"
                          ? cm.activeButton
                          : ""
                      }
                      onClick={() =>
                        changeAttribution("display_attribution_type", "CLICK")
                      }
                    >
                      {t("pages.admin.campaignSettings.postClick")}
                    </button>
                  </div>
                </div>

                <div className={cm.flex}>
                  <article>
                    <RetailTitle level={5} className={cm.title}>
                      {t("pages.admin.campaignSettings.sponsoredVideoTitle")}
                    </RetailTitle>
                    <RetailText
                      size="xxxs"
                      family="poppins"
                      className={cm.secondaryText}
                    >
                      {t("pages.admin.campaignSettings.sponsoredVideoText")}
                    </RetailText>
                  </article>
                  <div className={cm.btnContainer}>
                    <button
                      type="button"
                      className={
                        formFields.video_attribution_type === "VIEW"
                          ? cm.activeButton
                          : ""
                      }
                      onClick={() =>
                        changeAttribution("video_attribution_type", "VIEW")
                      }
                    >
                      {t("pages.admin.campaignSettings.postView")}
                    </button>
                    <button
                      type="button"
                      className={
                        formFields.video_attribution_type === "CLICK"
                          ? cm.activeButton
                          : ""
                      }
                      onClick={() =>
                        changeAttribution("video_attribution_type", "CLICK")
                      }
                    >
                      {t("pages.admin.campaignSettings.postClick")}
                    </button>
                  </div>
                </div>
              </section>

              <div className={cm.container}>
                <article className={cm.formatTitleContainer}>
                  <RetailTitle level={5} className={cm.secondaryTitle}>
                    {t("pages.admin.campaignSettings.reportTitle")}
                  </RetailTitle>
                  <RetailText
                    size="xxs"
                    family="poppins"
                    className={cc([cm.text, cm.formatText])}
                  >
                    {t("pages.admin.campaignSettings.reportText")}
                  </RetailText>
                </article>
                <div className={cm.flex}>
                  <div>
                    <RetailTitle level={5} className={cm.title}>
                      {t("pages.admin.campaignSettings.marketplaceReport")}
                    </RetailTitle>
                    <RetailText
                      size="xxxs"
                      family="poppins"
                      className={cm.secondaryText}
                    >
                      {t("pages.admin.campaignSettings.marketplaceReportText")}
                    </RetailText>
                  </div>
                  <div className="flex">
                    <Switch
                      checked={formFields.show_marketplace_metrics_by_attribute}
                      onClick={(value) =>
                        updateRoleAttribution(
                          "show_marketplace_metrics_by_attribute",
                          value
                        )
                      }
                    />
                    <RetailText size="xs" family="poppins">
                      {t(
                        `common.table.${
                          formFields.show_marketplace_metrics_by_attribute
                            ? "open"
                            : "closed"
                        }`
                      )}
                    </RetailText>
                  </div>
                </div>

                <div className={cm.flex}>
                  <div>
                    <RetailTitle level={5} className={cm.title}>
                      {t("pages.admin.campaignSettings.advertiserReport")}
                    </RetailTitle>
                    <RetailText
                      size="xxxs"
                      family="poppins"
                      className={cm.secondaryText}
                    >
                      {t("pages.admin.campaignSettings.advertiserReportText")}
                    </RetailText>
                  </div>
                  <div className="flex">
                    <Switch
                      checked={formFields.show_advertiser_metrics_by_attribute}
                      onClick={(value) =>
                        updateRoleAttribution(
                          "show_advertiser_metrics_by_attribute",
                          value
                        )
                      }
                    />
                    <RetailText size="xs" family="poppins">
                      {t(
                        `common.table.${
                          formFields.show_advertiser_metrics_by_attribute
                            ? "open"
                            : "closed"
                        }`
                      )}
                    </RetailText>
                  </div>
                </div>
              </div>

              <section className={cc([cm.container, cm.flex])}>
                <article className={cm.widgetTitleContainer}>
                  <RetailTitle level={5} className={cm.secondaryTitle}>
                    {t("pages.admin.campaignSettings.widgetTitle")}
                  </RetailTitle>
                  <RetailText
                    size="xxs"
                    family="poppins"
                    className={cc([cm.text, cm.formatText])}
                  >
                    {t("pages.admin.campaignSettings.widgetText")}
                  </RetailText>
                </article>
                <div className={cm.btnContainer}>
                  <button
                    type="button"
                    className={
                      formFields.attribution_type === "VIEW"
                        ? cm.activeButton
                        : ""
                    }
                    onClick={() =>
                      changeAttribution("attribution_type", "VIEW")
                    }
                  >
                    {t("pages.admin.campaignSettings.postView")}
                  </button>
                  <button
                    type="button"
                    className={
                      formFields.attribution_type === "CLICK"
                        ? cm.activeButton
                        : ""
                    }
                    onClick={() =>
                      changeAttribution("attribution_type", "CLICK")
                    }
                  >
                    {t("pages.admin.campaignSettings.postClick")}
                  </button>
                </div>
              </section>
            </Form>
          </Tabs.TabPane>
        </Tabs>
      </RetailSettingsLayout>
    </>
  );
};

export default CampaignSettingsPage;
