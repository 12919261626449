import { ReactComponent as EditOutlined } from "assets/icons/editWhiteOutlined.svg";
import { ReactComponent as VideoFilled } from "assets/icons/videoFilled.svg";
import cc from "classcat";
import VastPlayer from "components/Player/VastPlayer";
import RetailTooltip from "components/Tooltip/RetailTooltip";
import RetailText from "components/Typography/RetailText";
import {
	Trans,
	useTranslation,
} from "react-i18next";
import { Video } from "utils/types";

import {
	DeleteOutlined,
	InfoCircleFilled,
} from "@ant-design/icons";

import cm from "./style.module.scss";

export interface VideoItemProps {
  singleVideo: Video;
  deleteVideo(uuid: string): void;
  openUpdateDrawer(uuid: string): void;
}

const VideoItem = ({
  singleVideo,
  deleteVideo,
  openUpdateDrawer,
}: VideoItemProps) => {
  const { t } = useTranslation();

  const { vast_tag, uuid, creative_name, submitted } = singleVideo;

  const editVideoItem = () => openUpdateDrawer(uuid);

  const deleteVideoItem = () => deleteVideo(uuid);

  return (
    <div className={`${cm.videoCard} ${submitted ? cm.submitted : ""}`}>
      <VastPlayer
        vastURL={vast_tag || ""}
        name={creative_name}
        type="form"
        id={uuid}
      />

      <div className={cc(["flex", cm.videoCardMeta])}>
        <VideoFilled className={cm.videoIcon} />
        <RetailText
          weight="bold"
          className={cc(["flex", cm.videoCardMetaText])}
        >
          {t("components.campaignForm.firstStep.videoAd")}
        </RetailText>
      </div>
      <div className={cm.videoNameContainer}>
        <Trans>
          <RetailText size="xxxs" weight="medium" className={cm.creativeName}>
            {t("components.campaignForm.firstStep.creativeName", {
              value: creative_name,
            })}
          </RetailText>
        </Trans>
        <RetailTooltip title={creative_name}>
          <InfoCircleFilled />
        </RetailTooltip>
      </div>
      <div className={cm.videoCardFooter}>
        <div className={cm.videoCardIcon} onClick={editVideoItem}>
          <EditOutlined className={cm.editIcon} />
        </div>
        <div className={cm.videoCardIcon} onClick={deleteVideoItem}>
          <DeleteOutlined className={cm.deleteIcon} />
        </div>
      </div>
    </div>
  );
};

export default VideoItem;
