import { CheckboxChangeEvent } from "antd/lib/checkbox";
import Divider from "antd/lib/divider";
import Form from "antd/lib/form";
import Steps from "antd/lib/steps";
import useApi from "api";
import { ReactComponent as CloseOutlined } from "assets/icons/closeOutlined.svg";
import { ReactComponent as LeftOutlined } from "assets/icons/leftOutlined.svg";
import { ReactComponent as RightWhiteOutlined } from "assets/icons/rightWhiteOutlined.svg";
import axios, {
	AxiosError,
	AxiosResponse,
} from "axios";
import cc from "classcat";
import RetailAdminBar from "components/Bar/RetailAdminBar";
import RetailInfoBar from "components/Bar/RetailInfoBar";
import RetailMainButton from "components/Button/RetailMainButton";
import CampaignDetailsModal from "components/Modal/CampaignDetailsModal";
import RetailCampaignModal, { RetailCampaignModalHandles } from "components/Modal/RetailCampaignModal";
import RetailWarningModal from "components/Modal/RetailWarningModal";
import RetailNotification from "components/Notification";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import {
	Auth,
	AuthContext,
} from "context/AuthProvider";
import {
	Category,
	CategoryContext,
} from "context/CategoryProvider";
import {
	Creative,
	CreativeContext,
} from "context/CreativeProvider";
import {
	Keyword,
	KeywordContext,
} from "context/KeywordProvider";
import {
	Product,
	ProductContext,
} from "context/ProductProvider";
import useSettings from "hooks/useSettings";
import useTableFetch from "hooks/useTableFetch";
import moment from "moment";
import {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import {
	generateDayPartingObject,
	numFormatter,
	selectCampaignType,
	SelectCurrency,
} from "utils/helpers";
import {
	AdType,
	Schedule,
} from "utils/types";

import { CheckOutlined } from "@ant-design/icons";

import { RetailCampaignFormFirstStep } from "./RetailCampaignFormFirstStep";
import RetailCampaignFormSecondStep from "./RetailCampaignFormSecondStep";
import { RetailCampaignFormThirdStep } from "./RetailCampaignFormThirdStep";
import cm from "./style.module.scss";

const RetailCampaignForm = () => {
  const { t } = useTranslation();

  const { api, adminInGeneral, id: marketplace_id } = useApi();

  const { authToken, showAdminBar } = useContext(AuthContext) as Auth;

  const [form] = Form.useForm();

  const [current, setCurrent] = useState(0);

  const [infoModalVisible, setInfoModalVisible] = useState(false);

  const [warningModalVisible, setWarningModalVisible] = useState(false);

  const [navigateData, setNavigateData] = useState({
    campaign_id: "",
    campaign_name: "",
    advertiser_id: "",
    ad_type: "",
  });

  const { data } = useSettings("ADVERTISER");

  const [words, setWords] = useState(true);

  const { data: cities } = useTableFetch("cities", false);

  const location = useLocation();

  const advertiser = location.state as { id: number; name: string };

  const ad_type = location.state as { ad_type: AdType };

  const { data: advertiserInfo } = useQuery(
    "selected_widgets",
    () => {
      const data = api.get("");
      return data;
    },
    {
      retry: false,
      refetchOnWindowFocus: true,
    }
  );

  const [checkedList, setCheckedList] = useState<any>([]);

  const [indeterminate, setIndeterminate] = useState(true);

  const [checkAll, setCheckAll] = useState(false);

  const onChange = (checkedValue: any) => {
    checkedList.map((item: string) => item).indexOf(checkedValue) === -1
      ? setCheckedList((prevState: any) => [...prevState, checkedValue])
      : setCheckedList(
          checkedList.filter((value: string) => value !== checkedValue)
        );
  };

  useEffect(() => {
    setCheckAll(checkedList?.length === cities?.data.records?.length);
    setIndeterminate(
      !!checkedList.length && checkedList.length < cities?.data.records?.length
    );
  }, [checkedList]);

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(
      e.target.checked ? cities?.data.records.map((item: any) => item.name) : []
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const modalRef = useRef<RetailCampaignModalHandles>(null);

  const { selectedProducts, handleDeleteAllProducts } = useContext(
    ProductContext
  ) as Product;

  const { img, setImg, video, setVideo } = useContext(
    CreativeContext
  ) as Creative;

  const { keywords, setKeywords, negativeKeywords, setNegativeKeywords } =
    useContext(KeywordContext) as Keyword;

  const {
    categories,
    negativeCategories,
    setCategories,
    setNegativeCategories,
  } = useContext(CategoryContext) as Category;

  let navigate = useNavigate();

  const [scheduleState, setScheduleState] = useState<Schedule>(
    Array(7)
      .fill(undefined)
      .map(() => Array(24).fill(false))
  );

  const [requiredFields, setRequiredFields] = useState({
    name: "",
    start_date: "",
    end_date: "",
    budget: "",
    budgetDaily: "",
    acos: "",
    bid_type: "STANDART",
    bid: "",
    targeting: "AUTO_TARGETING",
    is_auto_keyword: true,
    is_advanced: null,
    all_cities: true,
    budget_type: "DAILY",
    placement: "IN_CATEGORY",
    placements: [
      "IN_SEARCH",
      "IN_CATEGORY",
      "IN_HOME_PAGE",
      "IN_PRODUCT_DETAIL_PAGE",
    ],
    date_type: "STOPPED",
    keywords: null,
    redirect_url: "",
    dayParting: "ALL_TIMES",
    connected_with_products: false,
    spending_type: "EVENLY",
    front_loaded_percentage: "",
    multiRegion: "ALL_REGIONS",
    selectedRegions: [],
  });

  const [errorStates, setErrorStates] = useState({
    nameError: false,
    startError: false,
    dailyBudgetError: false,
    totalBudgetError: false,
    acosError: false,
    productError: false,
    keywordError: false,
    bidError: false,
    categoryError: false,
    placementsError: false,
    redirectURLError: false,
    dayPartingError: false,
    videoError: false,
    spendingError: false,
    frontLoadedError: false,
    regionError: false,
  });

  const isCampaignTypeProduct =
    ad_type?.ad_type === "PRODUCT" || ad_type?.ad_type === "STORE";

  const isProductAddedToCreatives =
    requiredFields.connected_with_products ||
    ad_type?.ad_type === "DISPLAY_WITH_PRODUCT" ||
    ad_type?.ad_type === "VIDEO_WITH_PRODUCT";

  const isProducts = isCampaignTypeProduct || isProductAddedToCreatives;

  const doesCampaignHasCreatives =
    selectCampaignType(ad_type?.ad_type) === "DISPLAY" ||
    selectCampaignType(ad_type?.ad_type) === "VIDEO";

  const handleErrorChange = (value: any, name: string) =>
    setErrorStates((prev) => ({ ...prev, [name]: value }));

  const bid_type = useCallback(() => {
    if (isCampaignTypeProduct) {
      if (data?.data.available_bidding_types.length === 1) {
        switch (data?.data.available_bidding_types[0]) {
          case "ACOS":
            return "MAX_ACOS";
          case "AUTO":
            return "STANDART";
          case "FIXED":
            return "FIXED";
          default:
            break;
        }
      } else if (data?.data.available_bidding_types.length > 1) {
        return requiredFields.bid_type;
      }
    }
    return "FIXED";
  }, [
    isCampaignTypeProduct,
    requiredFields.bid_type,
    data?.data.available_bidding_types,
  ]);

  const chooseMinimum = () => {
    switch (ad_type?.ad_type) {
      case "DISPLAY":
      case "DISPLAY_WITH_PRODUCT":
        return data?.data?.sponsored_display_price_setting;
      case "VIDEO":
      case "VIDEO_WITH_PRODUCT":
        return data?.data?.sponsored_video_price_setting;
      default:
        return data?.data?.sponsored_product_price_setting;
    }
  };

  const chosenMinBid = () => {
    if (chooseMinimum() === "CPM") return data?.data?.minimum_cpm;
    else return data?.data?.minimum_bid;
  };

  const bidError = () => {
    const typeCondition =
      bid_type() === "FIXED" && requiredFields.targeting === "AUTO_TARGETING";
    const isBidError = typeCondition && requiredFields.bid < chosenMinBid();
    /* const isProductError =
      doesCampaignHasCreatives &&
      requiredFields.targeting === "AUTO_TARGETING" &&
      selectedProducts.length === 0; */
    return isBidError; /* || isProductError; */
  };

  const validateDayPartingObject = () => {
    const { all_week, all_day, schedule } = generateDayPartingObject(
      requiredFields.dayParting,
      scheduleState,
      t
    ).dayparting;
    if (!all_week && !all_day && !schedule) {
      return true;
    }
    return false;
  };

  const isSelectedRegions =
    data?.data?.is_multi_region &&
    requiredFields.multiRegion === "SELECTED_REGIONS";

  const isAllRegions =
    data?.data?.is_multi_region && requiredFields.multiRegion === "ALL_REGIONS";

  const check = () => {
    const {
      name,
      date_type,
      start_date,
      budget_type,
      budgetDaily,
      budget,
      bid_type,
      acos,
      targeting,
      placement,
      redirect_url,
      spending_type,
    } = requiredFields;
    if (current === 0) {
      name === "" && handleErrorChange(true, "nameError");
      date_type !== "STOPPED" &&
        start_date === "" &&
        handleErrorChange(true, "startError");
      budget_type === "DAILY" &&
        budgetDaily === "" &&
        handleErrorChange(true, "dailyBudgetError");
      budget_type === "TOTAL" &&
        budget === "" &&
        handleErrorChange(true, "totalBudgetError");
      bid_type === "MAX_ACOS" &&
        acos === "" &&
        handleErrorChange(true, "acosError");
      bidError() && handleErrorChange(true, "bidError");
      selectedProducts.length === 0 &&
        isProducts &&
        handleErrorChange(true, "productError");
      (img.length === 0 ||
        img.some((item) => item.creative_name === undefined)) &&
        selectCampaignType(ad_type?.ad_type) === "DISPLAY" &&
        handleErrorChange(true, "displayError");
      selectCampaignType(ad_type?.ad_type) === "VIDEO" &&
        video.length === 0 &&
        handleErrorChange(true, "videoError");
      doesCampaignHasCreatives &&
        redirect_url === "" &&
        handleErrorChange(true, "redirectURLError");
      spending_type === null && handleErrorChange(true, "spendingError");
      spending_type === "FRONT_LOADED" &&
        requiredFields.front_loaded_percentage === "" &&
        handleErrorChange(true, "frontLoadedError");
      handleErrorChange(validateDayPartingObject(), "dayPartingError");
      isSelectedRegions &&
        requiredFields.selectedRegions.length === 0 &&
        handleErrorChange(true, "regionError");
    } else if (current === 1) {
      targeting !== "AUTO_TARGETING" &&
        placement === "IN_SEARCH" &&
        keywords!.length === 0 &&
        handleErrorChange(true, "keywordError");

      targeting !== "AUTO_TARGETING" &&
        placement === "IN_CATEGORY" &&
        categories.length === 0 &&
        handleErrorChange(true, "categoryError");
    }
  };

  const targeting =
    requiredFields.targeting === "AUTO_TARGETING"
      ? "AUTO"
      : requiredFields.placement === "IN_SEARCH"
      ? "SEARCH"
      : "CATEGORY";

  const campaign = (values: any) => {
    return {
      status: "ACTIVE",
      name: values.name,
      advertiser_id: adminInGeneral ? advertiser.id : null,
      ad_type: ad_type?.ad_type === "STORE" ? "PRODUCT" : ad_type?.ad_type,
      start_date:
        requiredFields.date_type === "STOPPED"
          ? moment().format("YYYY-MM-DD")
          : values.start_date.format("YYYY-MM-DD"),
      end_date:
        values.end_date !== undefined && values.end_date !== ""
          ? values.end_date.format("YYYY-MM-DD")
          : null,
      budget_type: requiredFields.budget_type,
      daily_budget:
        requiredFields.budgetDaily !== ""
          ? parseInt(requiredFields.budgetDaily)
          : null,
      total_budget:
        requiredFields.budget !== "" ? parseInt(requiredFields.budget) : null,
      spending_type: requiredFields.spending_type,
      front_loaded_percentage:
        requiredFields.spending_type === "FRONT_LOADED"
          ? parseInt(requiredFields.front_loaded_percentage)
          : null,
      /* budget:
      requiredFields.budget_type === "DAILY"
        ? parseInt(requiredFields.budgetDaily)
        : parseInt(requiredFields.budget), */
      targeting_type: targeting,
      placements: requiredFields.placements,
      bid_type: bid_type(),
      max_acos: values.bid_type === "MAX_ACOS" ? +requiredFields.acos : null,
      fixed_bid:
        bid_type() === "FIXED" && requiredFields.targeting === "AUTO_TARGETING"
          ? parseFloat(values.bid)
          : null,
      products: isProducts
        ? selectedProducts.map((product) => product.id)
        : null,
      creatives:
        selectCampaignType(ad_type?.ad_type) === "DISPLAY"
          ? img.filter((img) => img.submitted).map((img) => img.uuid)
          : selectCampaignType(ad_type?.ad_type) === "VIDEO"
          ? video.filter((video) => video.submitted).map((video) => video.uuid)
          : null,
      connected_with_products:
        ad_type?.ad_type === "DISPLAY" || ad_type?.ad_type === "VIDEO"
          ? requiredFields.connected_with_products
          : ad_type?.ad_type.includes("WITH_PRODUCT")
          ? true
          : null,
      redirect: doesCampaignHasCreatives
        ? {
            type: "CUSTOM",
            custom_url: values.url,
          }
        : null,
      campaign_regions: data?.data?.is_multi_region
        ? {
            status: "ACTIVE",
            id: isAllRegions
              ? data?.data?.regions.map((region: any) => region.id)
              : requiredFields.selectedRegions,
          }
        : null,
      keywords:
        requiredFields.targeting === "MANUAL_TARGETING" &&
        requiredFields.placement === "IN_SEARCH" &&
        keywords!.length > 0
          ? keywords!.map((keyword) => {
              return {
                text: keyword.text,
                match_type: keyword.match_type,
                bid:
                  bid_type() === "FIXED"
                    ? parseFloat(keyword.bid) ||
                      parseFloat(keyword.suggested_bid)
                    : null,
              };
            })
          : null,
      negative_keywords:
        requiredFields.targeting === "MANUAL_TARGETING" &&
        requiredFields.placement === "IN_SEARCH" &&
        negativeKeywords.length > 0
          ? negativeKeywords.map((word) => {
              return {
                text: word.text,
                match_type: word.match_type,
              };
            })
          : null,
      categories:
        requiredFields.targeting === "MANUAL_TARGETING" &&
        requiredFields.placement === "IN_CATEGORY" &&
        categories.length > 0
          ? categories.map((category: any) => {
              return {
                id: category.id,
                bid:
                  bid_type() === "FIXED"
                    ? parseFloat(category.bid) ||
                      parseFloat(category.suggested_bid)
                    : null,
              };
            })
          : null,
      negative_categories:
        requiredFields.targeting === "MANUAL_TARGETING" &&
        requiredFields.placement === "IN_CATEGORY" &&
        negativeCategories.length > 0
          ? negativeCategories.map((category: any) => category.id)
          : null,
      manual_targeting: words
        ? null
        : {
            platforms: values.platform,
            age_ranges: values.age,
            genders: values.gender,
            customers: values.customers,
            all_cities: requiredFields.all_cities,
            cities: requiredFields.all_cities ? null : values.cities_select,
          },
    };
  };

  const resetGlobalStates = () => {
    handleDeleteAllProducts();
    setKeywords([]);
    setNegativeKeywords([]);
    setCategories([]);
    setNegativeCategories([]);
    setImg([]);
    setVideo([]);
  };

  const success = (res: AxiosResponse) => {
    modalRef.current?.open();

    setNavigateData({
      campaign_id: res.data.id,
      campaign_name: res.data.name,
      advertiser_id: res.data.advertiser_id,
      ad_type: res.data.ad_type,
    });
  };

  const switchErrorKey = (err: AxiosError) => {
    if (
      err?.request?.responseText?.includes(
        "keywords in body shouldn't contain duplicates"
      )
    )
      return "keywordErr";
    else if (err?.request?.responseText?.includes("advertiser is paused"))
      return "advertiserErr";
    else if (err?.request?.responseText?.includes("at most 250 items"))
      return "productLimitErr";
    else if (err?.request?.responseText?.includes("same keyword in target"))
      return "sameKeyword";
    else if (err?.request?.responseText?.includes("maximum campaign number"))
      return "campaignLimitErr";
    else if (err?.request?.responseText?.includes("at most 100 items"))
      return "keywordLimitErr";
    else if (err?.request?.responseText?.includes("maximum 100 categories"))
      return "categoryLimitErr";
    else {
      console.error(err);
      check();
      return "generalErr";
    }
  };

  const error = (err: AxiosError) => {
    RetailNotification.showNotification(
      "error",
      "",
      t(`components.campaignForm.firstStep.errorStates.${switchErrorKey(err)}`)
    );
  };

  const createAdminCampaign = async (values: any) => {
    const dayPartingObject = generateDayPartingObject(
      requiredFields.dayParting,
      scheduleState,
      t
    );
    const body = {
      ...campaign(values),
      ...dayPartingObject,
    };
    try {
      await axios
        .post(`/api/marketplaces/${marketplace_id}/campaigns`, body, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => success(res));
    } catch (err: any) {
      error(err);
    }
  };

  const createCampaign = async (values: any) => {
    const dayPartingObject = generateDayPartingObject(
      requiredFields.dayParting,
      scheduleState,
      t
    );
    const body = {
      ...campaign(values),
      ...dayPartingObject,
    };
    setInfoModalVisible(false);
    if (adminInGeneral) {
      createAdminCampaign(values);
    } else {
      try {
        await api.post("campaigns", body).then((res) => success(res));
      } catch (err: any) {
        error(err);
      }
    }
  };

  const onOk = () => createCampaign(form.getFieldsValue(true));

  const onCancelInfoModal = () => setInfoModalVisible(false);

  const minDailyBudgetFromSettings = () => {
    if (ad_type?.ad_type === "DISPLAY_WITH_PRODUCT")
      return data?.data?.min_sponsored_display_daily_budget;
    if (ad_type?.ad_type === "VIDEO_WITH_PRODUCT")
      return data?.data?.min_sponsored_video_daily_budget;
    else
      return data?.data?.[
        `min_sponsored_${ad_type?.ad_type?.toLowerCase()}_daily_budget`
      ];
  };

  const totalBudgetAmount = () => {
    if (requiredFields.start_date !== "" && requiredFields.end_date !== "")
      return (
        Number(
          moment(requiredFields.end_date).diff(
            moment(requiredFields.start_date),
            "days"
          ) + 1
        ) * minDailyBudgetFromSettings()
      );
    else return minDailyBudgetFromSettings();
  };

  const budget = () => {
    const dailyBudgetError =
      requiredFields.budgetDaily === "" ||
      +requiredFields.budgetDaily < minDailyBudgetFromSettings();

    const totalBudgetError =
      requiredFields.budget === "" ||
      +requiredFields.budget < minDailyBudgetFromSettings();

    const totalBudgetWithDateError =
      requiredFields.budget === "" ||
      +requiredFields.budget < totalBudgetAmount();

    const smallerTotalBudgetError =
      +requiredFields.budget < +requiredFields.budgetDaily;

    if (requiredFields?.budget_type === "DAILY") {
      return dailyBudgetError;
    } else if (
      requiredFields?.budget_type === "TOTAL" &&
      requiredFields.date_type === "STOPPED"
    ) {
      return totalBudgetError;
    } else if (
      requiredFields?.budget_type === "TOTAL" &&
      requiredFields.date_type !== "STOPPED"
    ) {
      return totalBudgetWithDateError;
    } else if (requiredFields.budget_type === "FLEXIBLE") {
      return dailyBudgetError || totalBudgetError || smallerTotalBudgetError;
    }
    return false;
  };

  const date =
    requiredFields?.date_type !== "STOPPED" && requiredFields.start_date === ""
      ? true
      : false;

  const acos =
    requiredFields?.bid_type === "MAX_ACOS" && requiredFields.acos === ""
      ? true
      : false;

  const keyword =
    requiredFields.targeting !== "AUTO_TARGETING" &&
    requiredFields.placement === "IN_SEARCH" &&
    keywords!.length === 0;

  const category =
    requiredFields.targeting !== "AUTO_TARGETING" &&
    requiredFields.placement === "IN_CATEGORY" &&
    categories!.length === 0;

  const switchLastStepError = () => {
    switch (ad_type?.ad_type) {
      case "DISPLAY":
        return (
          img.length === 0 ||
          img.some((item) => item.creative_name === undefined)
        );
      case "VIDEO":
        return video.length === 0;
      case "DISPLAY_WITH_PRODUCT":
        return (
          img.length === 0 ||
          img.some((item) => item.creative_name === undefined) ||
          selectedProducts.length === 0
        );
      case "VIDEO_WITH_PRODUCT":
        return video.length === 0 || selectedProducts.length === 0;
      default:
        return selectedProducts.length === 0;
    }
  };

  const creativeError =
    selectCampaignType(ad_type?.ad_type) === "DISPLAY" &&
    img.some(
      (item) => item.size_valid === false || item.creative_name === undefined
    );

  const videoError =
    selectCampaignType(ad_type?.ad_type) === "VIDEO" && video.length === 0;

  const connectedWithProductsError =
    doesCampaignHasCreatives &&
    requiredFields.connected_with_products &&
    selectedProducts.length === 0;

  const redirectURLError =
    doesCampaignHasCreatives && requiredFields.redirect_url === "";

  const spendingError =
    requiredFields.spending_type === null ||
    (requiredFields.spending_type === "FRONT_LOADED" &&
      requiredFields.front_loaded_percentage === "");

  const regionError =
    isSelectedRegions && requiredFields.selectedRegions.length === 0;

  const switchMovable = () => {
    switch (current) {
      case 0:
        return (
          requiredFields.name === "" ||
          budget() ||
          date ||
          acos ||
          spendingError ||
          bidError() ||
          switchLastStepError() ||
          creativeError ||
          videoError ||
          connectedWithProductsError ||
          redirectURLError ||
          regionError ||
          validateDayPartingObject()
        );
      case 1:
        return keyword || category;
      default:
        break;
    }
  };

  const onFinishFailed = () => {
    check();
    if ((current === 1 && keyword) || (current === 1 && category)) {
      const errType = keyword ? "keyword" : "category";
      RetailNotification.showNotification(
        "error",
        "",
        t(`components.notification.${errType}`)
      );
    } else {
      RetailNotification.showNotification(
        "error",
        t("components.notification.formTitle"),
        t("components.notification.formText")
      );
    }
  };

  const onFinish = () => {
    !switchMovable() ? setInfoModalVisible(true) : onFinishFailed();
  };

  const handleChange = (value: any, name: string) =>
    setRequiredFields((prev) => ({ ...prev, [name]: value }));

  const handleFieldChange = (event: any) => {
    const { name, value } = event.target;
    setRequiredFields((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const targetingType = () => {
    switch (ad_type?.ad_type) {
      case "DISPLAY":
      case "DISPLAY_WITH_PRODUCT":
        return data?.data?.available_display_targeting_types;
      case "VIDEO":
      case "VIDEO_WITH_PRODUCT":
        return data?.data?.available_video_targeting_types;
      default:
        return data?.data?.available_product_targeting_types;
    }
  };

  const onlyManualTargeting =
    Array.isArray(targetingType()) &&
    targetingType()?.length === 1 &&
    targetingType()?.[0] === "MANUAL";

  useEffect(() => {
    form.setFieldsValue({
      date: "STOPPED",
      budget_type: "DAILY",
      keyword_targeting: "auto",
      placement: "IN_CATEGORY",
      audience: "auto",
      platform: ["TABLET", "MOBILE", "APP", "DESKTOP"],
      age: ["18-24", "25-34", "35-44", "45-54", "55-64", "65+"],
      gender: ["FEMALE", "MALE"],
      customers: ["PRESENT", "NEW"],
      cities: "ALL",
      targeting: onlyManualTargeting ? "MANUAL_TARGETING" : "AUTO_TARGETING",
      connected_with_products: false,
      multiRegion: "ALL_REGIONS",
      spending_type: "EVENLY",
      placements: data?.data?.available_ad_placement_types,
      bid_type: bid_type(),
    });

    setRequiredFields({
      ...requiredFields,
      placements: data?.data?.available_ad_placement_types,
      bid_type: bid_type(),
      targeting: onlyManualTargeting ? "MANUAL_TARGETING" : "AUTO_TARGETING",
    });
  }, [data?.data, onlyManualTargeting]);

  const stepInformation = [
    {
      title: `1. ${t("components.campaignForm.firstStep.campaignInfoTitle")}`,
      sub:
        current === 0 ? (
          <span className={cm.currentSubtitle}>{t("common.now")}</span>
        ) : (
          <span className={cc([cm.finishedSubtitle, "flex"])}>
            {t("common.completed")} <CheckOutlined />
          </span>
        ),
    },
    {
      title: `2. ${
        requiredFields.placement === "IN_SEARCH"
          ? t("components.campaignForm.firstStep.keywordStepTitle")
          : t("components.campaignForm.firstStep.categoryStepTitle")
      }`,
      sub:
        current === 1 ? (
          <span className={cm.currentSubtitle}>{t("common.now")}</span>
        ) : current > 1 ? (
          <span className={cc([cm.finishedSubtitle, "flex"])}>
            {t("common.completed")} <CheckOutlined />
          </span>
        ) : (
          t("common.notCompleted")
        ),
    },
    {
      title: `3. ${t("components.campaignForm.firstStep.targetingStepTitle")}`,
      sub:
        current === 2 ? (
          <span className={cm.currentSubtitle}>{t("common.now")}</span>
        ) : (
          t("common.notCompleted")
        ),
    },
  ];

  const handleCloseEndDate = () => form.setFieldsValue({ end_date: "" });

  const switchStep = () => {
    switch (current) {
      case 0:
        return (
          <RetailCampaignFormFirstStep
            requiredFields={{ ...requiredFields, dataBidType: bid_type() }}
            handleChange={handleChange}
            handleFieldChange={handleFieldChange}
            closeDatePicker={handleCloseEndDate}
            errorStates={errorStates}
            handleErrorChange={handleErrorChange}
            advertiserInfo={advertiserInfo}
            bidError={bidError()}
            form={form}
            schedule={scheduleState}
            setSchedule={setScheduleState}
          />
        );
      case 1:
        return (
          <RetailCampaignFormSecondStep
            requiredFields={{ ...requiredFields, dataBidType: bid_type() }}
            errorStates={errorStates}
          />
        );
      case 2:
        return (
          <RetailCampaignFormThirdStep
            requiredFields={requiredFields}
            handleChange={handleChange}
            words={words}
            setWords={setWords}
            checkAll={checkAll}
            indeterminate={indeterminate}
            onCheckAllChange={onCheckAllChange}
            onChange={onChange}
            checkedList={checkedList}
          />
        );
    }
  };

  const openWarningModal = () => setWarningModalVisible(true);

  const onCancel = () => openWarningModal();

  const onWarningModalOk = () => {
    adminInGeneral
      ? navigate("/admin/campaigns")
      : navigate({ pathname: "/", search: location.search });
    resetGlobalStates();
  };

  const onWarningModalCancel = () => setWarningModalVisible(false);

  const chooseSteps = () => {
    if (requiredFields.targeting === "AUTO_TARGETING")
      return [stepInformation[0]];
    if (data?.data?.demographic_targeting_type === "ALLOWED")
      return stepInformation;
    else return [stepInformation[0], stepInformation[1]];
  };

  const mode = location.pathname.includes("create") ? "create" : "edit";

  const isLastStep = current + 1 === chooseSteps().length;

  const prev = () => {
    setCurrent(current - 1);
    window.scrollTo(0, 0);
  };

  const next = (index: number) => {
    if (!switchMovable() && isLastStep) form.submit();
    else if (!switchMovable() && !isLastStep) {
      setCurrent(index + 1);
      window.scrollTo(0, 0);
    } else {
      form.validateFields();
      onFinishFailed();
    }
  };

  const handleStep = (index: number) => {
    if (isLastStep || !switchMovable() || index < current)
      return setCurrent(index);
    else if (switchMovable() && index > current) {
      onFinishFailed();
    }
  };

  useEffect(() => {
    const savedState = window.history.state;

    const alteredState = {
      ...savedState,
      newState: true,
    };

    window.history.pushState(alteredState, "", window.location.href);

    const handlePopState = () => {
      openWarningModal();
      window.history.pushState(alteredState, "", window.location.href);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <>
      {showAdminBar && <RetailAdminBar />}
      <nav
        className={cc(["flex", cm.nav, showAdminBar ? cm.advertiserNav : ""])}
      >
        <div className="flex">
          <span
            className={cc(["flex", cm.close])}
            onClick={onCancel}
            data-test="campaign-close"
          >
            <CloseOutlined />
          </span>
          <div>
            <RetailTitle level={5} className={cm.name}>
              {requiredFields.name}
            </RetailTitle>
            {adminInGeneral && (
              <RetailText
                weight="medium"
                size="s"
                className={cc(["flex", cm.text])}
              >
                {t("components.stepsNavbar.advertiser")}
                <strong>{advertiser.name}</strong>
              </RetailText>
            )}
          </div>
        </div>
        <div
          className={cc([
            cm.container,
            chooseSteps().length === 1 ? cm.small : cm.wide,
          ])}
        >
          <Steps current={current} type="navigation" className="nav-steps">
            {chooseSteps().map((item: any, index: number) => (
              <Steps.Step
                key={item.title}
                title={item.title}
                subTitle={item.sub}
                onClick={() => handleStep(index)}
                data-test="campaign-step"
              />
            ))}
          </Steps>
        </div>
        <div className="steps-action">
          <RetailMainButton
            hasBackground
            className={cc(["flex", cm.btn])}
            data-test={isLastStep ? "create-campaign" : "continue-campaign"}
            onClick={() => next(current)}
          >
            <p className={cm.title}>
              <span className={cm.span}>
                {!isLastStep
                  ? t("common.continue")
                  : t(`components.stepsNavbar.campaign_${mode}`)}
              </span>
              {!isLastStep && (
                <span className={cm.span}>
                  {t("components.stepsNavbar.step", {
                    value: `${current + 2} / ${stepInformation.length}`,
                  })}
                </span>
              )}
            </p>
            <RightWhiteOutlined />
          </RetailMainButton>
        </div>
      </nav>

      {data?.data?.campaign_tip === "OPEN" ? (
        <RetailInfoBar type="create" />
      ) : null}

      {/* Content */}
      <Form
        className="steps-content"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        colon={false}
        data-test="campaign-form"
        requiredMark={false}
        onFinishFailed={onFinishFailed}
      >
        {switchStep()}
        <div>
          <Divider className={cm.footerDivider} />
          <div className={cc(["flex", cm.footer])}>
            <RetailMainButton
              className={cm.cancelBtn}
              hasBackground={false}
              onClick={onCancel}
              data-test="cancel-campaign"
            >
              {t("common.cancel")}
            </RetailMainButton>
            {current !== 0 && (
              <RetailMainButton
                className={cc(["flex", cm.prevBtn])}
                hasBackground={false}
                data-test="return-campaign"
                onClick={prev}
              >
                <LeftOutlined /> {t("common.goBack")}
              </RetailMainButton>
            )}
            {requiredFields.targeting === "MANUAL_TARGETING" &&
              current !== chooseSteps().length - 1 && (
                <RetailMainButton
                  htmlType="button"
                  hasBackground
                  className={cm.nextBtn}
                  data-test="continue-campaign"
                  onClick={() => next(current)}
                >
                  {t("common.continue")}
                </RetailMainButton>
              )}
            {current === chooseSteps().length - 1 && (
              <RetailMainButton
                htmlType="button"
                hasBackground
                className={cm.nextBtn}
                onClick={form.submit}
                data-test="create-campaign"
              >
                {t("common.create")}
              </RetailMainButton>
            )}
          </div>
        </div>
      </Form>
      <RetailCampaignModal
        navigateData={navigateData}
        ref={modalRef}
        onClick={resetGlobalStates}
        isAdmin={adminInGeneral}
      />
      <CampaignDetailsModal
        type="CAMPAIGN_FORM"
        /* subtitle="Text" */
        visible={infoModalVisible}
        onOk={onOk}
        onCancel={onCancelInfoModal}
      >
        <section className={cm.summaryContainer}>
          <h6>{t("components.campaignForm.firstStep.info")}</h6>
          {/**
           * Advertiser Name and Campaign Name
           */}
          <article className="flex">
            <div>
              <p className={cm.summaryColTitle}>
                {t("components.campaignForm.firstStep.advertiser")}
              </p>
              <p className={cm.summaryColText}>{advertiserInfo?.data?.name}</p>
            </div>
            <div>
              <p className={cm.summaryColTitle}>
                {`${t("components.campaignForm.firstStep.campaignLabel")}:`}
              </p>
              <p className={cm.summaryColText}>{requiredFields.name}</p>
            </div>
          </article>
          {/**
           * Campaign Date Type or Start Date and End Date
           */}
          <article className="flex">
            {requiredFields.start_date !== "" ? (
              <>
                <div>
                  <p className={cm.summaryColTitle}>
                    {`${t(
                      "components.campaignForm.firstStep.startDateLabel"
                    )}:`}
                  </p>
                  <p className={cm.summaryColText}>
                    {moment(requiredFields.start_date).format("DD/MMM/YYYY")}
                  </p>
                </div>
                <div>
                  <p className={cm.summaryColTitle}>{`${t(
                    "components.endDatePicker.label"
                  )}:`}</p>
                  <p className={cm.summaryColText}>
                    {requiredFields.end_date === ""
                      ? "-"
                      : moment(requiredFields.end_date).format("DD/MMM/YYYY")}
                  </p>
                </div>
              </>
            ) : (
              <div>
                <p className={cm.summaryColTitle}>
                  {`${t("components.campaignForm.firstStep.dateInfoTitle")}:`}
                </p>
                <p className={cm.summaryColText}>
                  {t("components.campaignForm.firstStep.stoppedText")}
                </p>
              </div>
            )}
          </article>
          {/**
           * Budget Type and Budget
           */}
          <article className="flex">
            <div>
              <p className={cm.summaryColTitle}>
                {t("components.campaignForm.firstStep.budgetType")}
              </p>
              <p className={cm.summaryColText}>
                {t(
                  `components.campaignForm.firstStep.${requiredFields.budget_type.toLowerCase()}BudgetLabel`
                )}
              </p>
            </div>
            <div>
              <p className={cm.summaryColTitle}>{`${t(
                "components.campaignForm.firstStep.budget"
              )}:`}</p>
              <p className={cm.summaryColText}>
                <SelectCurrency
                  amount={
                    requiredFields.budget_type === "DAILY"
                      ? requiredFields.budgetDaily
                      : requiredFields.budget
                  }
                />
              </p>
            </div>
          </article>
          {/**
           * Bid Type and Bid
           */}
          <article className="flex">
            <div>
              <p className={cm.summaryColTitle}>
                {t("components.campaignForm.firstStep.bidType")}
              </p>
              <p className={cm.summaryColText}>
                {t(
                  `components.campaignForm.firstStep.${bid_type().toLowerCase()}`
                )}
              </p>
            </div>
            {bid_type() !== "STANDART" ? (
              <div>
                <p className={cm.summaryColTitle}>
                  {t(
                    `components.campaignForm.firstStep.${bid_type().toLowerCase()}_info`
                  )}
                </p>
                <p className={cm.summaryColText}>
                  {bid_type() === "FIXED" ? (
                    targeting === "AUTO" ? (
                      <SelectCurrency amount={requiredFields.bid} />
                    ) : (
                      <p>{t("components.campaignForm.firstStep.custom")}</p>
                    )
                  ) : (
                    `%${numFormatter(requiredFields.acos)}`
                  )}
                </p>
              </div>
            ) : null}
          </article>
          {/**
           * Targetings
           * */}
          {words ? null : (
            <>
              <h6>{t("components.campaignForm.firstStep.targeting")}</h6>
              {/**
               * Cities
               */}
              <article className="flex">
                <div>
                  <p className={cm.summaryColTitle}>
                    {`${t("components.campaignForm.thirdStep.city")}:`}
                  </p>
                  <p className={cm.summaryColText}>
                    {requiredFields.all_cities
                      ? t("components.campaignForm.thirdStep.allCities")
                      : form
                          .getFieldValue("cities_select")
                          ?.cities_select?.map((item: string) => (
                            <span className={cm.summaryColItem}>{item}</span>
                          ))}
                  </p>
                </div>
              </article>
              {/**
               * Age and Platform
               */}
              {(form.getFieldValue("age")?.length > 0 ||
                form.getFieldValue("platform")?.length > 0) && (
                <article className="flex">
                  {form.getFieldValue("age")?.length > 0 && (
                    <div>
                      <p className={cm.summaryColTitle}>
                        {`${t("components.campaignForm.thirdStep.age")}:`}
                      </p>
                      <p className={cm.summaryColText}>
                        {form.getFieldValue("age")?.map((item: string) => (
                          <span className={cm.summaryColItem}>{item}</span>
                        ))}
                      </p>
                    </div>
                  )}
                  {form.getFieldValue("platform")?.length > 0 && (
                    <div>
                      <p className={cm.summaryColTitle}>
                        {`${t("components.campaignForm.thirdStep.platform")}:`}
                      </p>
                      <p className={cm.summaryColText}>
                        {form.getFieldValue("platform")?.map((item: string) => (
                          <span className={cm.summaryColItem}>
                            {t(
                              `components.campaignForm.thirdStep.${item.toLowerCase()}`
                            )}
                          </span>
                        ))}
                      </p>
                    </div>
                  )}
                </article>
              )}
              {/**
               * Customer and Gender
               */}
              {(form.getFieldValue("customers")?.length > 0 ||
                form.getFieldValue("gender")?.length > 0) && (
                <article className="flex">
                  {form.getFieldValue("customers")?.length > 0 && (
                    <div>
                      <p className={cm.summaryColTitle}>
                        {`${t("components.campaignForm.thirdStep.customer")}:`}
                      </p>
                      <p className={cm.summaryColText}>
                        {form
                          .getFieldValue("customers")
                          ?.map((item: string) => (
                            <span className={cm.summaryColItem}>
                              {t(
                                `components.campaignForm.thirdStep.${item.toLowerCase()}Customers`
                              )}
                            </span>
                          ))}
                      </p>
                    </div>
                  )}
                  {form.getFieldValue("gender")?.length > 0 && (
                    <div>
                      <p className={cm.summaryColTitle}>
                        {`${t("components.campaignForm.thirdStep.gender")}:`}
                      </p>
                      <p className={cm.summaryColText}>
                        {form.getFieldValue("gender")?.map((item: string) => (
                          <span className={cm.summaryColItem}>
                            {t(
                              `components.campaignForm.thirdStep.${item.toLowerCase()}`
                            )}
                          </span>
                        ))}
                      </p>
                    </div>
                  )}
                </article>
              )}
            </>
          )}
        </section>
      </CampaignDetailsModal>
      <RetailWarningModal
        type="campaign"
        visible={warningModalVisible}
        onOk={onWarningModalCancel}
        onCancel={onWarningModalOk}
      />
    </>
  );
};

export default RetailCampaignForm;
