import axios, { AxiosResponse } from "axios";
import moment from "moment";
import {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
	useQuery,
	useQueryClient,
} from "react-query";
import {
	useLocation,
	useParams,
} from "react-router-dom";

import useApi from "../api";
import FilterContext from "../context/FilterProvider";
import { Relations } from "../utils/types";

const useTableFetch = (
  //Endpoint
  url: string,
  //Check if a table is a relation table
  isRelation: boolean,
  //Some table needs default sorting parameter
  defaultSort?: any,
  //Determines relation type of a relation table
  relationType?: Relations,
  //Some tables need default filtering(reports)
  defaultFilter: any = [],
  //Selected columns for exporting
  selectedColumns?: any[],
  //If need, fetch all data
  fetchAll = false
) => {
  const { t } = useTranslation();

  const location = useLocation();

  const queryClient = useQueryClient();

  const ad_type = location.state as { ad_type: string };

  const { id } = useParams<{ id: string }>();

  const { tags } = useContext(FilterContext);

  const { api, baseURL } = useApi();

  const [page, setPage] = useState(1);

  const [format, setFormat] = useState(null as "excel" | "pdf" | "csv" | null);

  const determinePageSize = () => {
    if (url === "keywords" || url === "category_form_table") return 20;
    if (url === "categories" || url === "cities") return 100;
    return 10;
  };

  const [pageSize, setPageSize] = useState(determinePageSize());

  const [search, setSearch] = useState("");

  const [debouncedSearch, setDebouncedSearch] = useState("");

  const searchRef = useRef(debouncedSearch);

  const [isEnabled, setIsEnabled] = useState(false);

  const [isExported, setIsExported] = useState(false);

  const [sort, setSort] = useState<{ sortValue: string; order: string } | null>(
    null
  );

  const determineEndpoint = () => {
    //Relation table endpoint
    if (isRelation) return `campaigns/${id}/relations/table`;
    //Category metrics endpoint with campaign type
    if (url === "category_form_table")
      return `categories/metrics/table?campaignType=${ad_type?.ad_type}`;
    //Keywords and categories endpoint with campaign type
    if (url === "keywords" || url === "categories")
      return `${url}/table?campaignType=${ad_type?.ad_type}`;
    //Products endpoint with campaign id
    if (url === "products" && fetchAll)
      return `products/table?campaignID=${id}`;
    // Else
    return url.includes("query") ? `reports/${id}/query` : `${url}/table`;
  };

  const determineEnabled = () => {
    if (url === "reports" && defaultFilter.length === 0) return false;
    if (url.includes("query") && id === undefined) return false;
    else return true;
  };

  const changePage = useCallback(
    (value: number) => {
      setPage(value);
    },
    [setPage]
  );

  const changePageSize = useCallback(
    (current: number, value: number) => {
      changePage(current);
      setPageSize(value);
    },
    [changePage]
  );

  useEffect(() => {
    if (baseURL() !== undefined) setIsEnabled(determineEnabled());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseURL]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 300);
    return () => {
      clearTimeout(handler);
      queryClient.cancelQueries("table");
    };
  }, [search, queryClient]);

  useEffect(() => {
    const timer = setTimeout(() => {
      searchRef.current = debouncedSearch;
    }, 1000);
    return () => clearTimeout(timer);
  }, [debouncedSearch]);

  const defaultSorting = () => {
    if (url === "advertisers" && sort === null)
      return { advertiserCreatedAt: "desc", advertiser: "asc" };
    if (sort !== null) return { [sort.sortValue]: sort.order };
    else return defaultSort;
  };

  const hasSelectedColumnsExport =
    isExported && format !== null && selectedColumns;

  const config = {
    page: searchRef.current !== debouncedSearch ? 1 : page,
    page_size: pageSize,
    search: debouncedSearch,
    filters:
      tags.length > 0
        ? defaultFilter.concat(
            tags.map((tag) => ({
              key: tag.selectedFilter,
              op: tag.equality,
              value: tag.selectedValue,
            }))
          )
        : defaultFilter,
    export: isExported && format !== null ? format.toUpperCase() : null,
    export_cols: hasSelectedColumnsExport
      ? selectedColumns?.map((col) => col?.dataIndex)
      : null,
    sort: defaultSorting(),
  };

  const configRelation = { ...config, relation: relationType };

  const downloadFile = (resp: AxiosResponse<any>) => {
    const downloadURL = window.URL.createObjectURL(
      new Blob([resp.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const link = document.createElement("a");
    link.href = downloadURL;
    link.setAttribute(
      "download",
      `${t(
        `components.download.${isRelation ? relationType : url}`
      )} ${moment().format("DD-MM-YYYY")}.${
        format === "excel" ? "xlsx" : format
      }`
    );
    document.body.appendChild(link);
    link.click();
  };

  const { isLoading, error, isError, data } = useQuery(
    [
      "table",
      {
        url,
        page,
        pageSize,
        debouncedSearch,
        isExported,
        type: isRelation ? relationType : null,
        tags: !url.includes("query") ? tags.length : false,
        sort,
        default:
          defaultFilter.length > 0
            ? `${defaultFilter[0].op}_${defaultFilter[0].value}`
            : fetchAll,
      },
    ],
    async ({ signal }) => {
      const cancelTokenSource = axios.CancelToken.source();
      const data = api.post(
        determineEndpoint(),
        isRelation ? configRelation : config,
        {
          cancelToken: cancelTokenSource.token,
          responseType: isExported ? "arraybuffer" : undefined,
        }
      );
      signal?.addEventListener("abort", () => {
        cancelTokenSource.cancel();
      });
      const response = await data;
      if (isExported) {
        downloadFile(response);
        setIsExported(false);
      }
      return response;
    },

    {
      retry: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: isEnabled,
    }
  );
  return {
    isLoading,
    isError,
    data,
    error,
    changePage,
    changePageSize,
    page,
    pageSize,
    search,
    setSearch,
    isExported,
    setIsExported,
    sort,
    setSort,
    setFormat,
    config,
  };
};

export default useTableFetch;
