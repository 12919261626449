import { RadioChangeEvent } from "antd";
import { useForm } from "antd/es/form/Form";
import {
	UploadChangeParam,
	UploadFile,
	UploadProps,
} from "antd/lib/upload/interface";
import useApi from "api";
import cc from "classcat";
import RetailCreativeImgColumn from "components/Column/RetailCreativeImgColumn";
import RetailCreativeLanguageColumn from "components/Column/RetailCreativeLanguageColumn";
import RetailMoneyColumn from "components/Column/RetailMoneyColumn";
import RetailNumberColumn from "components/Column/RetailNumberColumn";
import RetailPercentageColumn from "components/Column/RetailPercentageColumn";
import RetailStateColumn from "components/Column/RetailStateColumn";
import RetailStatusColumn from "components/Column/RetailStatusColumn";
import RetailDrawer from "components/Drawer/RetailDrawer";
import CreativeNameForm from "components/Form/RetailCampaignForm/RetailCampaignFormFirstStep/CreativeStep/CreativeNameForm";
import CreativeStepOptions from "components/Form/RetailCampaignForm/RetailCampaignFormFirstStep/CreativeStep/CreativeStepOptions";
import SecondDrawerUploadInner from "components/Form/RetailCampaignForm/RetailCampaignFormFirstStep/CreativeStep/SecondDrawerUploadInner";
import CreativePreviewModal from "components/Modal/CreativePreviewModal";
import RetailNotification from "components/Notification";
import RetailTable from "components/Table/RetailTable";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import {
	Creative,
	CreativeContext,
} from "context/CreativeProvider";
import {
	useContext,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
	useMutation,
	useQueryClient,
} from "react-query";
import { useParams } from "react-router";
import { ACCEPTED_FILE_TYPES } from "utils/constants";
import { selectCampaignType } from "utils/helpers";
import {
	AdType,
	Img,
} from "utils/types";

import cm from "./style.module.scss";

interface CreativesTableProps {
  languages: string[];
  type: AdType;
}

const CreativesTable = ({ languages, type }: CreativesTableProps) => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const { api } = useApi();

  const [openUpload, setOpenUpload] = useState(true);

  const [firstDrawerStates, setFirstDrawerStates] = useState({
    visible: false,
    selected: "",
  });

  const [secondDrawerStates, setSecondDrawerStates] = useState<{
    visible: boolean;
    name: string;
    showCreative: boolean;
    isLoading: boolean;
  }>({
    visible: false,
    name: "",
    showCreative: false,
    isLoading: false,
  });

  const [form] = useForm();

  const [visible, setVisible] = useState(false);

  const [url, setURL] = useState("");

  const { img, setImg, deleteCreative } = useContext(
    CreativeContext
  ) as Creative;

  const mainImgFile = useRef<File>();

  const queryClient = useQueryClient();

  const close = () => setVisible(false);

  const renderColumns = (col: string, value: any, records: any) => {
    switch (col) {
      case "status":
        return (
          <RetailStatusColumn
            records={records}
            url={`campaigns/${id}/relations`}
            relationType="CREATIVES"
          />
        );
      case "state":
        return (
          <RetailStateColumn
            value={value}
            type="creatives"
            review_note={records?.review_note}
          />
        );
      case "image_url":
        return (
          <RetailCreativeImgColumn
            onClick={() => {
              setURL(value);
              setVisible(true);
            }}
            value={value}
          />
        );
      case "impressions":
      case "clicks":
      case "viewable_impressions":
      case "RoAS":
      case "sale":
        return <RetailNumberColumn value={value} />;
      case "CTR":
      case "ACoS":
      case "CVR":
        return <RetailPercentageColumn value={value} />;
      case "CPM":
      case "CPC":
      case "sale_amount":
        return <RetailMoneyColumn value={value} />;
      case "spend":
        return <RetailMoneyColumn value={value} spendColumn={true} />;
      case "languages":
        return <RetailCreativeLanguageColumn value={value} />;
      default:
        return value ? value : "-";
    }
  };

  const resetUploadStates = (success?: boolean) => {
    setFirstDrawerStates({ visible: false, selected: "" });
    setSecondDrawerStates({
      visible: false,
      name: "",
      showCreative: false,
      isLoading: false,
    });
    if (!success && img.length > 0) deleteCreative(img[img.length - 1].uuid);
  };

  const updateValue = async () => {
    const config = {
      creatives: [img[img.length - 1].uuid],
    };
    const response = await api.patch(`campaigns/${id}`, config);
    return response;
  };

  const { mutateAsync } = useMutation(updateValue);

  const addCreatives = async () => {
    try {
      await mutateAsync();
      await new Promise((resolve) => setTimeout(resolve, 250));
      await queryClient.refetchQueries("table");
    } catch (e) {
      console.log(e);
    }
  };

  const uploadCreative = async (
    info: UploadChangeParam<UploadFile<any>> | null,
    options: {
      type: "image" | "video" | "vast";
      vastTag?: string;
      name?: string;
      onSuccess?: (response: any) => void;
      onError?: (error: any) => void;
    }
  ) => {
    const { type, onSuccess, onError } = options;

    const file = mainImgFile;
    if (type !== "vast") {
      if (!info || !info.file || info.fileList.length === 0) {
        file.current = undefined;
        return;
      }
      file.current = info.file as unknown as File;
    }

    const formData = new FormData();

    formData.append("image", file.current!);
    formData.append("name", file?.current?.name.replace(/\.[^/.]+$/, "")!);
    formData.append("status", "ACTIVE");
    formData.append(
      "format",
      info?.file?.type === "image/gif" ? "GIF" : "IMAGE"
    );

    const config = {
      image: formData.get("image"),
      body: JSON.stringify({
        name: formData.get("name"),
        status: formData.get("status"),
        format: formData.get("format"),
      }),
    };

    try {
      const response = await api.post("/creatives", config, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      await handleSuccess(response, type.toLowerCase() as "image" | "video");
      if (onSuccess) onSuccess(response);
    } catch (error) {
      if (onError) onError(error);
    }
  };

  const handleFileChange = async (
    info: UploadChangeParam<UploadFile<any>>,
    type: "video" | "image"
  ) => {
    await uploadCreative(info, {
      type,
      onSuccess: () => {
        setSecondDrawerStates((prev) => ({
          ...prev,
          showCreative: true,
          isLoading: false,
        }));
      },
      onError: (error) => handleError(error, type),
    });
  };

  const handleError = (error: any, type: "image" | "video") => {
    console.error(error);
    setSecondDrawerStates((prev) => ({ ...prev, isLoading: false }));

    let errKey = "generalErr";

    if (type === "image" && error?.request?.responseText) {
      const responseText = error.request.responseText;
      errKey = responseText.includes("maximum number of creatives for SMO")
        ? "creativeLimitErr"
        : responseText.includes("file size is too large")
        ? "creativeSizeErr"
        : "generalErr";
    }

    RetailNotification.showNotification(
      "error",
      "",
      t(`components.campaignForm.firstStep.errorStates.${errKey}`)
    );
  };

  const props: UploadProps = {
    name: "files",
    multiple: false,
    disabled: secondDrawerStates.isLoading,
    className: cc(["dragger", cm.creativeUploadDragger]),
    beforeUpload: () => false,
    itemRender: () => <></>,
    onChange: (f) => handleFileChange(f, "image"),
    openFileDialogOnClick: openUpload,
    accept: ACCEPTED_FILE_TYPES["IMAGE"],
  };

  const tableConfig = {
    url: "campaigns",
    isRelation: true,
    relationType: "CREATIVES",
    /* filters: productFilters(t), */
    renderColumns,
  };

  const handleSuccess = async (
    response: any,
    type: "image" | "video" | "vast"
  ) => {
    if (type === "image") {
      return new Promise<void>((resolve) => {
        const img = new Image();
        img.src = response.data.image_url;
        img.onload = () => {
          setImg((prev: Img[]) => [
            ...prev,
            {
              url: img.src,
              size: `${img.width}x${img.height}`,
              creative_name: response.data.creative_name,
              uuid: response.data.id,
              size_valid: response.data.size_valid,
              languages: response.data.languages,
            },
          ]);
          resolve();
        };
      });
    }
  };

  const openFirstDrawer = () =>
    setFirstDrawerStates({ visible: true, selected: "" });

  const cancelAllDrawers = () => resetUploadStates(false);

  const closeSecondDrawer = () => {
    setSecondDrawerStates({
      ...secondDrawerStates,
      visible: false,
    });
    setFirstDrawerStates({ ...firstDrawerStates, selected: "" });
  };
  const openSecondDrawer = () =>
    setSecondDrawerStates({
      ...secondDrawerStates,
      visible: true,
    });

  const changeUploadType = ({ target }: RadioChangeEvent) => {
    setFirstDrawerStates({ ...firstDrawerStates, selected: target.value });
    openSecondDrawer();
  };

  const updateCreativeName = async () => {
    let success = false;
    const uploadedDisplayID = img[img.length - 1]?.uuid;

    try {
      const response = await api
        .patch(`/creatives/${uploadedDisplayID}`, {
          name: form.getFieldValue("creative_name"),
        })
        .then((response) => {
          setImg((allImgs: Img[]) =>
            allImgs.map((obj: Img) => {
              if (obj.uuid === uploadedDisplayID) {
                return { ...obj, creative_name: response.data.creative_name };
              }
              return obj;
            })
          );
        });
      success = true;
      RetailNotification.showNotification(
        "success",
        "",
        t("components.notification.creativeUpload"),
        "bottomLeft"
      );
      return response;
    } catch (error) {
      console.log(error);
      success = false;
    } finally {
      resetUploadStates(success);
    }
  };

  const changeShowCreative = () =>
    setSecondDrawerStates({ ...secondDrawerStates, showCreative: false });

  const onOk = () => {
    form.submit();
    addCreatives();
  };

  return (
    <>
      <RetailTable
        button={{
          title: t("pages.acc.campaignDetails.creativesBtn"),
          onClick: openFirstDrawer,
        }}
        placeholder={t("pages.acc.campaignDetails.creativesPlaceholder")}
        tableConfig={tableConfig}
      />
      <RetailDrawer
        visible={firstDrawerStates.visible}
        onOk={openSecondDrawer}
        onClose={cancelAllDrawers}
        width={820}
        title={t("components.campaignForm.firstStep.firstDrawerTitle")}
        type="steps"
        disableOk={firstDrawerStates.selected === ""}
        footer={null}
      >
        <RetailTitle level={5} noMargin>
          {t("components.campaignForm.firstStep.creativeTypeTitle")}
        </RetailTitle>
        <RetailText size="xxxs" weight="medium" className={cm.drawerText}>
          {t("components.campaignForm.firstStep.creativeTypeText")}
        </RetailText>
        <CreativeStepOptions
          t={t}
          changeUploadType={changeUploadType}
          firstDrawerStates={firstDrawerStates}
          adType={selectCampaignType(type)}
        />
      </RetailDrawer>
      <RetailDrawer
        title={t(`components.campaignForm.firstStep.displaySecondDrawerTitle`)}
        visible={secondDrawerStates.visible}
        onOk={onOk}
        onClose={closeSecondDrawer}
        onCloseAll={cancelAllDrawers}
        width={772}
        type="add"
        cancelText={t("common.goBack")}
        disableOk={
          secondDrawerStates.name === "" ||
          !secondDrawerStates.showCreative ||
          img[img.length - 1]?.size_valid === false
        }
      >
        <RetailTitle level={5} noMargin>
          {t("components.campaignForm.firstStep.creativeNameTitle")}
        </RetailTitle>
        <RetailText size="xxxs" weight="medium" className={cm.drawerText}>
          {t("components.campaignForm.firstStep.creativeNameText")}
        </RetailText>
        <CreativeNameForm
          onFinish={updateCreativeName}
          form={form}
          value={secondDrawerStates.name}
          onChange={(e) =>
            setSecondDrawerStates({
              ...secondDrawerStates,
              name: e.target.value,
            })
          }
          t={t}
        />
        <RetailTitle level={5} noMargin>
          {t(
            `components.campaignForm.firstStep.${firstDrawerStates.selected.toLowerCase()}`
          )}
        </RetailTitle>
        <RetailText size="xxxs" weight="medium" className={cm.drawerText}>
          {t(
            `components.campaignForm.firstStep.${firstDrawerStates.selected.toLowerCase()}_drawer`
          )}
        </RetailText>

        <SecondDrawerUploadInner
          showCreative={secondDrawerStates.showCreative}
          video={[]}
          changeShowCreative={changeShowCreative}
          img={img}
          adType={selectCampaignType(type)}
          setOpenUpload={setOpenUpload}
          loading={secondDrawerStates.isLoading}
          error={img[img.length - 1]?.size_valid === false}
          {...props}
        />
      </RetailDrawer>
      <CreativePreviewModal
        type="creative"
        url={url}
        visible={visible}
        onCancel={close}
      />
    </>
  );
};

export default CreativesTable;
