import {
	Tabs,
	Tree,
} from "antd";
import ConfigProvider from "antd/lib/config-provider";
import useApi from "api";
import { ReactComponent as CollapseOutlined } from "assets/icons/collapseOutlined.svg";
import { ReactComponent as DownOutlined } from "assets/icons/downOutlined.svg";
import { ReactComponent as ExpandOutlined } from "assets/icons/expandOutlined.svg";
import { ReactComponent as ResetOutlined } from "assets/icons/resetOutlined.svg";
import cc from "classcat";
import RetailSearchBar from "components/Bar/RetailSearchBar";
import RetailMainButton from "components/Button/RetailMainButton";
import RetailEditableColumn from "components/Column/RetailEditableColumn";
import RetailBidEditContainer, { Selected } from "components/Container/RetailBidEditContainer";
import Empty from "components/Empty";
import RetailSettingsHeader from "components/Layout/RetailSettingsHeader";
import RetailTooltip from "components/Tooltip/RetailTooltip";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import useSettings from "hooks/useSettings";
import useTableFetch from "hooks/useTableFetch";
import {
	ChangeEvent,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
	useMutation,
	useQueryClient,
} from "react-query";
import { localeLanguages } from "utils/helpers";
import {
	Category,
	TreeNode,
} from "utils/types";

import cm from "../style.module.scss";

const RestrictionsPageCategoryTab = () => {
  const { t, i18n } = useTranslation();

  const [isScrollable, setIsScrollable] = useState(false);

  const queryClient = useQueryClient();

  const { api } = useApi();

  const { data } = useSettings("MARKETPLACE");

  const [activeKey, setActiveKey] = useState("ALL");

  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  const {
    data: categories,
    setSearch,
    search,
  } = useTableFetch("category-tree", false, {
    updatedAt: "desc",
  });

  const [selected, setSelected] = useState<Selected>({
    data: {},
    editing: false,
    editing_field: "",
    value: "",
  });

  const [categoryTree, setCategoryTree] = useState<any>(null);

  useEffect(() => {
    if (categories?.data?.trees) {
      setCategoryTree(categories.data.trees);
    }
  }, [categories?.data]);

  const [editing, setEditing] = useState<{
    [key: string]: { [field: string]: boolean };
  }>({});

  const updateColumn = async () => {
    const id = selected.data.category_setting?.id;

    const config = {
      [selected.editing_field]: parseFloat(selected.value),
      id,
    };
    const response = await api.patch(`/category-based-setting/${id}`, config);
    return response;
  };

  const postCategory = useCallback(
    async (value: any) => {
      try {
        const response = await api.post("category-based-setting", {
          category_id: value?.category_id,
          minimum_bid: parseFloat(value.minimum_bid) || data?.data?.minimum_bid,
          minimum_cpm: parseFloat(value.minimum_cpm) || data?.data?.minimum_cpm,
          acos: parseFloat(value.acos) || data?.data?.targeting_acos,
          relevance:
            parseInt(value.relevance) || data?.data?.general_category_relevance,
        });
        queryClient.refetchQueries("table");
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    [
      api,
      data?.data?.minimum_bid,
      data?.data?.minimum_cpm,
      data?.data?.targeting_acos,
      data?.data?.general_category_relevance,
      queryClient,
    ]
  );

  const { mutateAsync: mutateColumn } = useMutation(updateColumn);

  const handleRequest = useCallback(async () => {
    if (selected.data?.category_setting?.id) await mutateColumn();
    else await postCategory(selected.data);
  }, [mutateColumn, postCategory, selected.data]);

  const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setSearch(target.value);

  const customizeRenderEmpty = () => <Empty type="categories_table" />;

  const handleExpandAll = useCallback(() => {
    const allKeys = categoryTree.flatMap((node: any) =>
      [node?.category_id?.toString()].concat(
        node?.sub_categories?.map((sub: any) => sub?.category_id?.toString()) || []
      )
    );
    setExpandedKeys(allKeys);
  }, [categoryTree]);

  const handleCollapseAll = useCallback(() => setExpandedKeys([]), []);

  const toggleEditMode = useCallback((id: string, field: string) => {
    setEditing((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: !prev[id]?.[field],
      },
    }));
  }, []);

  const updateNodeData = useCallback(
    (id: number, field: string, value: any) => {
      if (!categoryTree) return;
      const updateNode = (node: Category): any => {
        if (node?.category_id === id) {
          return {
            ...node,
            category_setting: {
              ...node.category_setting,
              [field]: value,
            },
          };
        }
        if (node.sub_categories) {
          return {
            ...node,
            sub_categories: node.sub_categories.map(updateNode),
          };
        }
        return node;
      };
      setCategoryTree(updateNode(categoryTree));
    },
    [categoryTree]
  );

  const filterCategories = (categories: Category[]): Category[] => {
    if (!categories || categories.length === 0) return [];
    return categories
      .map((category) => {
        const isFilled =
          category?.category_setting?.acos !== undefined ||
          category?.category_setting?.relevance !== undefined ||
          category?.category_setting?.minimum_bid !== undefined ||
          category?.category_setting?.minimum_cpm !== undefined;

        const filteredSubCategories = category.sub_categories
          ? filterCategories(category.sub_categories)
          : [];

        if (isFilled || filteredSubCategories.length > 0) {
          return {
            ...category,
            sub_categories: filteredSubCategories,
          };
        }
        return null;
      })
      .filter((category): category is Category => category !== null);
  };

  const renderField = useCallback(
    (node: any, field: string) => {
      const fieldError = (field: string, value: any) => {
        switch (field) {
          case "acos":
            return !(+value <= 0.0001 && +value <= 100);
          case "relevance":
            return +value >= 1 && +value <= 100;
          default:
            return value !== "";
        }
      };
      const isEditing = editing[node?.category_id]?.[field];

      const value = node?.category_setting?.[field] ?? "-";

      return (
        <div className={cm.editCol}>
          <RetailText size="xxxs" weight="medium" className={cm.editLabel}>
            {t(`common.table.${field}`)}:
          </RetailText>
          {isEditing ? (
            <RetailBidEditContainer
              selected={selected}
              setSelected={setSelected}
              onClick={() => {
                toggleEditMode(node?.category_id, field);
                if (fieldError(field, selected.value)) {
                  updateNodeData(node?.category_id, field, selected.value);
                  handleRequest();
                }
              }}
              justCloseOnBlur={() => toggleEditMode(node?.category_id, field)}
            />
          ) : (
            <RetailEditableColumn
              value={value}
              type={field === "acos" ? "percent" : "number"}
              reverse={true}
              onClick={() => {
                toggleEditMode(node?.category_id, field);
                setSelected({
                  data: node,
                  editing: true,
                  value: value,
                  editing_field: field,
                });
              }}
            />
          )}
        </div>
      );
    },
    [editing, selected, t, toggleEditMode, updateNodeData, handleRequest]
  );

  const deleteCategoryApi = useCallback(
    async (id: number) => {
      try {
        await api.delete(`category-based-setting/${id}`);
        queryClient.refetchQueries("table");
      } catch (error) {
        console.error(error);
      }
    },
    [api, queryClient]
  );

  const convertToTreeRows = useCallback(
    (node: Category): TreeNode => ({
      title: (
        <div className={cm.row}>
          <RetailText size="xs" weight="medium" className={cm.nodeName}>
            {node.name}
          </RetailText>
          {renderField(node, "minimum_bid")}
          {renderField(node, "minimum_cpm")}
          {renderField(node, "acos")}
          {renderField(node, "relevance")}
          <div
            className={`flex ${cm.resetWrapper} ${
              node?.category_setting ? cm.resetWrapperActive : "-"
            }`}
          >
            {node?.category_setting && node?.category_setting?.id && (
              <div
                className={`flex ${cm.resetIconContainer}`}
                onClick={() => deleteCategoryApi(node!.category_setting!.id)}
              >
                <RetailTooltip title={t("common.reset")}>
                  <ResetOutlined />
                </RetailTooltip>
              </div>
            )}
          </div>
        </div>
      ),
      key: node?.category_id?.toString(),
      switcherIcon: (props: { expanded: boolean }) => (
        <div className={cc([cm.icon, props.expanded ? cm.up : cm.down])}>
          <DownOutlined />
        </div>
      ),
      children: node.sub_categories
        ? node.sub_categories.map(convertToTreeRows)
        : [],
    }),
    [renderField, deleteCategoryApi, t]
  );

  const filteredData = useMemo(
    () => filterCategories(categoryTree || []),
    [categoryTree, convertToTreeRows]
  );
  const treeData = useMemo(
    () => categoryTree?.map(convertToTreeRows) || [],
    [categoryTree, convertToTreeRows]
  );
  const filteredTreeData = useMemo(
    () => filteredData?.map(convertToTreeRows) || [],
    [filteredData, convertToTreeRows]
  );

  useEffect(() => {
    const data = activeKey === "ALL" ? treeData : filteredTreeData;
    setIsScrollable(expandedKeys.length >= 3 || data?.length >= 4);
  }, [expandedKeys, activeKey, treeData, filteredTreeData]);

  return (
    <>
      <RetailSettingsHeader type="category" />
      <div className={cc([cm.container, cm.categoryContainer])}>
        <div className={cm.categoryHeader}>
          <RetailTitle level={4} className={cm.secondaryTitle}>
            {t("pages.admin.restrictions.categoryTitle")}
          </RetailTitle>
          <RetailText size="xxxs" family="poppins" className={cm.categoryText}>
            {t("pages.admin.restrictions.categoryText")}
          </RetailText>
        </div>
        <div className={cc([cm.search, isScrollable ? cm.fixedSearch : ""])}>
          <RetailSearchBar
            placeholder={t("common.search")}
            shadow
            value={search}
            onChange={handleSearch}
          />
        </div>
        {categoryTree && (
          <ConfigProvider
            renderEmpty={customizeRenderEmpty}
            locale={localeLanguages(i18n.language)}
          >
            <div>
              <Tabs
                activeKey={activeKey}
                onChange={(activeKey) => setActiveKey(activeKey)}
                className={cc([
                  cm.categoryTabs,
                  isScrollable ? cm.fixedTabs : "",
                ])}
                tabBarExtraContent={
                  <div className={cm.btnContainer}>
                    <RetailMainButton
                      className={cm.btn}
                      onClick={handleExpandAll}
                    >
                      <ExpandOutlined />
                      {t("pages.admin.restrictions.expand")}
                    </RetailMainButton>
                    <RetailMainButton
                      className={cm.btn}
                      onClick={handleCollapseAll}
                    >
                      <CollapseOutlined />
                      {t("pages.admin.restrictions.collapse")}
                    </RetailMainButton>
                  </div>
                }
              >
                <Tabs.TabPane tab={t("pages.admin.restrictions.all")} key="ALL">
                  <Tree
                    treeData={treeData}
                    showLine={{
                      showLeafIcon: false,
                    }}
                    expandedKeys={expandedKeys}
                    onExpand={(keys) => setExpandedKeys(keys as string[])}
                    blockNode
                    selectable={false}
                    virtual={false}
                    className={cm.tree}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={t("pages.admin.restrictions.edited")}
                  key="EDITED"
                >
                  {filteredData && (
                    <Tree
                      treeData={filteredTreeData}
                      showLine={{
                        showLeafIcon: false,
                      }}
                      expandedKeys={expandedKeys}
                      onExpand={(keys) => setExpandedKeys(keys as string[])}
                      blockNode
                      selectable={false}
                      virtual={false}
                      className={cm.tree}
                    />
                  )}
                </Tabs.TabPane>
              </Tabs>
            </div>
          </ConfigProvider>
        )}
      </div>
    </>
  );
};

export default RestrictionsPageCategoryTab;
