import { useTranslation } from "react-i18next";
import { DataSchemaItem } from "../../../../../utils/types";

import cm from "./style.module.scss";
interface RetailDataSchemaItemProps {
  item: DataSchemaItem;
  mode?: "form" | "drawer";
}

const RetailDataSchemaItem = ({
  item,
  mode = "drawer",
}: RetailDataSchemaItemProps) => {
  const { t } = useTranslation();

  const render = (item: DataSchemaItem) => {
    const { field, type, required, description, sales } = item;

    return (
      <div key={field} className={`${cm.fieldItemContainer} ${cm[mode]}`}>
        <p>
          <span className={cm.field}>{field}</span>
          <span className={cm.type}>{type}</span>
          {required && (
            <span className={cm.required}>
              {t("components.datasource.required")}
            </span>
          )}
        </p>
        <p className={cm.description}>{description}</p>

        {sales && sales?.length > 0 && (
          <div className={cm.childItem}>
            {sales.map((saleItem) => render(saleItem))}
          </div>
        )}
      </div>
    );
  };

  return <>{render(item)}</>;
};

export default RetailDataSchemaItem;
