import { selectCampaignType } from "utils/helpers";
import { AdType } from "utils/types";

import ConnectedWithProducts from "../ConnectedWithProducts";
import CreativeStep from "../CreativeStep";
import RedirectURLInput from "../RedirectURLInput";

interface WithProductsWrapperProps {
  targeting: string;
  languages: string[];
  redirect_url: string;
  redirectURLError: boolean;
  productError: boolean;
  connected_with_products: boolean;
  type: AdType;
  displayError: boolean;
  videoError: boolean;
  handleFieldChange(e: any): void;
}

const WithProductsWrapper = ({
  targeting,
  languages,
  redirect_url,
  redirectURLError,
  connected_with_products,
  type,
  displayError,
  videoError,
  productError,
  handleFieldChange,
}: WithProductsWrapperProps) => {
  const typeError =
    selectCampaignType(type) === "DISPLAY" ? displayError : videoError;
  //DONT FORGET TO INCLUDE LANGUAGE TO THE NEW CREATIVE SCREEN
  return (
    <>
      <CreativeStep
        type={selectCampaignType(type)}
        targeting={targeting}
        error={typeError}
        languages={languages}
      />
      <ConnectedWithProducts
        targeting={targeting}
        connected_with_products={connected_with_products}
        handleFieldChange={handleFieldChange}
        type={type}
        error={productError}
      />
      <RedirectURLInput
        targeting={targeting}
        redirect_url={redirect_url}
        redirectURLError={redirectURLError}
        handleFieldChange={handleFieldChange}
      />
    </>
  );
};
export default WithProductsWrapper;
