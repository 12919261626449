import { Trans, useTranslation } from "react-i18next";
import { Radio, RadioChangeEvent } from "antd";
import { EntityStates, MethodStates } from "../../../../utils/types";
import { LinkOutlined } from "@ant-design/icons";
import google from "../../../../assets/images/google.png";
import meta from "../../../../assets/images/meta.png";
import code from "../../../../assets/images/code.png";
import RetailDrawer from "../../../Drawer/RetailDrawer";
import RetailMainButton from "../../../Button/RetailMainButton";
import RetailText from "../../../Typography/RetailText";
import RetailTitle from "../../../Typography/RetailTitle";

import cm from "./style.module.scss";

interface RetailMethodRowProps {
  methodStates: MethodStates;
  setMethodStates: React.Dispatch<React.SetStateAction<MethodStates>>;
  isActive: boolean;
  setActive: (id: string) => void;
  selectedEntity: EntityStates["selected"];
}

const RetailMethodRow = ({
  methodStates,
  setMethodStates,
  isActive,
  setActive,
  selectedEntity,
}: RetailMethodRowProps) => {
  const { t } = useTranslation();

  const openMethodDrawer = () =>
    setMethodStates((prev) => ({ ...prev, visible: true }));

  const onOk = () => {
    setMethodStates((prev) => ({ ...prev, visible: false, filled: true }));
    setActive("configurations");
  };

  const cancelEntityDrawer = () => {
    setMethodStates((prev) => ({ ...prev, selected: null, visible: false }));
  };

  const handleSelect = ({ target }: RadioChangeEvent) =>
    setMethodStates((prev) => ({ ...prev, selected: target.value }));

  const methodTitle = methodStates.filled
    ? t("components.datasource.filledMethod", {
        value: t(`components.datasource.${methodStates.selected}`),
      })
    : t(`components.datasource.emptyMethod`);

  const methodValueForFilledText = () => {
    switch (methodStates.selected) {
      case "googleFeedUrl":
      case "googleFeedFile":
      case "googleFeedDirectXml":
        return "Google Feed";
      case "metaFeedUrl":
        return "Meta Feed";
      case "api":
        return "API";
      default:
        return "";
    }
  };

  const filledMethodText = t(`components.datasource.filledMethodText`, {
    value: methodValueForFilledText(),
  });

  const emptyMethodText = t("components.datasource.emptyMethodText");

  const methodText = methodStates.filled ? filledMethodText : emptyMethodText;

  return (
    <>
      <section
        className={`${cm.contentContainer} ${isActive ? cm.active : ""}`}
        id="method"
      >
        <div className={cm.content}>
          <Trans>
            <h2 className={cm.title}>{methodTitle}</h2>
          </Trans>
          <p className={`${cm.text} ${methodStates.filled && cm.filledText}`}>
            {methodText}
          </p>
          {!methodStates.filled && (
            <RetailMainButton
              onClick={openMethodDrawer}
              className={cm.btn}
              hasBackground={isActive}
              disabled={!isActive}
            >
              {t("components.datasource.selectMethod")}
            </RetailMainButton>
          )}
        </div>
      </section>
      <RetailDrawer
        visible={methodStates.visible}
        title={t("components.datasource.selectMethod")}
        onOk={onOk}
        onClose={cancelEntityDrawer}
        disableOk={methodStates.selected === null}
        width={545}
      >
        {selectedEntity === "product" ? (
          <Radio.Group onChange={handleSelect}>
            <article className={cm.textContainer}>
              <div>
                <img src={google} alt="Google Logo" />
                <RetailTitle level={5} noMargin>
                  Google Feed
                </RetailTitle>
              </div>
              <RetailText size="xxxs" weight="medium" className={cm.drawerText}>
                {t("components.datasource.googleFeedText")}
              </RetailText>
            </article>
            <Radio
              value="googleFeedUrl"
              className={`form-radio ${cm.radioLabel}`}
            >
              <RetailTitle level={5} noMargin>
                {t("components.datasource.feedUrl")}
              </RetailTitle>
              <RetailText className={cm.radioText} size="xxxs" weight="medium">
                {t("components.datasource.feedUrlText")}
              </RetailText>
              <div className={cm.linkContainer}>
                <LinkOutlined />
                <a
                  href="https://support.google.com/merchants/answer/7052112?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("components.datasource.feedUrlLink")}
                </a>
              </div>
            </Radio>
            <Radio
              value="googleFeedFile"
              className={`form-radio ${cm.radioLabel}`}
            >
              <RetailTitle level={5} noMargin>
                {t("components.datasource.fileUpload")}
              </RetailTitle>
              <RetailText size="xxxs" weight="medium" className={cm.radioText}>
                {t("components.datasource.fileUploadText")}
              </RetailText>
              <div className={cm.linkContainer}>
                <LinkOutlined />
                <a
                  href="https://support.google.com/merchants/answer/7052112?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("components.datasource.feedUrlLink")}
                </a>
              </div>
            </Radio>
            <Radio
              value="googleFeedDirectXml"
              className={`form-radio ${cm.radioLabel}`}
            >
              <RetailTitle level={5} noMargin>
                {t("components.datasource.requestBody")}
              </RetailTitle>
              <RetailText size="xxxs" weight="medium" className={cm.radioText}>
                {t("components.datasource.requestBodyText")}
              </RetailText>
              <div className={cm.linkContainer}>
                <LinkOutlined />
                <a
                  href="https://support.google.com/merchants/answer/7052112?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("components.datasource.feedUrlLink")}
                </a>
              </div>
            </Radio>

            <article className={cm.textContainer}>
              <div className={cm.logoContainer}>
                <img src={meta} alt="Meta Logo" />
                <RetailTitle level={5} noMargin>
                  Meta Feed
                </RetailTitle>
              </div>

              <RetailText size="xxxs" weight="medium" className={cm.drawerText}>
                {t("components.datasource.metaFeedText")}
              </RetailText>
            </article>

            <Radio
              value="metaFeedUrl"
              className={`form-radio ${cm.radioLabel}`}
            >
              <RetailTitle level={5} noMargin>
                {t("components.datasource.feedUrl")}
              </RetailTitle>
              <RetailText className={cm.radioText} size="xxxs" weight="medium">
                {t("components.datasource.metaFeedUrlText")}
              </RetailText>
            </Radio>

            <article className={cm.textContainer}>
              <div className={cm.logoContainer}>
                <img src={code} alt="API Integration Logo" />
                <RetailTitle level={5} noMargin>
                  API
                </RetailTitle>
              </div>

              <RetailText size="xxxs" weight="medium" className={cm.drawerText}>
                {t("components.datasource.apiText")}
              </RetailText>
            </article>
            <Radio value="api" className={`form-radio ${cm.radioLabel}`}>
              <RetailTitle level={5} noMargin>
                {t("components.datasource.api")}
              </RetailTitle>
              <RetailText className={cm.radioText} size="xxxs" weight="medium">
                {t("components.datasource.apiButtonText")}
              </RetailText>
            </Radio>
          </Radio.Group>
        ) : (
          <Radio.Group onChange={handleSelect}>
            <article className={cm.textContainer}>
              <div>
                <img src={code} alt="API Integration Logo" />
                <RetailTitle level={5} noMargin>
                  API
                </RetailTitle>
              </div>

              <RetailText size="xxxs" weight="medium" className={cm.drawerText}>
                {t("components.datasource.apiText")}
              </RetailText>
            </article>
            <Radio value="api" className={`form-radio ${cm.radioLabel}`}>
              <RetailTitle level={5} noMargin>
                {t("components.datasource.api")}
              </RetailTitle>
              <RetailText className={cm.radioText} size="xxxs" weight="medium">
                {t("components.datasource.apiButtonText")}
              </RetailText>
            </Radio>
          </Radio.Group>
        )}
      </RetailDrawer>
    </>
  );
};

export default RetailMethodRow;
